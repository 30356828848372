import React from "react";

import { selectTranslations } from "core-ui/client/src/app/core/translateServiceModule/TranslationsSelector";
import PropTypes from "prop-types";

import { ACCESSIBILITY } from "../../../../constants/AccessibilityPaycheck";
import HiddenA11yWrapper from "../../../accessibility/HiddenA11yWrapper";
import RadialChart from "../../../radialChart/RadialChart";

/**
 * Desktop version for hdic radial charts
 * @param {*} pog
 */

const getGoalColor = (pog) => {
    if (pog < 40) {
        return "hdic-pog-low";
    } else if (pog < 50) {
        return "hdic-pog-medium";
    }
    return "hdic-pog-high";
};

export const TargetPeersComparison = ({ percentageOfGoal, name, hasFocus, onTabNav }) => {
    const translations = selectTranslations("hdic");
    const peerPctColor = getGoalColor(percentageOfGoal);
    const goalSegments = [];
    let item = {};
    switch (name) {
        case "target-my-peers":
            item = {
                code: "myPeers",
                class: peerPctColor,
                percent: Number(percentageOfGoal) > 100 ? 100 : Number(percentageOfGoal)
            };

            break;
        case "target-top-peers":
            item = {
                code: "topPeers",
                class: getGoalColor(percentageOfGoal),
                percent: Number(percentageOfGoal) > 100 ? 100 : Number(percentageOfGoal)
            };
            break;
        default:
            break;
    }

    goalSegments.push(item);
    const remainingPct = percentageOfGoal >= 100 ? 0 : 100 - percentageOfGoal;
    const remainingItem = {
        code: "remaining",
        class: "integrated-hdic-pog-remaining-color",
        percent: remainingPct
    };
    goalSegments.push(remainingItem);
    const handleClick = (e) => {
        e.preventDefault();
    };

    if (!percentageOfGoal) {
        return null;
    }

    return (
        <td className={name}>
            <div>
                <HiddenA11yWrapper id={ACCESSIBILITY.HOW_DO_I_COMPARE[name]}>
                    {Math.round(percentageOfGoal) > 999 ? 999 : Math.round(percentageOfGoal)} %{" "}
                    {translations.ofGoal}
                </HiddenA11yWrapper>
                <RadialChart
                    wrapperId="mobile-hdic-radial-chart-wrapper"
                    wrapperClass="progress-to-goal-radial-chart-wrapper"
                    segments={goalSegments}
                    selectedSegmentCode=""
                    donutHoleClass="integrated-hdic-radial-chart-inner"
                    topPadding={true}
                    topPaddingClass="progress-to-goal-radial-chart-top-padding"
                    handleClick={handleClick}
                    hasFocus={hasFocus}
                    onTabNav={onTabNav}
                >
                    <text fill="#092C3D" x="52%" y="50%">
                        <tspan
                            className={`score integrated-hdic-score target-peer-percent ${
                                Math.round(percentageOfGoal) > 99 && "three-digits"
                            }`}
                        >
                            {Math.round(percentageOfGoal) > 999
                                ? 999
                                : Math.round(percentageOfGoal)}
                            %
                        </tspan>
                    </text>
                    <text
                        className="goal-subtext-of-goal progress-to-goal-chart-action"
                        x="50%"
                        y="65%"
                        fill="#7d7d7d"
                    >
                        {translations.ofGoal}
                    </text>
                </RadialChart>
            </div>
        </td>
    );
};

TargetPeersComparison.propTypes = {
    percentageOfGoal: PropTypes.number,
    name: PropTypes.string,
    hasFocus: PropTypes.bool,
    onTabNav: PropTypes.func
};
