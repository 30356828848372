import React, { useCallback, useEffect, useState } from "react";

import { AMPLITUDE_EVENTS, dispatchAmplitude } from "core-ui/client/src/app/core/amplitude";
import { selectTranslations } from "core-ui/client/src/app/core/translateServiceModule/TranslationsSelector";
import { useAtomValue } from "jotai";
import { filter as _filter } from "lodash";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";

import { dispatchEventBus } from "../../actions/shared/sharedActionCreators";
import { hasRsgServicesAtom } from "../../atoms/atoms";
import Constants from "../../constants/Constants";
import { useHeaderStoreSelector } from "../../contexts";
import EventBusEvents from "../../events/eventBusEvents";
import LoggerFactory from "../../factories/LoggerFactory";
import { callMyLifeGoals, continuePCAPAssetUpdate } from "../../middleware/otherAssetsMiddleware";
import { formatMoneyWithDecimalWithDollarPrefix } from "../../utils/currencyUtils";

import {
    getPrimaryAccountsInContext,
    hasOtherAssetsAccounts,
    getParticipantFirstNameForAsset
} from "./helpers";

const DEFAULT_NON_MONTHLY_ACCOUNTS = [];

/**
 * Display only - Other Assets for an Integrated User
 * Update functionality will be on PCAP side
 * Cloned from MigratedUserList
 */

const IntegratedOtherAssets = ({ accountSummary }) => {
    const logger = LoggerFactory.getInstance("IntegratedOtherAssets:");
    const dispatch = useDispatch();

    const maCommon = selectTranslations("maCommon");
    const maMigratedUser = selectTranslations("MigratedUser");
    const appTranslations = selectTranslations("app");

    const { term } = useSelector((state) => state.applicationSettings.projectionSettings);
    const participant = useSelector((state) => state.participant);
    const spouse = useSelector((state) => state.spouse);
    const primaryPlan = useSelector((state) => state.primaryPlan);
    const otherAssets = useSelector((state) => state.otherAssets);
    const txnAccess = useSelector((state) => state.shared.txnAccess);
    const inboundSSOUser = useHeaderStoreSelector((state) => {
        return state?.shared?.authenticationStatus.inboundSSOUser;
    });

    const { empowerCrossSellConsent } = globalThis.integratedEligibility;
    const hasRsgServices = useAtomValue(hasRsgServicesAtom);

    const primaryAccountInContext = getPrimaryAccountsInContext({
        accountSummary,
        primaryPlanId: primaryPlan.id
    });

    // start list with non joint owners
    const [externalAssetsList, setExternaAssetsList] = useState(() =>
        _filter(otherAssets.externalAssets, (item) => {
            return !item.isJointOwner;
        })
    );

    const handleIntegratedAssetUpdate = useCallback(
        (e) => {
            e.stopPropagation();
            const currentContext =
                primaryAccountInContext.length > 0 ? primaryAccountInContext[0] : null;

            let planId = primaryPlan.id;
            planId = planId.split("-");

            const data = {
                sponsorId: planId[0],
                personId: participant.pcapPersonId,
                individualId: participant.individualId,
                dbName: currentContext ? String(currentContext.dbName).toUpperCase() : "IN02",
                accuCode: currentContext ? currentContext.accuCode : "Empower"
            };

            logger.debug("caught pc_integratedassetupdatecompleted " + e.detail.type);

            dispatch(continuePCAPAssetUpdate());

            if (e.detail.type === "income") {
                dispatch(callMyLifeGoals(data));
            }
        },
        [
            dispatch,
            logger,
            participant.individualId,
            participant.pcapPersonId,
            primaryAccountInContext,
            primaryPlan.id
        ]
    );

    useEffect(() => {
        window.addEventListener("pc_integratedassetupdatecompleted", handleIntegratedAssetUpdate);
    }, [handleIntegratedAssetUpdate]);

    useEffect(() => {
        if (otherAssets.externalAssets.length > 0) {
            const singleOwners = _filter(otherAssets.externalAssets, (item) => {
                return !item.isJointOwner;
            });
            const jointOwners = _filter(otherAssets.externalAssets, (item) => {
                return item.isJointOwner;
            });

            let tempList = [];
            tempList = singleOwners.length > 0 ? tempList.concat(singleOwners) : tempList;

            // combine joint owner records to one
            const jointPrimaryOwnersList = _filter(jointOwners, (item) => {
                return item.isJointOwner && item.isPrimary;
            });

            const jointSecondaryOwnersList = _filter(jointOwners, (item) => {
                return item.isJointOwner && !item.isPrimary;
            });

            if (jointPrimaryOwnersList.length > 0) {
                // combine balances for joint owners; match asset
                // update balance on primary owner record
                for (let x = 0; x < jointPrimaryOwnersList.length; x++) {
                    //  loop thru joint secondary owner; matching on asset id, combine balance and monthly amount values
                    for (let y = 0; y < jointSecondaryOwnersList.length; y++) {
                        if (jointSecondaryOwnersList[y].id === jointPrimaryOwnersList[x].id) {
                            jointPrimaryOwnersList[x].estimatedAnnualAmount +=
                                jointSecondaryOwnersList[y].estimatedAnnualAmount;
                            jointPrimaryOwnersList[x].monthlyIncomeEST +=
                                jointSecondaryOwnersList[y].monthlyIncomeEST;
                            break;
                        }
                    }
                }

                tempList = tempList.concat(jointPrimaryOwnersList);
                // eslint-disable-next-line react-hooks-extra/no-direct-set-state-in-use-effect
                setExternaAssetsList(tempList);
            } else {
                // check if secondary owner has joint with other owner
                if (jointSecondaryOwnersList.length > 0) {
                    tempList = tempList.concat(jointSecondaryOwnersList);
                    // eslint-disable-next-line react-hooks-extra/no-direct-set-state-in-use-effect
                    setExternaAssetsList(tempList);
                } else {
                    // update single owners list
                    // eslint-disable-next-line react-hooks-extra/no-direct-set-state-in-use-effect
                    setExternaAssetsList(tempList);
                }
            }
        }
    }, [otherAssets.externalAssets]);

    const listItems = [
        { item: maCommon.otherAssetNoneAtThisTime.message1 },
        { item: maCommon.otherAssetNoneAtThisTime.message2 },
        {
            item: maMigratedUser.MigratedUserNoneAtThisTime.labels.example3,
            ext: maMigratedUser.MigratedUserNoneAtThisTime.labels.example3Ext
        },
        { item: maCommon.otherAssetNoneAtThisTime.message3 },
        { item: maMigratedUser.MigratedUserNoneAtThisTime.labels.example5 },
        { item: maMigratedUser.MigratedUserNoneAtThisTime.labels.example6 }
    ];

    const listData = listItems.map(function (itemTxt, indx) {
        if (itemTxt.item === "Pensions") {
            const ext = itemTxt.ext.split(" ");

            return (
                // eslint-disable-next-line react/no-array-index-key
                <li key={`${itemTxt.item}-${indx}`}>
                    {`${itemTxt.item} `}
                    {ext.length > 1 && (
                        <span>
                            (
                            <strong>
                                {ext.map((text, indx) => {
                                    if (indx === ext.length - 1) {
                                        return (
                                            <a
                                                key={text}
                                                target="_blank"
                                                rel="noreferrer"
                                                href="https://www.empower-retirement.com/learning_center/calculators/pension.shtml#/"
                                            >
                                                {text}
                                            </a>
                                        );
                                    } else {
                                        return `${text} `;
                                    }
                                })}
                            </strong>
                            )
                        </span>
                    )}
                </li>
            );
        }
        return <li key={itemTxt.item}>{itemTxt.item}</li>;
    });

    const openOtherAssets = (type, item) => {
        const contextSwitchEligible =
            item.assetType === Constants.ASSET_TYPE.OAB_PLAN ||
            item.assetType === Constants.ASSET_TYPE.OAB_TRS;

        if (
            type === Constants.OTHER_ASSETS_SELECT_OPTIONS.linkedAccountBalances &&
            contextSwitchEligible
        ) {
            const planId = item.planId;

            window.dispatchEvent(new CustomEvent("switch-plan", { detail: { planId } }));
        }
    };

    const renderAssets = () => {
        const arrayToRender = [];
        const iirList = otherAssets.retirementAssets;
        const linkedAccountBalances = otherAssets.linkedAccountBalances;

        // mapping income in retirement object
        for (let i = 0; i < iirList.length; i++) {
            arrayToRender.push({
                id: iirList[i].investorId,
                type: Constants.OTHER_ASSETS_SELECT_OPTIONS.incomeInRetirement,
                name:
                    getParticipantFirstNameForAsset(
                        iirList[i].investorId,
                        false,
                        participant,
                        spouse
                    ) +
                    " " +
                    iirList[i].name,
                amount: iirList[i].estimatedAnnualAmount,
                monthlyIncomeEST: iirList[i].monthlyIncomeEST,

                responseData: iirList[i],
                accountProviderName: iirList[i].name
            });
        }

        // mapping income in linked account balances object
        for (let j = 0; j < linkedAccountBalances.length; j++) {
            arrayToRender.push({
                id: linkedAccountBalances[j].investorId,
                type: Constants.OTHER_ASSETS_SELECT_OPTIONS.linkedAccountBalances,
                name:
                    getParticipantFirstNameForAsset(
                        linkedAccountBalances[j].investorId,
                        false,
                        participant,
                        spouse
                    ) +
                    " " +
                    linkedAccountBalances[j].name,
                amount: linkedAccountBalances[j].value,
                monthlyIncomeEST: linkedAccountBalances[j].monthlyIncomeEST,
                responseData: linkedAccountBalances[j],
                accountProviderName: linkedAccountBalances[j].name
            });
        }

        for (let k = 0; k < externalAssetsList.length; k++) {
            arrayToRender.push({
                id: externalAssetsList[k].investorId,
                type: Constants.OTHER_ASSETS_SELECT_OPTIONS.externalAssets,
                name:
                    getParticipantFirstNameForAsset(
                        externalAssetsList[k].investorId,
                        externalAssetsList[k].isJointOwner,
                        participant,
                        spouse
                    ) +
                    " " +
                    externalAssetsList[k].name,
                amount: externalAssetsList[k].estimatedAnnualAmount,
                monthlyIncomeEST: externalAssetsList[k].monthlyIncomeEST,
                responseData: externalAssetsList[k],
                accountProviderName: externalAssetsList[k].accountProviderName
            });
        }

        // sort array by account provider name in asc order
        arrayToRender.sort(function (a, b) {
            const textA = String(a.name).toUpperCase();
            const textB = String(b.name).toUpperCase();
            return textA < textB ? -1 : textA > textB ? 1 : 0;
        });

        return arrayToRender.map((item) => {
            const { name, type, amount, monthlyIncomeEST, responseData, id } = item;

            const planSubsetName = (
                accountSummary?.nonMonthlyAccounts || DEFAULT_NON_MONTHLY_ACCOUNTS
            ).find((item) => {
                return responseData.name === item.name;
            });

            const subsetName = planSubsetName?.subsetName ? ` - ${planSubsetName.subsetName}` : "";

            return (
                <tbody key={id} className="force-break">
                    <tr>
                        <td className="table-th-header-name">
                            {type === "lab" && !inboundSSOUser ? (
                                <button
                                    type="button"
                                    onClick={() => openOtherAssets(type, responseData)}
                                    data-testid="accountBtn_type"
                                >
                                    {name}
                                    {subsetName && <em>{subsetName}</em>}
                                </button>
                            ) : (
                                <span data-testid="accountBtn">{name}</span>
                            )}
                        </td>
                        <td className="table-th-header-amount text-right" data-testid="render-amt">
                            {typeof amount === "number"
                                ? formatMoneyWithDecimalWithDollarPrefix(amount)
                                : amount}
                        </td>
                        <td
                            className="table-th-header-amount monthly-income text-right"
                            data-testid="monthly-income-est"
                        >
                            {formatMoneyWithDecimalWithDollarPrefix(monthlyIncomeEST)}
                        </td>
                    </tr>
                </tbody>
            );
        });
    };

    const handleAddClick = () => {
        dispatch(dispatchEventBus(EventBusEvents.OTHER_ASSETS.ADD_OTHER_ASSET_CLICK));
        dispatchAmplitude({
            eventType: AMPLITUDE_EVENTS.SELECT_BUTTON,
            selection: EventBusEvents.OTHER_ASSETS.ADD_OTHER_ASSET_CLICK
        });
    };

    const handleLinkClick = () => {
        dispatch(dispatchEventBus(EventBusEvents.OTHER_ASSETS.TIMETAP_LINK_CLICKED));
        dispatchAmplitude({
            eventType: AMPLITUDE_EVENTS.SELECT_LINK,
            selection: EventBusEvents.OTHER_ASSETS.TIMETAP_LINK_CLICKED
        });
    };

    const hasOtherAssets = hasOtherAssetsAccounts(otherAssets);

    const termHeaderText =
        term === Constants.TERM.monthly ? maCommon.monthlyIncomeEST : maCommon.yearlyIncomeEST;

    return (
        <div className="migrated-user-wrapper integrated-oa-wrapper">
            {hasOtherAssets ? (
                <div>
                    <div>
                        <p className="font-xl integrated-oa" data-testid="display-heading">
                            {maCommon.doYouHaveAnyRetirementAsset}
                        </p>
                    </div>
                    <table className="table">
                        <thead>
                            <tr>
                                <th className="table-th-header-name" scope="col">
                                    {maMigratedUser.MigratedUserList.labels.nameAndType}
                                </th>
                                <th className="table-th-header-amount text-right" scope="col">
                                    {maCommon.amount}
                                </th>
                                <th className="table-th-header-amount text-right" scope="col">
                                    {termHeaderText}
                                </th>
                            </tr>
                        </thead>
                        {renderAssets()}
                    </table>
                </div>
            ) : (
                <div className="row landing-component">
                    <div className="col-md-12 col-sm-12">
                        <div className="col-md-2 col-sm-2">
                            <div className="row container-right-border">
                                <div className="col-md-10 col-md-offset-1 col-sm-10 col-sm-offset-1 other-assets-liat">
                                    <div className="other-assets-logo" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-10 col-sm-10">
                            <p className="font-xl integrated-oa" data-testid="display-heading">
                                {maCommon.doYouHaveAnyRetirementAsset}
                            </p>
                            <h4 className="list-heading integrated-oa">
                                {maCommon.exampleIncludes}
                            </h4>
                            <div className="col-md-12 col-sm-12">
                                <ul className="list-group integrated-oa-font">{listData}</ul>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            <div className="row call-to-action-button">
                {hasRsgServices && empowerCrossSellConsent && txnAccess.accountOpenEnabled && (
                    <a
                        onClick={handleLinkClick}
                        className="wellness-consultation"
                        href="https://fwconsult.empowermytime.com/#/"
                        target="_blank"
                        rel="noreferrer"
                        data-testid="wellness-link"
                    >
                        {maCommon.wellnessConsultation}
                    </a>
                )}
                <a
                    className="btn btn-pcap-primary btn-block"
                    href="#/accounts/add"
                    onClick={handleAddClick}
                    tabIndex="0"
                    data-testid="addBtn"
                >
                    {otherAssets.retirementAssets.length > 0 ||
                    otherAssets.externalAssets.length > 0
                        ? appTranslations.common.labels.add
                        : maMigratedUser.Common.buttons.yesIDo}
                </a>
            </div>
        </div>
    );
};
IntegratedOtherAssets.propTypes = {
    accountSummary: PropTypes.object
};
export default IntegratedOtherAssets;
