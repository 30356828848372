import React from "react";

import ReferenceData from "../../../../constants/ReferenceData";
import type IQuickViewAccount from "../../types";
import QuickViewLink from "../QuickViewLink";

interface RenderLastTransactionProps {
    planDetails: IQuickViewAccount | null;
    translations: {
        planDetails: {
            lastTransactions: {
                title: string;
            };
        };
    } | null;
}
const RenderLastTransAction = ({ planDetails, translations }: RenderLastTransactionProps) => {
    if (!planDetails || !translations) {
        return null;
    }
    const lastTransaction = planDetails.lastTxnHistory?.txnDesc;
    const wrapTextClass =
        lastTransaction && lastTransaction.length > 25 ? "wrap-text subtitle" : "subtitle";

    return (
        lastTransaction && (
            <div className="quick-view-item d-flex flex-column">
                <QuickViewLink
                    link={ReferenceData.URL_HYPERLINK_TRANSACTION_HISTORY}
                    planDetails={planDetails}
                    title={translations.planDetails.lastTransactions.title}
                />
                <p className={wrapTextClass}>{lastTransaction}</p>
            </div>
        )
    );
};

export default RenderLastTransAction;
