import { postSamlResponse } from "core-ui/client/src/app/core/integrated/IntegratedEligibilityService";

import {
    eligibilityTypeAtom,
    hasRsgServicesAtom,
    homePageEligibilityAtom,
    isRetailAtom,
    showWidgetsAtom,
    csrfAtom
} from "../../atoms/atoms";
import { redwoodStore } from "../../store/redwoodStore";
import checkHasSeenWelcomeWidget from "../../utils/checkHasSeenWelcomeWidget";
import { modalStack } from "../modalStackService";
import { publicServices } from "../publicServices";

import type { EligiblityData, PartialEligiblityData } from "./eligibility";
import eligibility from "./eligibility";
import loadRedwoodBundle from "./loadRedwoodBundle";
import loadRedwoodCss from "./loadRedwoodCss";

const PCAP_LOCAL_HOST = `${globalThis.__HTTP_PREFIX__}://localhost:${globalThis.__LOGIN_UI_PORT__}`;
const localPcapApi = globalThis.__LOCAL_PCAP_API__ === "true";

const instanceOfData = (data: EligiblityData | PartialEligiblityData): data is EligiblityData => {
    return "integratedJS" in data;
};

/**
 * After user has been verified, we will need to check eligibility which provides us a samlToken and postOriginUrl.
 * With the token, we call the postSaml service to get pcap userGuid.
 * THEN, we can create the pcap bundle, inject the script and instantiate the PersonalCapital with the userGuid & originUrl.
 */

const handlePcapBundle = async () => {
    publicServices();

    try {
        const response = await eligibility();

        if (response.data.data) {
            globalThis.integratedEligibility = {
                ...globalThis.integratedEligibility,
                ...response.data.data,
                groupDetails: response.data.logInfo
            };

            redwoodStore.set(eligibilityTypeAtom, globalThis.integratedEligibility.type);
            redwoodStore.set(hasRsgServicesAtom, globalThis.integratedEligibility.hasRsgServices);
            redwoodStore.set(
                homePageEligibilityAtom,
                globalThis.integratedEligibility.homePageEligibility
            );
            redwoodStore.set(isRetailAtom, globalThis.integratedEligibility.isRetail);
            redwoodStore.set(showWidgetsAtom, globalThis.integratedEligibility.showWidgets);

            globalThis.modalStack = modalStack;

            if (instanceOfData(response.data.data)) {
                const { samlToken, integratedJS, integratedCSS, postOriginUrl } =
                    response.data.data;

                // inject CSS styling to head
                loadRedwoodCss({ integratedCSS });

                const samlResponse = await postSamlResponse(samlToken);

                if (samlResponse?.userGuid) {
                    const { userGuid, csrf, success } = samlResponse;

                    checkHasSeenWelcomeWidget(userGuid);
                    redwoodStore.set(csrfAtom, csrf);

                    const originUrl = localPcapApi ? PCAP_LOCAL_HOST : postOriginUrl;

                    loadRedwoodBundle({ originUrl, integratedJS, userGuid });
                    window.csrf = csrf; // do we really need in both places...
                    globalThis.integratedEligibility.csrf = csrf;
                    globalThis.integratedEligibility.success = success;
                    globalThis.integratedEligibility.userGuid = userGuid;
                    globalThis.integratedEligibility.samlPosted = true;
                }
            } else {
                console.warn("returning the secondary response...", { response });
            }
        }

        return response;
    } catch (error) {
        console.error("Error in handlePcapBundle", error);
        return error;
    }
};

export default handlePcapBundle;
