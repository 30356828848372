import { useQuery } from "@tanstack/react-query";
import type { Query, QueryKey } from "@tanstack/react-query";
import { useAtomValue } from "jotai";

import { csrfAtom } from "../../atoms/atoms";
import { getAccounts2 } from "../../services/otherAssetsService";
import { QUERY_KEYS } from "../constants";

import type IGetAccountsData from "./IGetAccountsData";

type RefetchIntervalFn = (
    query: Query<IGetAccountsData, Error, IGetAccountsData, QueryKey>
) => number | false | undefined;

type RefetchInterval = RefetchIntervalFn | number | false;

const useGetAccounts2 = (
    refetchInterval: RefetchInterval,
    lastServerChangeId: undefined | number
) => {
    const csrf = useAtomValue(csrfAtom);

    return useQuery<IGetAccountsData>({
        // eslint-disable-next-line @tanstack/query/exhaustive-deps
        queryKey: [QUERY_KEYS.GET_ACCOUNT_2, lastServerChangeId],
        queryFn: async () => {
            const response = await getAccounts2(csrf, lastServerChangeId);
            return response.data;
        },
        enabled: Boolean(csrf) && lastServerChangeId !== undefined,
        refetchInterval
    });
};

export default useGetAccounts2;
