import React, { memo, useEffect, StrictMode } from "react";

import { withProfiler } from "@sentry/react";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import coreQueryClient from "core-ui/client/react/core/shared/coreQueryClient";
import qualtrics from "core-ui/client/src/app/core/qualtrics/Qualtrics";
import coreuiReactComponents from "core-ui/dist/core-ui-react-components";
import { ToggleSearchButton } from "gw-shared-components";
import { Provider } from "react-redux";
import { HashRouter as Router } from "react-router-dom";
import { CompatRouter } from "react-router-dom-v5-compat";

import Application from "./Application";
import EnterpriseChatContainer from "./components/enterpriseChat/EnterpriseChatContainer";
import SearchBoxWrapper from "./components/search";
import { headerStoreContext } from "./contexts";
import { store } from "./store/store";

const { headerStore, HeaderViewProviderComponent, FooterViewProviderComponent } =
    coreuiReactComponents;

const ApplicationContainer = () => {
    useEffect(() => {
        qualtrics();
    }, []);

    return (
        <div id="main-page" className="redwood-container" style={{ minHeight: "100vh" }}>
            <StrictMode>
                <Router>
                    <CompatRouter>
                        <HeaderViewProviderComponent
                            store={headerStore}
                            externalContext={headerStoreContext}
                            queryClient={coreQueryClient}
                            ToggleSearchButton={ToggleSearchButton}
                        />

                        <QueryClientProvider client={coreQueryClient}>
                            <Provider store={headerStore} context={headerStoreContext}>
                                <Provider store={store}>
                                    <SearchBoxWrapper />
                                    <Application />
                                    <EnterpriseChatContainer />
                                </Provider>
                            </Provider>
                            {process.env.NODE_ENV === "development" && (
                                <ReactQueryDevtools initialIsOpen={false} />
                            )}
                        </QueryClientProvider>
                        <FooterViewProviderComponent />
                    </CompatRouter>
                </Router>
            </StrictMode>
        </div>
    );
};

export default withProfiler(memo(ApplicationContainer), { name: "ApplicationContainer" });
