import type { AxiosResponse } from "axios";
import StringUtil from "core-ui/client/src/app/StringUtil";

import type { IPrimaryPlan, MaDetailsResponse } from "../types/common";
import { executeGet } from "../utils/apiUtils";
import { getUrl } from "../utils/urlUtils";

const getMADetails = async ({
    gaId,
    indId
}: {
    gaId: IPrimaryPlan["gaId"] | undefined;
    indId: IPrimaryPlan["indId"] | undefined;
}) => {
    //
    const url = StringUtil.supplant(getUrl("getMADetails"), {
        gaId,
        indId
    });
    const response: AxiosResponse<MaDetailsResponse> = await executeGet(url, "getMADetails");
    return response.data;
};

export default getMADetails;
