import type IQuickViewAccount from "../../types";
import { percentageStringFormatter } from "../index";

const DAYS_IN_YEAR = 365;
const ONE_YEAR_IN_MILLISECONDS = DAYS_IN_YEAR * 24 * 60 * 60 * 1000;

const calculateRoRType = (planDetails: IQuickViewAccount | null | undefined): number | string => {
    if (!planDetails || !planDetails.ror) {
        console.warn("There is No ROR object in planDetails object.");
        return 0;
    }

    const { adjustedBeginDate, adjustedEndDate, annualRateOfReturn, periodRateOfReturn } =
        planDetails.ror;

    if (!adjustedBeginDate || !adjustedEndDate || !annualRateOfReturn || !periodRateOfReturn) {
        console.warn("ROR object is missing required properties.");
        return 0;
    }
    const percentageString =
        adjustedEndDate - adjustedBeginDate >= ONE_YEAR_IN_MILLISECONDS
            ? annualRateOfReturn
            : periodRateOfReturn;
    return percentageStringFormatter(percentageString);
};

export default calculateRoRType;
