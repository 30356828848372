import React, { useCallback, useEffect, useRef, useState } from "react";

import {
    handleKeyboardDownEvent,
    keyboardInteractions
} from "core-ui/client/react/core/utils/accessibilityHelpers";
import { AMPLITUDE_EVENTS } from "core-ui/client/src/app/core/amplitude";
import { selectTranslations } from "core-ui/client/src/app/core/translateServiceModule/TranslationsSelector";
import { find as _find } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useHistory, useLocation } from "react-router-dom";

import {
    HEALTHCARE_COSTS_ROUTE,
    HOW_DO_I_COMPARE_ROUTE,
    HSA_ROUTE,
    INCOME_GAP_ROUTE,
    MAXIMIZER_ROUTE,
    OTHER_ASSETS_ROUTE,
    PRE_RETIREMENT_ROUTE,
    RETIREMENT_INCOME_ROUTE,
    SOCIAL_SECURITY_ROUTE,
    WHEN_CAN_I_RETIRE_ROUTE
} from "../../../routes";
import {
    setActiveIncomeTabId,
    setFocusOnFirstActionable
} from "../../actions/projectedIncome/projectedIncomeActions";
import { dispatchEventBus, dispatchAmplitude } from "../../actions/shared/sharedActionCreators";
import EventBusEvents from "../../events/eventBusEvents";
import { useIsEmulator, useIsPrivileged } from "../../hooks/useEmulatorType";
import { continuePCAPAssetUpdate } from "../../middleware/otherAssetsMiddleware";
import {
    canShowIncomeGap,
    getDisplayGovernmentBenefits,
    getDisplayHsa,
    getDisplayOtherAssets
} from "../../selectors/featureFlagSelectors";
import { usePrevious } from "../../utils/customHooks";
import HiddenA11yWrapper from "../accessibility/HiddenA11yWrapper";
import NumberCounter from "../numberCounter/numberCounter";

const IncomeParts = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const tablist = useRef(null);
    const [focusTabIndex, setFocusTabIndex] = useState(0);
    const incomePartTranslations = selectTranslations("incomeParts");

    const participant = useSelector((state) => state.participant);
    const projectedIncome = useSelector((state) => state.projectedIncome);
    const previousProjectedIncome = usePrevious(projectedIncome);
    const primaryPlan = useSelector((state) => state.primaryPlan);
    const isEmulator = useIsEmulator();
    const isPrivileged = useIsPrivileged();
    const isPAE = isEmulator && !isPrivileged;
    const [shouldShowOtherAssets, setShouldShowOtherAssets] = useState(false);

    const isMobile = false; // static to be changed

    const currentPath = "#";

    const getIncomePartById = (projectedIncome, id) => {
        if (!projectedIncome || !projectedIncome.incomeParts) {
            return {};
        }
        return _find(projectedIncome.incomeParts, (incomePart) => {
            return incomePart.id == id;
        });
    };

    const getIncomePartValue = useCallback((projectedIncome, id) => {
        const part = getIncomePartById(projectedIncome, id);
        return part ? part.value : null;
    }, []);

    const getTranslationForKey = useCallback(
        (key) => {
            return key ? incomePartTranslations[key] : "";
        },
        [incomePartTranslations]
    );

    const [availableIncomeParts, setAvailableIncomeParts] = useState([]);

    useEffect(() => {
        const newContributionValues = [
            {
                label: getTranslationForKey("myContributions"),
                amount: getIncomePartValue(projectedIncome, "myContributions"),
                previousAmount: getIncomePartValue(previousProjectedIncome, "myContributions")
            }
        ];
        if (getIncomePartValue(projectedIncome, "employerContributions") > 0) {
            newContributionValues.push({
                label: getTranslationForKey("employerContributions"),
                amount: getIncomePartValue(projectedIncome, "employerContributions"),
                previousAmount: getIncomePartValue(previousProjectedIncome, "employerContributions")
            });
        }

        const newAvailableIncomeParts = [
            {
                goToRoute: RETIREMENT_INCOME_ROUTE,
                incomePartRoute: RETIREMENT_INCOME_ROUTE,
                extraDisplayRoutes: [
                    HOW_DO_I_COMPARE_ROUTE,
                    HEALTHCARE_COSTS_ROUTE,
                    MAXIMIZER_ROUTE,
                    PRE_RETIREMENT_ROUTE,
                    WHEN_CAN_I_RETIRE_ROUTE,
                    HOW_DO_I_COMPARE_ROUTE,
                    HEALTHCARE_COSTS_ROUTE,
                    MAXIMIZER_ROUTE,
                    PRE_RETIREMENT_ROUTE,
                    WHEN_CAN_I_RETIRE_ROUTE
                ],
                classKey: "contributions",
                values: newContributionValues
            }
        ];

        if (getDisplayHsa(participant.plans)) {
            newAvailableIncomeParts.push({
                goToRoute: HSA_ROUTE,
                incomePartRoute: !isMobile ? HSA_ROUTE : currentPath,
                classKey: "hsa",
                values: [
                    {
                        label: getTranslationForKey("hsa"),
                        amount: getIncomePartValue(projectedIncome, "hsa"),
                        previousAmount: getIncomePartValue(previousProjectedIncome, "hsa")
                    }
                ]
            });
        }

        if (getDisplayGovernmentBenefits(primaryPlan)) {
            newAvailableIncomeParts.push({
                goToRoute: SOCIAL_SECURITY_ROUTE,
                incomePartRoute: !isMobile ? SOCIAL_SECURITY_ROUTE : currentPath,
                classKey: "socialSecurity",
                values: [
                    {
                        label: getTranslationForKey("governmentBenefits"),
                        amount: getIncomePartValue(projectedIncome, "governmentBenefits"),
                        previousAmount: getIncomePartValue(
                            previousProjectedIncome,
                            "governmentBenefits"
                        )
                    }
                ]
            });
        }

        const displayOtherAssets = getDisplayOtherAssets(participant, primaryPlan);
        // confirm displayOtherAssets is true, then check on the assets total value greater than 0
        const otherAssetsTotalAmount = getIncomePartValue(projectedIncome, "otherAssets");

        const shouldShowOtherAssets =
            displayOtherAssets || (!displayOtherAssets && otherAssetsTotalAmount > 0);
        // eslint-disable-next-line react-hooks-extra/no-direct-set-state-in-use-effect
        setShouldShowOtherAssets(shouldShowOtherAssets);

        if (shouldShowOtherAssets) {
            newAvailableIncomeParts.push({
                // disable link when isPAE
                goToRoute: isPAE ? "#" : OTHER_ASSETS_ROUTE,
                incomePartRoute: !isMobile ? OTHER_ASSETS_ROUTE : currentPath,
                classKey: "otherAssets",
                values: [
                    {
                        label: getTranslationForKey("otherAssets"),
                        amount: otherAssetsTotalAmount,
                        previousAmount: getIncomePartValue(previousProjectedIncome, "otherAssets")
                    }
                ],
                disabled: !displayOtherAssets
            });
        }

        newAvailableIncomeParts.push({
            goToRoute: INCOME_GAP_ROUTE,
            incomePartRoute: !isMobile ? INCOME_GAP_ROUTE : currentPath,
            classKey: "incomeGap",
            values: [
                {
                    label: getTranslationForKey("incomeGap"),
                    amount: getIncomePartValue(projectedIncome, "incomeGap"),
                    previousAmount: getIncomePartValue(previousProjectedIncome, "incomeGap")
                }
            ],
            disabled: !canShowIncomeGap(primaryPlan)
        });
        // eslint-disable-next-line react-hooks-extra/no-direct-set-state-in-use-effect
        setAvailableIncomeParts(newAvailableIncomeParts);
    }, [
        participant,
        primaryPlan,
        projectedIncome,
        isPAE,
        getTranslationForKey,
        getIncomePartValue,
        previousProjectedIncome,
        isMobile
    ]);

    const handleIntegratedAssetUpdate = useCallback(
        (e) => {
            e.stopPropagation();
            if (e.detail.type === "assets") {
                dispatch(continuePCAPAssetUpdate());
            }
        },
        [dispatch]
    );

    useEffect(() => {
        window.removeEventListener(
            "pc_integratedassetupdatecompleted",
            handleIntegratedAssetUpdate
        );

        if (shouldShowOtherAssets) {
            window.addEventListener(
                "pc_integratedassetupdatecompleted",
                handleIntegratedAssetUpdate
            );
        }

        return () => {
            window.removeEventListener(
                "pc_integratedassetupdatecompleted",
                handleIntegratedAssetUpdate
            );
        };
    }, [handleIntegratedAssetUpdate, shouldShowOtherAssets]);

    const tabsWidthCalculator = (part) => {
        const employerContributionExist = part.values.length === 2;
        const tabsNumber = availableIncomeParts.length;

        if (employerContributionExist) {
            if (tabsNumber === 5) {
                return "five-tabs";
            } else if (tabsNumber >= 3) {
                return "three-and-four-tabs";
            } else if (tabsNumber === 2) {
                return "two-tabs";
            }
        }
        return "";
    };

    const location = useLocation();
    const handleClick = (key) => {
        dispatch(dispatchEventBus(EventBusEvents.LIAT.INCOME_PART_TAB_CLICK, { incomePart: key }));
        dispatch(
            dispatchAmplitude({
                event_type: AMPLITUDE_EVENTS.SELECT_BUTTON,
                event_properties: {
                    selection: EventBusEvents.LIAT.INCOME_PART_TAB_CLICK,
                    incomePart: key
                }
            })
        );
    };

    const handleNavLinkClick = (incomeTabId) => {
        if (incomeTabId) {
            dispatch(setActiveIncomeTabId(incomeTabId));
        }
    };

    const handleNavLinkKeyDown = (event, incomePartRoute, incomeTabId) => {
        handleTabListKeydown(event);
        handleKeyboardDownEvent({
            event,
            keys: keyboardInteractions.button,
            output: () => {
                handleNavLinkClick(incomeTabId);
                history.push(incomePartRoute);
            }
        });
    };

    const handleTabListKeydown = (event) => {
        event.preventDefault();
        let beforeTablist;
        const element = document.querySelector(".income-part__item--link.d-flex");
        if (element && element.className.includes("active")) {
            beforeTablist = document.querySelector(".progress-to-goal-radial-chart-wrapper");
        } else {
            beforeTablist = document.querySelector(".liat-body-section");
        }
        switch (event.key) {
            case "ArrowLeft":
                setFocusTabIndex((curr) => {
                    return curr === 0 ? availableIncomeParts.length - 1 : curr - 1;
                });
                break;
            case "ArrowRight":
                setFocusTabIndex((curr) => {
                    return (curr + 1) % availableIncomeParts.length;
                });
                break;
            case "Tab":
                if (event.shiftKey) {
                    beforeTablist.setAttribute("tabindex", 0);
                    beforeTablist.focus();
                } else {
                    dispatch(setFocusOnFirstActionable(true));
                }
                break;
        }
    };

    useEffect(() => {
        if (
            tablist.current &&
            document.activeElement.classList.contains("income-part__item--link")
        ) {
            tablist.current.children[focusTabIndex].children[0].focus();
        }
    }, [focusTabIndex]);

    return (
        <div className="redwood-income-part d-flex" ref={tablist}>
            {availableIncomeParts.map((part, indexPart) => {
                let selected = location.pathname === part.goToRoute;

                const isDisabled = part.disabled ? "disabled" : "";
                if (part.extraDisplayRoutes) {
                    const shouldSelect = part.extraDisplayRoutes.includes(location.pathname);
                    selected = shouldSelect || selected;
                }
                const id = `income-parts-tabs-${indexPart + 1}`;
                const incPartRoute = part.goToRoute;

                return (
                    <div
                        key={part.classKey}
                        className={`income-part--${part.classKey}
                                    ${tabsWidthCalculator(part)}
                                    ${isDisabled}`}
                    >
                        <NavLink
                            to={incPartRoute}
                            isActive={() => selected}
                            className={`income-part__item--link d-flex`}
                            onClick={() => handleClick(part.classKey)}
                            onKeyDown={(event) => handleNavLinkKeyDown(event, incPartRoute, id)}
                            id={id}
                            role="tab"
                            aria-selected={selected}
                            aria-controls="paycheck-primary-controls"
                        >
                            {part.values.map((val) => {
                                return (
                                    <div
                                        key={
                                            part.classKey +
                                            val.previousAmount +
                                            val.amount +
                                            val.label.replace(/\s/g, "")
                                        }
                                    >
                                        <div
                                            className={`rainbow-bar-segment rainbow-bar-segment--${part.classKey} ${
                                                selected && "active"
                                            }`}
                                        />
                                        <div className="income-part-value-label--container">
                                            <p> {val.label} </p>
                                            <p>
                                                <NumberCounter
                                                    key={
                                                        part.classKey +
                                                        val.previousAmount +
                                                        val.amount
                                                    }
                                                    startingNumber={val.previousAmount}
                                                    endingNumber={val.amount || 0}
                                                    isCurrency={true}
                                                    currencyUsesSuperscriptSymbol={false}
                                                />
                                            </p>
                                        </div>
                                        <HiddenA11yWrapper
                                            id={`income-part-item-list-${val.label.toLowerCase().replace(/ /g, "-")}`}
                                        >
                                            {val.label}
                                            {Math.round(val.amount)}
                                        </HiddenA11yWrapper>
                                    </div>
                                );
                            })}
                        </NavLink>
                    </div>
                );
            })}
        </div>
    );
};

export default IncomeParts;
