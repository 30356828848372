import React from "react";

import { dispatchEventBus, setCurrentFault } from "../../actions/shared/sharedActionCreators";
import EventBusEvents from "../../events/eventBusEvents";
import { useDispatch } from "../../hooks/use-redux-actions";
import type { Attributes } from "../../types/common";
import type { SsoAttributes } from "../../types/common/advisoryMessaging";
import { getPAEActionFault } from "../../utils/paeUtils";

interface AdvisoryFooterProps {
    addGaIdAndIndIdToUrl: (url: string, planId: string, indId: string) => string;
    advisoryAttributes: Attributes;
    currentRoute: string;
    displayFeForecast: boolean;
    enrolled: boolean;
    indId: string;
    isPAEUser: boolean;
    navigate: (sso: SsoAttributes) => void;
    planId: string;
    provider: string;
    setFeForecastModalIsVisible: (visible: boolean) => void;
}

const AdvisoryFooter = ({
    advisoryAttributes,
    enrolled,
    provider,
    displayFeForecast,
    currentRoute,
    addGaIdAndIndIdToUrl,
    isPAEUser,
    navigate,
    setFeForecastModalIsVisible,
    planId,
    indId
}: AdvisoryFooterProps) => {
    const OPEN_FE_FORECAST_MODAL = "OPEN_FE_FORECAST_MODAL";
    const SSO_FINANCIAL_ENGINES_FOOTER = "SSO_FINANCIAL_ENGINES_FOOTER";

    const dispatch = useDispatch();

    const handleClickFinancialEngineSSO = () => {
        if (isPAEUser) {
            dispatch(setCurrentFault(getPAEActionFault()));
            return;
        }
        if ("sso" in advisoryAttributes && advisoryAttributes.sso) {
            navigate(advisoryAttributes.sso);
        }
    };

    const openFeForecastModal = () => {
        setFeForecastModalIsVisible(true);
    };

    return (
        <div className="footer">
            {"footer" in advisoryAttributes &&
                Array.isArray(advisoryAttributes.footer) &&
                advisoryAttributes.footer?.map((data) => {
                    return (
                        <span key={data.preLinkText}>
                            {data.preLinkText}&nbsp;
                            {!!data.link &&
                                data.link.lookup &&
                                data.link.url === OPEN_FE_FORECAST_MODAL && (
                                    <button
                                        className="btn-link no-padding no-underline"
                                        onClick={() => {
                                            dispatch(
                                                dispatchEventBus(
                                                    EventBusEvents.ADVISORY_SERVICES
                                                        .FOOTER_LINK_CLICKED,
                                                    this,
                                                    {
                                                        enrolled,
                                                        provider,
                                                        feForecastEnabled: displayFeForecast,
                                                        currentRoute,
                                                        destination:
                                                            "Open Financial Engine Forecast modal"
                                                    }
                                                )
                                            );
                                            openFeForecastModal();
                                        }}
                                    >
                                        {data.link.label}
                                    </button>
                                )}
                            {!!data.link &&
                                data.link.label &&
                                data.link.lookup &&
                                data.link.url === SSO_FINANCIAL_ENGINES_FOOTER && (
                                    <section className="fe-sso-class">
                                        <button
                                            className="as-button-link"
                                            onClick={handleClickFinancialEngineSSO}
                                        >
                                            {data.link.label}
                                        </button>
                                    </section>
                                )}
                            {!!data.link && !data.link.lookup && (
                                <button
                                    className="as-button-link"
                                    onClick={() => {
                                        const url = addGaIdAndIndIdToUrl(
                                            data.link.url,
                                            planId,
                                            indId
                                        );
                                        dispatch(
                                            dispatchEventBus(
                                                EventBusEvents.ADVISORY_SERVICES
                                                    .FOOTER_LINK_CLICKED,
                                                this,
                                                {
                                                    enrolled,
                                                    provider,
                                                    feForecastEnabled: displayFeForecast,
                                                    currentRoute,
                                                    url
                                                }
                                            )
                                        );
                                        window.location.href = url;
                                    }}
                                >
                                    {data.link.label}
                                </button>
                            )}
                            &nbsp;{data.postLinkText}&nbsp;
                        </span>
                    );
                })}
        </div>
    );
};

export default AdvisoryFooter;
