import React from "react";

import Highcharts from "highcharts";
import highchartsAccessibility from "highcharts/modules/accessibility";
import HighchartsReact from "highcharts-react-official";
import PropTypes from "prop-types";

window.Highcharts = Highcharts;

class HealthcareCostsChart extends React.Component {
    constructor(props) {
        super(props);
        highchartsAccessibility(Highcharts);

        this.state = {
            chartOptions: {
                chart: {
                    type: "pie",
                    backgroundColor: null,
                    height: "180",
                    width: "180",
                    margin: [0, 0, 0, 0],
                    spacingTop: 0,
                    spacingBottom: 0,
                    spacingLeft: 0,
                    spacingRight: 0
                },
                title: {
                    text: ""
                },
                credits: {
                    enabled: false
                },
                tooltip: {
                    enabled: false
                },
                series: [
                    {
                        data: this.props.chartSummaryViewData,
                        size: "80%",
                        dataLabels: {
                            enabled: false
                        },
                        states: {
                            hover: {
                                enabled: false
                            }
                        }
                    }
                ],
                plotOptions: {
                    series: {
                        animation: {
                            duration: 0
                        }
                    }
                }
            }
        };
    }

    static getDerivedStateFromProps(props, state) {
        if (props.showHealthcareViewDetails) {
            return {
                chartOptions: {
                    ...state.chartOptions,
                    series: [
                        {
                            data: props.chartDetailedViewData,
                            size: "80%",
                            dataLabels: {
                                enabled: false
                            },
                            states: {
                                hover: {
                                    enabled: false
                                }
                            }
                        },
                        {
                            data: props.chartSummaryViewData,
                            size: "100%",
                            innerSize: "80%",
                            ignoreHiddenPoint: false,
                            dataLabels: {
                                enabled: false
                            },
                            states: {
                                hover: {
                                    enabled: false
                                }
                            }
                        }
                    ]
                }
            };
        } else {
            return {
                chartOptions: {
                    ...state.chartOptions,
                    series: [
                        {
                            data: props.chartSummaryViewData,
                            size: "80%",
                            dataLabels: {
                                enabled: false
                            },
                            states: {
                                hover: {
                                    enabled: false
                                }
                            }
                        }
                    ]
                }
            };
        }
    }

    render() {
        const { chartOptions } = this.state;

        return (
            <div data-testid="healthcare-costs-chart" role="img">
                <HighchartsReact highcharts={Highcharts} options={chartOptions} />
            </div>
        );
    }
}

HealthcareCostsChart.propTypes = {
    chartDetailedViewData: PropTypes.array.isRequired,
    chartSummaryViewData: PropTypes.array.isRequired,
    showHealthcareViewDetails: PropTypes.bool.isRequired
};

export default HealthcareCostsChart;
