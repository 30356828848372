import React, { memo } from "react";

import { dispatchAmplitude, AMPLITUDE_EVENTS } from "core-ui/client/src/app/core/amplitude";
import { selectTranslations } from "core-ui/client/src/app/core/translateServiceModule/TranslationsSelector";

import { dispatchEventBus } from "../../../../actions/shared/sharedActionCreators";
import AdvisoryServicesMessaging from "../../../../components/advisoryMessaging/AdvisoryServicesMessaging";
import { WantToComponent } from "../../../../components/dashboard";
import ReferenceData from "../../../../constants/ReferenceData";
import EventBusEvents from "../../../../events/eventBusEvents";
import { useSelector, useDispatch } from "../../../../hooks/use-redux-actions";
import type { MaDetailsData } from "../../../../types/common/maDetails";
import PremierIRA from "../PremierIRA";

interface SideBarFaqTranslations {
    href: string;
    title: string;
}

interface RightSideBarProps {
    homePageEligibility: boolean;
    isPAE: boolean;
    maDetails: MaDetailsData | undefined;
    showLoader: boolean;
    showWidgets: boolean;
    webSearchEnabled: boolean;
}

const RightSideBar = (props: RightSideBarProps) => {
    const { homePageEligibility, showWidgets, isPAE, maDetails, showLoader, webSearchEnabled } =
        props;
    const dispatch = useDispatch();
    const txnAccess = useSelector((state) => state.shared.txnAccess);

    const sideBarFaqTranslations: SideBarFaqTranslations = selectTranslations("sideBarFaq");

    const title = sideBarFaqTranslations.title || "";

    const provider = maDetails?.maProduct;
    const maEligible = !!provider;

    const handleFaqClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        dispatchAmplitude({
            eventType: AMPLITUDE_EVENTS.SELECT_BUTTON,
            selection: EventBusEvents.FAQ.CLICKED
        });

        dispatch(dispatchEventBus(EventBusEvents.FAQ.CLICKED));

        if (title.toLowerCase() !== "retirement planner overview") {
            window.location.href = sideBarFaqTranslations.href;
            return;
        }

        window.open(sideBarFaqTranslations.href);
    };

    return (
        <div className="right-side-bar d-flex">
            {txnAccess.hasAdvisoryAccess &&
                provider !== ReferenceData.PROVIDER_FE &&
                maEligible && (
                    // If it is AMA, MTR, and PPT is not enrolled, then display this widget above "I want too..." menu.
                    <AdvisoryServicesMessaging
                        additionalClasses={"rounded-border bg-white advisory-messaging-container"}
                        showLoader={showLoader}
                        getDynamicMessaging={false}
                    />
                )}
            {homePageEligibility && !webSearchEnabled && (
                <WantToComponent showLoader={showLoader} />
            )}
            {provider && provider === ReferenceData.PROVIDER_FE && (
                <AdvisoryServicesMessaging
                    additionalClasses={"rounded-border bg-white advisory-messaging-container"}
                    showLoader={showLoader}
                    getDynamicMessaging={true}
                />
            )}
            {((showWidgets && txnAccess.aceMessagingEnabled) || isPAE) && <PremierIRA />}
            {
                <button className="btn btn-link" onClick={handleFaqClick}>
                    {sideBarFaqTranslations?.title}
                </button>
            }
        </div>
    );
};

export default memo(RightSideBar);
