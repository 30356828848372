import type { Participant, ISpouse } from "../../../../types/common";

const getParticipantFirstNameForAsset = (
    investorId: number,
    isJointOwner: boolean,
    participant: Pick<Participant, "personId" | "firstName">,
    spouse: Pick<ISpouse, "personId" | "firstName">
) => {
    if (isJointOwner) {
        return "JOINT ACCOUNT";
    }

    if (investorId === participant.personId) {
        return participant.firstName;
    }

    if (investorId === spouse.personId) {
        return spouse.firstName;
    }

    return "";
};

export default getParticipantFirstNameForAsset;
