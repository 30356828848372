import { useMutation } from "@tanstack/react-query";
import { useAtomValue } from "jotai";

import { csrfAtom } from "../../atoms/atoms";
import getUserTransactions from "../../services/getUserTransactions";
import { QUERY_KEYS } from "../constants";

interface UserTransactions {
    spData?: {
        netCashflow?: number;
    };
}

interface UserTransactionsError {
    code: string;
}

interface GetUserTransactionsParams {
    endDate: string;
    startDate: string;
    userAccountIds?: number[];
}

const useUserTransactions = (
    onSuccess?: (data: UserTransactions, params: GetUserTransactionsParams) => void,
    onError?: (error: UserTransactionsError) => void
) => {
    const csrf = useAtomValue(csrfAtom);
    const mutationFn = async ({
        startDate,
        endDate,
        userAccountIds
    }: GetUserTransactionsParams) => {
        const { data } = await getUserTransactions(csrf, startDate, endDate, userAccountIds);
        return data;
    };

    return useMutation<UserTransactions, UserTransactionsError, GetUserTransactionsParams>({
        mutationKey: [QUERY_KEYS.GET_USER_TRANSACTIONS],
        mutationFn,
        onSuccess,
        onError
    });
};

export default useUserTransactions;
