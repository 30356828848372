import { deleteSpouse } from "../actions/goal/goalModalActionCreators";
import { setParticipant } from "../actions/participantDetails/participantDetailsActions";
import { updateProjectedIncome } from "../actions/projectedIncome/projectedIncomeActions";
import { SET_MIGRATED_SOCIAL_SECURITY } from "../actions/socialSecurity/socialSecurityActionTypes";
import { setSpouse } from "../actions/spouse/spouseActions";
import type { AppDispatch } from "../hooks/use-redux-actions";
import getUsers from "../services/getUsers";
import type { User } from "../types/common";

const updateUsers = (participantData, isPAE) => {
    return async (dispatch: AppDispatch) => {
        try {
            const users: User[] = isPAE
                ? (participantData as User[])
                : ((await getUsers()) as User[]);
            const participant = users.find((user) => user.headOfHousehold);
            const spouse = users.find((user) => !user.headOfHousehold);

            // set the users data to store
            if (participant) {
                dispatch(setParticipant(participant));
            }

            const spouseAction = spouse?.personId ? setSpouse : deleteSpouse;
            dispatch(spouseAction(spouse));

            dispatch({ type: SET_MIGRATED_SOCIAL_SECURITY, payload: { users } });
            dispatch(updateProjectedIncome());

            return users;
        } catch (err) {
            console.error("Error with service updateUsers: ", err);
            return err;
        }
    };
};

export default updateUsers;
