import React, { useEffect, useState, useRef } from "react";

import {
    handleKeyboardDownEvent,
    keyboardInteractions
} from "core-ui/client/react/core/utils/accessibilityHelpers.js";
import { selectTranslations } from "core-ui/client/src/app/core/translateServiceModule/TranslationsSelector";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import ReactResizeDetector from "react-resize-detector";

import AddContributionButton from "../../addDeferralModal/AddDeferralButton";
import { getTextColorClass } from "../helpers";
import {
    calculateAvailableWidth,
    convertPixelToPercent,
    getThumbPercentageOfTrack,
    totalTrackAvailable
} from "../sliderHelpers";

const SummarySlider = ({
    showSummaryExpansion,
    setShowCsorModal,
    toggleSummary,
    deferralSummaryPrefix,
    summarySliderSuffix,
    summaryDisplayTotal,
    summarySliderTotal,
    deferralsLogic,
    getConvertedSummaryValue,
    isTerminatedOrSpecialCatchup,
    handleShowAddDeferralModal,
    availableDeferrals
}) => {
    const translations = selectTranslations("retirementIncome");
    const participant = useSelector((state) => state.participant);
    const deferralSetupConfig = useSelector((state) => state.deferralSetupConfig);
    const isTotalEstimated = deferralSetupConfig.csorEstimatedCount > 0;
    const deferralSummaryMax = deferralSetupConfig.deferralSummaryMax;
    const [domMounted, setDomMounted] = useState(false);
    const [summaryCls, setSummaryCls] = useState("summarySliderTextNormal");

    const sliderTrackRef = useRef(null);
    const sliderThumbRef = useRef(null);

    useEffect(() => {
        // eslint-disable-next-line react-hooks-extra/no-direct-set-state-in-use-effect
        setDomMounted(true);

        const checkTotalDeferralPercentOver100 =
            (summarySliderSuffix === "%" && deferralSetupConfig.deferralSummaryTotal > 100) ||
            deferralSetupConfig.deferralTotalBasePct > 100 ||
            (deferralSetupConfig.deferralTotalVarPct > 100 &&
                deferralSetupConfig.hasVariableDollarDeferral);
        if (summaryCls !== checkTotalDeferralPercentOver100) {
            // eslint-disable-next-line react-hooks-extra/no-direct-set-state-in-use-effect
            setSummaryCls(getTextColorClass(checkTotalDeferralPercentOver100));
        }
    }, [
        deferralSetupConfig.deferralTotalBasePct,
        deferralSetupConfig.deferralTotalVarPct,
        deferralSetupConfig.deferralSummaryTotal,
        deferralSetupConfig.hasVariableDollarDeferral,
        summaryCls,
        summarySliderSuffix
    ]);

    const getTotalSliderValue = (summarySliderTotal) => {
        const converted = getConvertedSummaryValue(summarySliderTotal);
        const max = deferralSummaryMax;

        return Math.max(converted, max);
    };

    const getWidthNumber = (deferral, isLast, summarySliderTotal) => {
        //gather variables for math
        if (!deferral) {
            return "0%";
        }
        const fullWidth = sliderTrackRef.clientWidth;
        const minWidth = sliderThumbRef.clientWidth;
        const totalSliderValue = getTotalSliderValue(summarySliderTotal);
        const minWidthPercent = getThumbPercentageOfTrack(fullWidth, minWidth);
        const totalAvailable = totalTrackAvailable(fullWidth, minWidth);
        //find what ratio of the total this deferral is
        let deferralRatio = 0;
        let deferralValue = 0;
        deferralValue =
            deferralsLogic.getDeferralSummaryConversionFactor(deferral) * deferral.value;
        if (totalSliderValue > 0) {
            deferralRatio = deferralValue / totalSliderValue;
        }
        //find the ratio for the actual slider track
        let fillPercent = deferralRatio * totalAvailable;
        //add the slider thumb width if last track fill
        if (isLast) {
            fillPercent = fillPercent + minWidthPercent;
        }

        return fillPercent;
    };
    const updateFillPercent = (deferralList) => {
        deferralList.map((deferral, id) => {
            return (deferral.fillPercent =
                getWidthNumber(deferral, id === deferralList.length - 1, summarySliderTotal) + "%");
        });
    };

    const getSummaryThumb = () => {
        if (showSummaryExpansion) {
            return translations.sliders.summary.collapse;
        } else if (isTerminatedOrSpecialCatchup) {
            return translations.sliders.summary.view;
        } else {
            return translations.sliders.summary.edit;
        }
    };

    const createNewTrack = (deferral, isLast) => {
        // This method will mimic the getWidth(). Could likely be refactored later.
        if (!deferral) {
            return 0;
        }

        const trackWidth = sliderTrackRef.current.clientWidth;
        const thumbWidth = sliderThumbRef.current.clientWidth;
        const totalSliderValue = getTotalSliderValue(summarySliderTotal);
        const minWidthPercent = getThumbPercentageOfTrack(trackWidth, thumbWidth / 2);
        const totalAvailable = totalTrackAvailable(trackWidth, thumbWidth);
        let deferralRatio = 0;
        let deferralValue = 0;
        deferralValue =
            deferralsLogic.getDeferralSummaryConversionFactor(deferral) * deferral.value;
        if (totalSliderValue > 0) {
            deferralRatio = deferralValue / totalSliderValue;
        }
        //find the ratio for the actual slider track
        let fillPercent = deferralRatio * totalAvailable;
        if (isLast) {
            fillPercent = fillPercent + minWidthPercent;
        }
        const availableWidth = calculateAvailableWidth(totalAvailable, trackWidth, thumbWidth);
        const width = convertPixelToPercent(availableWidth * (fillPercent / 100), trackWidth);

        return width;
    };

    const handleKeyboardDownLink = (event) => {
        handleKeyboardDownEvent({
            event,
            keys: keyboardInteractions.button,
            output: () => toggleSummary(event)
        });
    };

    const getShowSpacer = (deferral, isFirst) => {
        return !isFirst && deferral.value >= 0;
    };
    const renderSliderTrackFill = (deferralList) => {
        return deferralList.map((deferral, id) => {
            let width;

            if (domMounted) {
                width = createNewTrack(deferral, id === deferralList.length - 1);
            }

            return (
                <div
                    ref={sliderTrackRef}
                    key={deferral.deferralTypeCode}
                    className="sliderTrackFill"
                    style={{ width }}
                >
                    {getShowSpacer(deferral, id === 0) ? (
                        <div className="sliderTrackFillSpacer" />
                    ) : null}
                    {id === deferralList.length - 1 ? (
                        <button
                            ref={sliderThumbRef}
                            id="summaryThumb"
                            className="sliderThumb"
                            aria-controls="summary-slider-collapse"
                            aria-expanded={showSummaryExpansion}
                            onClick={toggleSummary}
                        >
                            <div className="sliderThumbValue">{getSummaryThumb()}</div>
                        </button>
                    ) : null}
                </div>
            );
        });
    };

    return (
        <div className="sliderComponent summarySlider">
            <div className="sliderTitleBox">
                <div className="sliderTitleLabel text-capitalize">
                    <span className="sliderTitle">
                        {isTotalEstimated ? (
                            <button
                                id="estimated-button"
                                className="estimated-button text-capitalize"
                                onClick={setShowCsorModal}
                            >
                                {translations.estimated}
                            </button>
                        ) : null}
                        {translations.addContribution.all}
                        {": "}
                    </span>
                    <div
                        id="summary-slider-title-value"
                        role="button"
                        className={`summaryTitleValue ${summaryCls}`}
                        tabIndex="0"
                        onClick={toggleSummary}
                        onKeyDown={handleKeyboardDownLink}
                        aria-controls="summary-slider-collapse"
                        aria-expanded={showSummaryExpansion}
                    >
                        {deferralSummaryPrefix}
                        {summaryDisplayTotal()}
                        {summarySliderSuffix}
                    </div>
                </div>
                <AddContributionButton
                    handleShowAddDeferralModal={handleShowAddDeferralModal}
                    availableDeferrals={availableDeferrals}
                />
            </div>
            <ReactResizeDetector
                handleWidth
                handleHeight
                onResize={() => updateFillPercent(participant.deferrals)}
            >
                <div className="sliderTrackAndThumb">
                    <div className="sliderTrackWrapper">
                        <div ref={sliderTrackRef} className="sliderTrack">
                            {renderSliderTrackFill(participant.deferrals)}
                        </div>
                    </div>
                </div>
            </ReactResizeDetector>
        </div>
    );
};
SummarySlider.propTypes = {
    deferralValue: PropTypes.number,
    showSummaryExpansion: PropTypes.bool,
    setShowCsorModal: PropTypes.func,
    toggleSummary: PropTypes.func,
    deferralSummaryPrefix: PropTypes.string,
    summarySliderSuffix: PropTypes.string,
    summaryDisplayTotal: PropTypes.func,
    summarySliderTotal: PropTypes.number,
    deferralsLogic: PropTypes.object,
    getConvertedSummaryValue: PropTypes.func,
    isTerminatedOrSpecialCatchup: PropTypes.bool,
    handleShowAddDeferralModal: PropTypes.func,
    availableDeferrals: PropTypes.array
};
export default SummarySlider;
