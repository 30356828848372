import React, { useState, useEffect } from "react";

import { AMPLITUDE_EVENTS, dispatchAmplitude } from "core-ui/client/src/app/core/amplitude";
import { selectTranslations } from "core-ui/client/src/app/core/translateServiceModule/TranslationsSelector";
import StringUtil from "core-ui/client/src/app/StringUtil";
import { Button } from "gw-shared-components";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";

import { dispatchEventBus } from "../../actions/shared/sharedActionCreators";
import EventBusEvents from "../../events/eventBusEvents";
import { useIsEmulator, useIsPrivileged } from "../../hooks/useEmulatorType";
import { resetAgeAssumptions } from "../../services/preRetireeService";

import AssumptionsTabView from "./AssumptionsTabView";
import ConsiderationsTabView from "./ConsiderationsTabView";

const {
    PLAN_TO_AGE_TAB_EVENT,
    IMPORTANT_CONSIDERATIONS_TAB_EVENT,
    SAVE_CLICK_EVENT,
    SLIDER_AGE_CHANGE_EVENT,
    RESET_CLICK_EVENT,
    CANCEL_CLICK_EVENT
} = EventBusEvents.PRERETIREE;

const ConsiderationsModal = ({
    showModal,
    toggleModal,
    activeTab,
    setActiveTab,
    showConsiderationsBooleans,
    saveLEAge
}) => {
    const dispatch = useDispatch();
    const translations = selectTranslations("preRetiree")?.modal;
    const participant = useSelector((state) => state.participant);
    const isEmulator = useIsEmulator();
    const isPrivileged = useIsPrivileged();
    const isPAE = isEmulator && !isPrivileged;

    const [hasActiveChange, setHasActiveChange] = useState(false);
    const [sliderAge, setSliderAge] = useState(participant.adjustedLEAge);

    const handleTabClick = (e) => {
        const tabIndex = Number(e.target.dataset.tabIndex);
        setActiveTab(tabIndex);
        const event = tabIndex === 0 ? PLAN_TO_AGE_TAB_EVENT : IMPORTANT_CONSIDERATIONS_TAB_EVENT;

        dispatchAmplitude({
            eventType: AMPLITUDE_EVENTS.SELECT_BUTTON,
            selection: event
        });
        dispatch(dispatchEventBus(event, this));
    };

    const handleSave = () => {
        if (isPAE) {
            setSliderAge(participant.adjustedLEAge);
        }

        saveLEAge(sliderAge);
        dispatch(dispatchEventBus(SAVE_CLICK_EVENT, this));
        dispatchAmplitude({
            eventType: AMPLITUDE_EVENTS.SELECT_BUTTON,
            selection: SAVE_CLICK_EVENT
        });
        setHasActiveChange(false);
        toggleModal();
    };

    const handleSliderMoving = () => {};

    const handleSliderChange = (age) => {
        const hasAgeChanged = participant.adjustedLEAge !== age;
        const payload = {
            planToAge: age
        };
        dispatch(dispatchEventBus(SLIDER_AGE_CHANGE_EVENT, payload));
        dispatchAmplitude({
            eventType: AMPLITUDE_EVENTS.SELECT_SLIDER,
            selection: SLIDER_AGE_CHANGE_EVENT
        });
        setHasActiveChange(hasAgeChanged);
        setSliderAge(age);
    };

    const handleReset = () => {
        dispatch(dispatchEventBus(RESET_CLICK_EVENT, this));
        dispatchAmplitude({
            eventType: AMPLITUDE_EVENTS.SELECT_BUTTON,
            selection: RESET_CLICK_EVENT
        });
        resetAgeAssumptions(participant, dispatch);
        closeModal();
    };

    const handleCancel = () => {
        dispatch(dispatchEventBus(CANCEL_CLICK_EVENT, this));
        dispatchAmplitude({
            eventType: AMPLITUDE_EVENTS.DISMISS_MODAL,
            selection: CANCEL_CLICK_EVENT
        });
        closeModal();
    };

    const closeModal = () => {
        setSliderAge(participant.adjustedLEAge);
        setHasActiveChange(false);
        toggleModal();
    };

    useEffect(() => {
        // eslint-disable-next-line react-hooks-extra/no-direct-set-state-in-use-effect
        setSliderAge(participant.adjustedLEAge);
    }, [participant.adjustedLEAge]);

    const canSave = hasActiveChange && !isPAE;

    return (
        <Modal
            show={showModal}
            dialogClassName="preRetiree-modal"
            className="preRetiree-modal--container"
        >
            <div>
                <div>
                    <Modal.Header className="modal-header">
                        <h2>{translations.header}</h2>
                    </Modal.Header>
                    <button
                        className="modal-close-button em-delete-icon"
                        aria-label="Close"
                        onClick={closeModal}
                    />
                </div>

                <Modal.Body>
                    <p className="modal-description">
                        {translations.description +
                            " " +
                            StringUtil.supplant(translations.gauranteedIncomeNote, {
                                examples: showConsiderationsBooleans.displaySocialSecurity
                                    ? translations.gauranteedIncomeExamples
                                    : " "
                            }) +
                            " " +
                            translations.monthlyIncomeNote}
                    </p>

                    <div className="modal-tabs" role="tablist">
                        <button
                            type="button"
                            role="tab"
                            className={`tab ${activeTab === 0 ? "tab-active" : ""}`}
                            data-tab-index={0}
                            onClick={handleTabClick}
                        >
                            {translations.tabs.assumptions.title}
                        </button>

                        {showConsiderationsBooleans.canShowConsiderationsTab && (
                            <button
                                type="button"
                                role="tab"
                                className={`tab ${activeTab === 1 ? "tab-active" : ""}`}
                                data-tab-index={1}
                                onClick={handleTabClick}
                            >
                                {translations.tabs.considerations.title}
                            </button>
                        )}
                    </div>

                    <div className={`modal-well with-background`}>
                        {activeTab === 0 && (
                            <AssumptionsTabView
                                handleSliderMoving={handleSliderMoving}
                                handleSliderChange={handleSliderChange}
                                handleReset={handleReset}
                                sliderAge={sliderAge}
                                isPAE={isPAE}
                            />
                        )}
                        {activeTab === 1 && (
                            <ConsiderationsTabView
                                hasOtherAssets={showConsiderationsBooleans.displayOtherAssets}
                                hasSocialSecurity={showConsiderationsBooleans.displaySocialSecurity}
                                isEnrolledInFullManagedAccounts={
                                    showConsiderationsBooleans.enrolledInFullManagedAccounts
                                }
                                isEligibleForManagedAccounts={
                                    showConsiderationsBooleans.eligibleForManagedAccounts
                                }
                                isEnrolledInAdvisorManagedAccounts={
                                    showConsiderationsBooleans.enrolledInAdvisorManagedAccounts
                                }
                            />
                        )}
                    </div>
                </Modal.Body>

                <Modal.Footer className="modal-footer">
                    <div className="modal-footer-buttons">
                        <Button
                            text={translations.cancelButton}
                            buttonClasses={`btn-link btn-cancel ${"integrated-footer"}`}
                            clickEvent={handleCancel}
                        />
                        <Button
                            text={translations.saveButton}
                            buttonClasses={`${"btn-pcap-primary"}`}
                            clickEvent={handleSave}
                            isDisabled={!canSave}
                        />
                    </div>
                </Modal.Footer>
            </div>
        </Modal>
    );
};

ConsiderationsModal.propTypes = {
    showModal: PropTypes.bool,
    toggleModal: PropTypes.func,
    activeTab: PropTypes.number,
    setActiveTab: PropTypes.func,
    showConsiderationsBooleans: PropTypes.object,
    saveLEAge: PropTypes.func
};
export default ConsiderationsModal;
