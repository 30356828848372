import React, { useMemo, useState, useEffect } from "react";

import { AMPLITUDE_EVENTS } from "core-ui/client/src/app/core/amplitude";
import { selectTranslations } from "core-ui/client/src/app/core/translateServiceModule/TranslationsSelector";
import { SliderWrapper } from "gw-shared-components";
import { omit, debounce } from "lodash";
import { useSelector, useDispatch } from "react-redux";

import { deferralSliderChange } from "../../../actions/deferrals/deferralActions";
import {
    getScreenSize,
    dispatchEventBus,
    dispatchAmplitude
} from "../../../actions/shared/sharedActionCreators";
import { setSliderMovement } from "../../../actions/slider/sliderActions";
import Deferrals, { getMaxEditableEntry } from "../../../business/deferralsLogic/deferrals";
import { getRoundedNextStepValue } from "../../../business/nextStepLogic/nextStep";
import { ariaControlsSlidersHomePage } from "../../../constants/AccessibilityPaycheck";
import DeferralConstants from "../../../constants/DeferralConstants";
import { displayDeferral } from "../helpers";
import { showEstimated } from "../sliderHelpers";

const SLIDER_CONTENT_ID = "srProjectedIncome";

const ContributionsSliders = ({ canShowSummary, showSummaryExpansion, handleShowCsorModal }) => {
    const dispatch = useDispatch();
    const primaryPlan = useSelector((state) => state.primaryPlan);
    const deferralSetupConfig = useSelector((state) => state.deferralSetupConfig);
    const translations = selectTranslations("retirementIncome");
    const sliderBeingMoved = useSelector((state) => state.slider).sliderBeingMoved;
    const participant = useSelector((state) => state.participant);
    const deferralList = participant.deferrals;
    const baselineDeferrals = useSelector((state) => state.baseline.deferrals);
    const isCachedApplication = useSelector((state) => state.shared.isCachedApplication);

    const deferralsLogic = useMemo(
        () => new Deferrals(deferralList, primaryPlan, deferralSetupConfig),
        [deferralList, deferralSetupConfig, primaryPlan]
    );

    const getMinValue = useMemo(
        () => deferralsLogic.getMinContributionSliderRate,
        [deferralsLogic]
    );

    const nextStepsSettings = useSelector((state) => state.nextSteps.settings);

    const getMessage = (deferral) => {
        return (
            translations.sliders.contributionRate.tooltip +
            " " +
            deferralsLogic.getDisplaySliderMax(deferral)
        );
    };

    const getSliderWarningValue = (deferral) => {
        if (canShowSummary) {
            return null;
        } else {
            return deferralsLogic.getDeferralWarningValue(deferral);
        }
    };

    const handleEventBus = (type, target, data) => {
        const payload = omit(data, ["action"]);
        dispatch(dispatchEventBus(type, target, payload)); // GA tracking
        dispatch(
            dispatchAmplitude({
                // Amplitude tracking
                event_type: AMPLITUDE_EVENTS.SELECT_SLIDER,
                event_properties: {
                    selection: type,
                    ...payload
                }
            })
        );
    };

    const handleSliderMoving = (value) => {
        dispatch(setSliderMovement(value));
    };

    const [sliderValues, setSliderValues] = useState({});

    useEffect(() => {
        const updatedSliderValues = {};
        deferralList.forEach((deferral) => {
            const min = getMinValue(deferral);
            const value = deferral.value < min ? min : deferral.value;
            updatedSliderValues[deferral.deferralTypeCode] = { value };
        });
        // eslint-disable-next-line react-hooks-extra/no-direct-set-state-in-use-effect
        setSliderValues(updatedSliderValues);
    }, [deferralList, getMinValue]);

    const debouncedSliderChangeHandler = useMemo(
        () =>
            debounce((type, value) => {
                try {
                    const selectedDeferral = participant.deferrals.find(
                        (deferral) => deferral.deferralTypeCode === type
                    );
                    dispatch(deferralSliderChange(type, value, selectedDeferral));
                } catch (error) {
                    console.error("Error in handleSliderChange", error);
                }
            }, 300),
        [dispatch, participant.deferrals]
    );

    const handleSliderChange = (type, value) => {
        setSliderValues((prev) => ({
            ...prev,
            [type]: { ...prev[type], value }
        }));
        debouncedSliderChangeHandler(type, value);
    };

    const canShowDeferral = (deferral, id) => {
        return (
            displayDeferral({ deferral, id, canShowSummary }) &&
            (showSummaryExpansion || !canShowSummary)
        );
    };

    return deferralList.map((deferral, id) => {
        const min = getMinValue(deferral);
        const max = deferralsLogic.getMaxContributionSliderRate(deferral);
        const bnsTargetValue = getRoundedNextStepValue(
            baselineDeferrals,
            deferral,
            primaryPlan,
            nextStepsSettings,
            nextStepsSettings.roundedRecommendedValue
        );
        const canShowEstimated = showEstimated(deferral, deferralList.length);
        const suffix = deferralsLogic.getDeferralSuffix(deferral);
        const isContributionSliderEnabled =
            deferralsLogic.getIsContributionSliderEnabled(deferral) && !isCachedApplication;

        // Only the primary plan will be set to terminated; update HSA terminated status if primary plan is terminated
        if (
            deferral.deferralTypeCode === DeferralConstants.typeCodes.HSA &&
            primaryPlan.terminated
        ) {
            deferral.plan.terminated = true;
        }
        const ariaLabelText = `${deferral.config.displayName} ${translations.sliders.contributionRate.label} ${translations.sliders.contributionRate.editableTextAria}`;
        const ariaDescribedBy = ariaControlsSlidersHomePage;

        const deferralEnabled = canShowDeferral(deferral, id);

        return (
            deferralEnabled && (
                <div
                    // eslint-disable-next-line react/no-array-index-key
                    key={`${deferral.deferralTypeCode}-${id}`}
                    className="summaryBreakdownSlider"
                    data-testid="contributions-sliders"
                >
                    <SliderWrapper
                        identifier="contribution-rate-slider"
                        sliderId={deferral.deferralTypeCode}
                        max={max}
                        maxEditableValue={deferralsLogic.getMaxContributionRate(deferral)}
                        min={min}
                        minEditableValue={deferralsLogic.getMinContributionRate(deferral)}
                        value={sliderValues[deferral.deferralTypeCode]?.value}
                        conversionFactor={deferralsLogic.getConversionFactor(deferral)}
                        suffix={suffix}
                        prefix={deferralsLogic.getDeferralPrefix(deferral)}
                        //action to take if it is being moved
                        sliderMoving={handleSliderMoving}
                        //state of if it is being moved
                        sliderBeingMoved={sliderBeingMoved}
                        sliderChange={handleSliderChange}
                        enabled={isContributionSliderEnabled}
                        increment={deferralsLogic.getDeferralStep(deferral)}
                        editableStep={deferralsLogic.getDeferralEditableStep(deferral)}
                        maxDecimals={deferralsLogic.getDeferralDecimals(deferral)}
                        targetValue={bnsTargetValue}
                        minMessageValue={deferralsLogic.getMaxContributionSliderRate(deferral)} // should this be the min ?
                        maxMessageValue={max}
                        message={getMessage(deferral)}
                        showMessage={deferralsLogic.getShowDeferralMaxTooltip(deferral)}
                        maxEditableEntry={getMaxEditableEntry("contributionRate", deferral)}
                        titleLinkLabel={deferralsLogic.getContributionSliderLinkLabel(deferral)}
                        titleLinkUrl={deferralsLogic.getContributionSliderLinkUrl(
                            participant.individualId
                        )}
                        titleMessageList={deferralSetupConfig.additionalDeferralList}
                        getScreenSize={getScreenSize}
                        srContentId={SLIDER_CONTENT_ID}
                        warningValue={getSliderWarningValue(deferral)}
                        showEstimatedLink={canShowEstimated.singleEstimatedLink}
                        openCsorModal={() => handleShowCsorModal()}
                        minToolTipRangeValue={25}
                        maxToolTipRange={25}
                        labelPrefix={
                            canShowEstimated.showEstimated
                                ? translations.estimated + " " + deferral.config.displayName + " "
                                : deferral.config.displayName
                        }
                        sliderLabel="contributionRate"
                        showBottomText={false}
                        valueUnit={suffix === "%" ? "percent" : "dollar"}
                        sliderTranslations={translations}
                        dispatchEventBus={handleEventBus}
                        ariaDescribedBy={ariaDescribedBy}
                        ariaLabelText={ariaLabelText}
                    />
                </div>
            )
        );
    });
};

export default ContributionsSliders;
