const PROJECTED_INCOME = "dashboard";

//  all the states from the angular application which is referenced in the init() GA event as *route* name
export const HASH_ROUTES = {
    "#/dashboard/retirement-income": PROJECTED_INCOME + ".retirementIncome",
    "#/dashboard/social-security": PROJECTED_INCOME + ".socialSecurity",
    "#/dashboard/other-assets": PROJECTED_INCOME + ".otherAssets",
    "#/dashboard/income-gap": PROJECTED_INCOME + ".incomeGap",
    "#/dashboard/healthcare-costs": PROJECTED_INCOME + ".healthCareCosts",
    "#/dashboard/how-do-i-compare": PROJECTED_INCOME + ".howDoICompare",
    "#/dashboard/plan-balances": PROJECTED_INCOME + ".planBalances",
    "#/dashboard/plan-savings": PROJECTED_INCOME + ".planSavings",
    "#/dashboard/when-can-i-retire": PROJECTED_INCOME + ".whenCanIRetire"
};
