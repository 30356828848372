import type { AxiosResponse } from "axios";

import RequestFactory from "../factories/RequestFactory";
import type { ACCOUNT_INTERFACE, AccountHeader } from "../types/common/accounts";
import { executeRequest } from "../utils/apiUtils";
import { getUrl } from "../utils/urlUtils";

interface GET_ACCOUNT_RESPONSE {
    error: any;
    logInfo: any;
    notifications: any;
    spData: {
        accounts: ACCOUNT_INTERFACE[];
    };
    spHeader: AccountHeader;
    status: string;
}

const getAccountsList = async (csrf: string) => {
    try {
        const url = getUrl("getAccounts2");
        const method = RequestFactory.HTTP_METHOD.POST;
        const contentType = RequestFactory.HTTP_CONTENT_TYPE.FORM_DATA;
        const formData = new FormData();
        formData.append("csrf", csrf);
        const request = RequestFactory.create(url, formData, method, contentType, undefined);
        const res: AxiosResponse<GET_ACCOUNT_RESPONSE> = await executeRequest(request);
        const accounts = res.data.spData.accounts;

        return { accounts, spHeader: res.data.spHeader };
    } catch (err) {
        console.log("ERROR: getAccounts2", err);
        throw err;
    }
};

export default getAccountsList;
