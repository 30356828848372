import type IQuickViewAccount from "../../types";

const getPlanDetails = (accounts: IQuickViewAccount[], id: string | null): IQuickViewAccount => {
    if (accounts.length === 1) {
        return accounts[0];
    }

    if (id && accounts) {
        return accounts.find((qv) => qv.gaId === id) || accounts[0];
    }

    return accounts.find((qv) => qv.primary) || accounts[0];
};

export default getPlanDetails;
