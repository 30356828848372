import type { Query, QueryKey } from "@tanstack/react-query";

import type IGetAccountsData from "../../../queries/useGetAccounts2/IGetAccountsData";

type RefetchIntervalFn = (
    query: Query<IGetAccountsData, Error, IGetAccountsData, QueryKey>
) => number | false | undefined;

const ONE_MINUTE = 60000;
const FIVE_MINUTES = 300000;

// If getAccounts2 returns an account that is still aggregating (account.nextAction?.iconType === "AGGREGATING"), refetch every minute. Otherwise, refetch every 5 minutes.
const getAccounts2RefetchInterval: RefetchIntervalFn = (query) => {
    const accounts = query.state.data?.spData?.accounts || [];
    if (accounts?.some((account) => account.nextAction?.iconType === "AGGREGATING")) {
        return ONE_MINUTE;
    }
    return FIVE_MINUTES;
};

export default getAccounts2RefetchInterval;
