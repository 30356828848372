import type { Location } from "history";

import { RETIREMENT_INCOME_ROUTE } from "../../../../routes";
import {
    getLoginAppStart,
    getLoginLastHash
} from "../../../../utils/sentryMetricsLogging/getEvents";

const LIAT_PREVIOUS_ROUTE = "LIAT_PREVIOUS_ROUTE";

const initializeRouteLocation = ({
    hashLocation,
    displayModal
}: {
    displayModal: boolean;
    hashLocation: Location;
}) => {
    const loginHash = getLoginLastHash() || getLoginAppStart()?.hash;
    const isDeeplink = loginHash?.includes("#/d/");
    const previousRouteLocation = sessionStorage.getItem(LIAT_PREVIOUS_ROUTE);

    if (previousRouteLocation) {
        return displayModal ? JSON.parse(previousRouteLocation) : hashLocation;
    } else if (isDeeplink) {
        return {
            ...hashLocation,
            hash: RETIREMENT_INCOME_ROUTE,
            pathname: RETIREMENT_INCOME_ROUTE
        };
    }

    return hashLocation;
};

export default initializeRouteLocation;
