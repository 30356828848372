import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import type { FocusEvent } from "react";

import { useIsMutating } from "@tanstack/react-query";
import { selectTranslations } from "core-ui/client/src/app/core/translateServiceModule/TranslationsSelector";
import DateUtil from "core-ui/client/src/app/DateUtil";
import { Sidebar, LoadingBar } from "gw-shared-components";

import { useSelector } from "../../hooks/use-redux-actions";
import { useIsEmulator, useIsPrivileged } from "../../hooks/useEmulatorType";
import { useAccountSummary } from "../../queries";
import { QUERY_KEYS } from "../../queries/constants";
import useFunnelAttributes from "../../queries/useFunnelAttributes";
import useGetAccounts2 from "../../queries/useGetAccounts2";
import usePerformanceHistories from "../../queries/usePerformanceHistories";
import useQuerySession from "../../queries/useQuerySession";
import useUserTransactions from "../../queries/useUserTransactions/useUserTransactions";

import getAccounts2RefetchInterval from "./helpers/getAccounts2RefetchInterval";

const WEEK_KEY = "W";
const MONTH_KEY = "M";
const YEAR_KEY = "Y";

interface SidebarContainerProps {
    onBlur: () => void;
    onFocus: (event: FocusEvent<HTMLDivElement>) => void;
    shouldCollapse: boolean;
    sidebarClass: string;
}

const SidebarContainer = ({
    onBlur,
    onFocus,
    shouldCollapse,
    sidebarClass
}: SidebarContainerProps) => {
    const { data: querySessionData, isLoading: querySessionLoading } = useQuerySession();

    const {
        data: accounts2Data,
        isLoading: getAccounts2Loading,
        refetch
    } = useGetAccounts2(getAccounts2RefetchInterval, querySessionData?.spHeader?.SP_HEADER_VERSION);

    const [dateRangeKey, setDateRangeKey] = useState<string>(WEEK_KEY);

    const [userAccountIdToPerformanceChange, setUserAccountIdToPerformanceChange] = useState<
        Map<number, number> | undefined
    >();

    const userAccountIds = useMemo(() => {
        const accounts = accounts2Data?.spData?.accounts;
        return accounts?.map((account) => account.userAccountId);
    }, [accounts2Data]);

    const { mutateAsync: performanceHistoriesMutate } = usePerformanceHistories();

    const { mutateAsync: userTransactionsMutate } = useUserTransactions();

    const isMutatingPerformanceHistories = useIsMutating({
        mutationKey: [QUERY_KEYS.GET_PERFORMANCE_HISTORIES]
    });

    const isMutatingUserTransactions = useIsMutating({
        mutationKey: [QUERY_KEYS.GET_USER_TRANSACTIONS]
    });

    const handlePerformanceDataToggle = useCallback(
        async (dateRangeKey: string) => {
            setDateRangeKey(dateRangeKey);

            const currentDate = DateUtil.getDate();
            let previousDate = null;
            if (dateRangeKey === WEEK_KEY) {
                previousDate = DateUtil.subtractFromDate(7, "day", currentDate);
            } else if (dateRangeKey === MONTH_KEY) {
                previousDate = DateUtil.subtractFromDate(1, "month", currentDate);
            } else if (dateRangeKey === YEAR_KEY) {
                previousDate = DateUtil.subtractFromDate(1, "year", currentDate);
            }

            if (previousDate && userAccountIds) {
                const newUserAccountIdToPerformanceChange = new Map<number, number>();

                const performanceHistories = await performanceHistoriesMutate({
                    startDate: DateUtil.getDateFormatted("YYYY-MM-DD", previousDate),
                    endDate: DateUtil.getDateFormatted("YYYY-MM-DD", currentDate)
                });

                performanceHistories.spData?.accountSummaries?.forEach((accountSummary) => {
                    const { userAccountId, dateRangeBalanceValueChange } = accountSummary;
                    newUserAccountIdToPerformanceChange.set(
                        userAccountId,
                        dateRangeBalanceValueChange
                    );
                });

                const pendingPerformanceChanges = userAccountIds.map(async (userAccountId) => {
                    if (!newUserAccountIdToPerformanceChange.has(userAccountId)) {
                        const userTransactions = await userTransactionsMutate({
                            startDate: DateUtil.getDateFormatted("YYYY-MM-DD", previousDate),
                            endDate: DateUtil.getDateFormatted("YYYY-MM-DD", currentDate),
                            userAccountIds: [userAccountId]
                        });
                        if (userTransactions.spData) {
                            const netCashFlow = userTransactions.spData.netCashflow || 0;
                            newUserAccountIdToPerformanceChange.set(userAccountId, netCashFlow);
                        }
                    }
                });
                await Promise.all(pendingPerformanceChanges);

                setUserAccountIdToPerformanceChange(newUserAccountIdToPerformanceChange);
            }
        },
        [userAccountIds, performanceHistoriesMutate, userTransactionsMutate]
    );

    useEffect(() => {
        // Default for the toggle buttons is week
        handlePerformanceDataToggle(WEEK_KEY);
    }, [handlePerformanceDataToggle]);

    const refreshAccounts = useCallback(() => {
        refetch();
        handlePerformanceDataToggle(dateRangeKey);
    }, [refetch, handlePerformanceDataToggle, dateRangeKey]);

    useEffect(() => {
        window.addEventListener("refreshAccounts", refreshAccounts);
        return () => {
            window.removeEventListener("refreshAccounts", refreshAccounts);
        };
    }, [refreshAccounts]);

    const { data: funnelAttributesData, isLoading: funnelAttributesLoading } =
        useFunnelAttributes();

    const { data: summaryData } = useAccountSummary();

    const isEmulator = useIsEmulator();

    const isPrivilegedEmulator = useIsPrivileged();

    // Only show the net worth section if CXPFD is on
    const { pcapWidgetsEnabled: showNetWorthSection } = useSelector(
        (state) => state.shared.txnAccess
    );

    const sidebarRef = useRef<HTMLDivElement>(null);

    const handleSidebarBlur = useCallback(
        (event: FocusEvent<HTMLDivElement>) => {
            if (sidebarRef.current && !sidebarRef.current.contains(event.relatedTarget)) {
                onBlur();
            }
        },
        [onBlur]
    );

    const handleSidebarFocus = useCallback(
        (event: FocusEvent<HTMLDivElement>) => {
            if (shouldCollapse && sidebarRef?.current?.contains(event.target)) {
                onFocus(event);
            }
        },
        [shouldCollapse, onFocus]
    );

    const sidebarTranslations = selectTranslations("aggregatorSidebar");

    if (querySessionLoading || getAccounts2Loading || funnelAttributesLoading) {
        return (
            <div className="sidebar-container bg-white rounded-border">
                <LoadingBar barWidth="80%" loadingTime={4} position="center" restartLoading />
            </div>
        );
    }

    if (
        !accounts2Data ||
        !accounts2Data.spData ||
        !funnelAttributesData ||
        !funnelAttributesData.spData ||
        !sidebarTranslations
    ) {
        return null;
    }

    const aggregationLevelNone = funnelAttributesData.spData.aggregationLevel === "NONE";
    const performanceChangeIsLoading =
        isMutatingPerformanceHistories > 0 || isMutatingUserTransactions > 0;

    return (
        <div
            ref={sidebarRef}
            id="sidebarContent"
            className={`new-sidebar-container ${sidebarClass}`}
            onMouseLeave={onBlur}
            onBlur={handleSidebarBlur}
            onFocus={handleSidebarFocus}
        >
            <Sidebar
                accounts={accounts2Data.spData.accounts}
                aggregationLevelNone={aggregationLevelNone}
                handlePerformanceDataToggle={handlePerformanceDataToggle}
                isEmulator={isEmulator}
                isPrivilegedEmulator={isPrivilegedEmulator}
                performanceChangeIsLoading={performanceChangeIsLoading}
                sidebarTranslations={sidebarTranslations}
                showNetWorthSection={showNetWorthSection}
                summaryData={summaryData}
                userAccountIdToPerformanceChange={userAccountIdToPerformanceChange}
            />
        </div>
    );
};

export default SidebarContainer;
