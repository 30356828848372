import { selectTranslations } from "core-ui/client/src/app/core/translateServiceModule/TranslationsSelector";

const TEST_SIZE: {
    "100": string[];
    "25": string[];
    "50": string[];
    "75": string[];
} = {
    100: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "a", "b", "c", "d", "e", "f"],
    25: ["0", "1", "2", "3"],
    50: ["0", "1", "2", "3", "4", "5", "6", "7"],
    75: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "a", "b"]
};

export const userHasWcirDefault = (userGuid: string): boolean => {
    if (!userGuid) {
        console.error("userGuid is null or undefined");
        return false;
    }
    const translations = selectTranslations("whenCanIRetire");
    const lastCharacter = userGuid.slice(-1); // Get the last character of the uuid

    const sampleArray = TEST_SIZE[translations.setDefaultView]; // Get the corresponding sample size array
    // Check if the last character is in the sample size array
    const result = sampleArray.includes(lastCharacter);
    return result;
};
