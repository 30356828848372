import React, { useCallback, useState, useEffect } from "react";

const ACCOUNT_OPTION_PREFIX = "account-selector-option-";
import { truncateSubsetName } from "../../helpers";
import type IQuickViewAccount from "../../types";

export interface QuickViewDropdownProps {
    dropDownChangeHandler: (e: any) => void;
    inContextFullList: IQuickViewAccount[];
    selectedPlan: IQuickViewAccount;
    truncatedSubsetName: string;
}

const QuickViewDropdown = ({
    inContextFullList,
    dropDownChangeHandler,
    selectedPlan,
    truncatedSubsetName
}: QuickViewDropdownProps) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [focusIndex, setFocusIndex] = useState<number>(0);

    const listener = useCallback((e: MouseEvent) => {
        const dropdown = document.querySelector("#quick-view-dropdown");

        if (!dropdown?.contains(e.target as HTMLElement)) {
            setIsOpen(false);
        }
    }, []);

    // Dismiss dropdown when clicking outside of container
    useEffect(() => {
        if (isOpen) {
            document.addEventListener("click", listener);
        } else {
            document.removeEventListener("click", listener);
        }
    }, [isOpen, listener]);

    const options = inContextFullList.map((option) => {
        const value = option.planName;
        return { ...option, value };
    });

    const handleClick = useCallback(() => {
        setIsOpen(!isOpen);
    }, [isOpen]);

    const handleKeyDown = (e: any) => {
        switch (e.key) {
            case "Enter":
            case " ":
                {
                    e.preventDefault();
                    dropDownChangeHandler(e);
                    handleClick();
                }

                break;
            case "Escape":
            case "Tab":
                {
                    setIsOpen(false);
                    document.getElementById("quick-view-dropdown")?.focus();
                }

                break;
            case "ArrowUp":
                {
                    e.preventDefault();
                    const prevIndex = focusIndex === 0 ? options.length - 1 : focusIndex - 1;
                    document.getElementById(`${ACCOUNT_OPTION_PREFIX}${prevIndex}`)?.focus();
                    setFocusIndex(prevIndex);
                }

                break;
            case "ArrowDown":
                {
                    e.preventDefault();
                    if (!isOpen) setIsOpen(true);
                    const nextIndex = focusIndex === options.length - 1 ? 0 : focusIndex + 1;
                    document.getElementById(`${ACCOUNT_OPTION_PREFIX}${nextIndex}`)?.focus();
                    setFocusIndex(nextIndex);
                }

                break;
            default:
                break;
        }
    };

    return (
        <div
            className="custom-select custom-select-medium form-control d-flex"
            onClick={handleClick}
            onKeyDown={handleKeyDown}
            data-testid="quick-view-dropdown"
            id="quick-view-dropdown"
            aria-haspopup="true"
            aria-expanded={isOpen}
            aria-label="Account Balance"
            tabIndex={0}
            role="button"
            style={{ minHeight: selectedPlan.planName?.length > 65 ? "60px" : "40px" }} // at 65 characters, the quick view dropdown text is in 3 lines
        >
            <div className="dropdown-container">
                <span
                    id="quick-view-display"
                    className="dropdown-toggle"
                    aria-label={`${selectedPlan.name} ${truncatedSubsetName}`}
                >
                    {selectedPlan.planName}
                    {truncatedSubsetName && <em>{`- ${truncatedSubsetName}`}</em>}
                </span>
            </div>
            <i className="fa fa-solid fa-chevron-down fa-icon"></i>
            <ul
                className={`dropdown-menu ${isOpen ? "visible" : ""}`}
                style={{
                    display: `${isOpen ? "block" : "none"}`,
                    visibility: `${isOpen ? "visible" : "hidden"}`
                }}
                aria-label="dropdown menu"
                role="listbox"
                data-testid="quick-view-dropdown-list"
            >
                {options.map((option, id) => {
                    return (
                        <li
                            className="quickview-value menu-item"
                            key={option.gaId}
                            id={`${ACCOUNT_OPTION_PREFIX}${id}`}
                            aria-label={`${option.value} ${truncateSubsetName(option.subsetName)}`}
                        >
                            <button
                                id={option.gaId}
                                onClick={dropDownChangeHandler}
                                className={`${
                                    selectedPlan.planName === option.value
                                        ? "dropdown-selected-menu"
                                        : null
                                } quick-view-dropdown-button`}
                            >
                                {option.value}
                                {option.subsetName && (
                                    <em data-testid="subsetName">
                                        <br />
                                        {`- ${truncateSubsetName(option.subsetName)}`}
                                    </em>
                                )}
                            </button>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

export default QuickViewDropdown;
