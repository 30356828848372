import { UPDATE_COMPANY_MATCH } from "../../actions/companyMatch";
import {
    DEFERRAL_CHANGED_PRIMARY_PLAN_MAXIMZER_UNENROLLING,
    SET_PRIMARY_PLAN_RULES_SALARY_REMINDER
} from "../../actions/deferrals/deferralActionTypes";
import { SET_INIT_DATA } from "../../actions/initData";
import {
    SET_PRIMARY_PLAN,
    SET_MAXIMIZER_IS_ONGOING,
    SET_DEFERRAL_RESTRICTIONS,
    SET_ALLOW_DEFERRAL,
    SET_DEFERRAL_INFO,
    UPDATE_PRIMARY_PLAN_SALARY,
    SET_PRIMARY_PLAN_ID
} from "../../actions/plans/plansActionsTypes";
import { findPrimaryPlan } from "../../utils/planUtils";

const initialState = {
    assetAllocationEligibility: null,
    balances: [],
    companyMatch: null, //old tables for apple, etc
    dataIsLoaded: false,
    deferralInfo: {},
    deferralRestrictions: null,
    deferrals: [],
    discretionaryContribution: null,
    employerMatch: null, //using new payroll system
    equityPercent: 0,
    hsaDetails: null,
    id: "",
    investmentHoldings: [],
    irsCode: "",
    isPrimary: true,
    limits: { ageCatchupAllowed: null, ageCatchupMethodCode: null },
    maximizer: {
        deferralPercent: [],
        enrollType: "",
        enrollmentSelection: "",
        isOngoing: false,
        isUnenrolling: false,
        remainingPayPeriods: 0,
        salary: 0,
        totalMaxDefPercent: 0,
        totalPayPeriods: 0,
        wasEnrolled: undefined
    },
    modelPortfolio: {
        modelDescription: "Default Model Portfolio Name",
        modelNumber: "123456",
        effectiveDate: null,
        rebalanceDate: null
    },
    name: "",
    newDeferrals: [], // deprecated
    nqEnrollmentInfo: null,
    payFrequency: 0,
    planRules: {
        allowSalaryReset: true,
        allowAddNewContributions: false,
        allowAntiDiscount: false,
        allowDeferral: null,
        allowMultipleDeferrals: false,
        deferralMessage: null,
        employerDirectedAllocationsOnly: false,
        feForecastEnabled: false,
        hdicEnabled: false,
        hvsEnabled: false,
        maximizer: false,
        monthlyDeferralsEnabled: null,
        otherAssetsEnabled: false,
        promptForAge: null,
        promptForSalary: null,
        salaryReminder: false,
        showLimitMsg: true,
        showSalary: null,
        specialCatchup: null,
        ssEnabled: null,
        usePayrollEmployerMatch: true,
        inPlanRothConversionInd: false
    },
    profitSharing: null,
    retirementAge: 0,
    retirementOption: { confidence: 0, equityPct: 0, inclSocialSec: true, retireAge: 0 },
    salary: {
        base: 0,
        variable: 0,
        total: 60000,
        tempVariable: null,
        source: "",
        nqContributionPct: null
    },
    terminated: false,
    trsFlexAccountInfo: null,
    yearsOfService: null
};

export default (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case SET_INIT_DATA:
            return { ...state, ...findPrimaryPlan(payload.user.plans), dataIsLoaded: true };
        case SET_PRIMARY_PLAN:
            return { ...state, ...payload };
        case UPDATE_COMPANY_MATCH:
            return { ...state, companyMatch: { ...state.companyMatch, ...payload } };
        case DEFERRAL_CHANGED_PRIMARY_PLAN_MAXIMZER_UNENROLLING:
            return { ...state, maximizer: { ...state.maximizer, isUnenrolling: payload } };
        case SET_PRIMARY_PLAN_RULES_SALARY_REMINDER:
            return { ...state, planRules: { ...state.planRules, salaryReminder: payload } };
        case SET_MAXIMIZER_IS_ONGOING:
            return { ...state, maximizer: { ...state.maximizer, isOngoing: payload } };
        case SET_DEFERRAL_RESTRICTIONS:
            return { ...state, deferralRestrictions: payload };
        case SET_ALLOW_DEFERRAL:
            return { ...state, planRules: { ...state.planRules, allowDeferral: payload } };
        case SET_DEFERRAL_INFO:
            return { ...state, deferralInfo: { ...state.deferralInfo, ...payload } };
        case UPDATE_PRIMARY_PLAN_SALARY:
            return { ...state, salary: { ...state.salary, ...payload } };
        case SET_PRIMARY_PLAN_ID:
            return { ...state, id: payload };
        default:
            return state;
    }
};
