import { useQuery } from "@tanstack/react-query";
import { useAtomValue } from "jotai";

import { csrfAtom } from "../../atoms/atoms";
import getFunnelAttributesData from "../../services/getFunnelAttributesData";
import type { FunnelData } from "../../types/common/funnelAttributes";
import { QUERY_KEYS } from "../constants";

const useFunnelAttributes = () => {
    const csrf = useAtomValue(csrfAtom);

    return useQuery<FunnelData["data"]>({
        queryKey: [QUERY_KEYS.GET_FUNNEL_DATA],
        queryFn: async () => {
            const response = await getFunnelAttributesData();
            return response.data;
        },
        enabled: !!csrf
    });
};

export default useFunnelAttributes;
