import { useQuery } from "@tanstack/react-query";

import type IQuickViewAccount from "../../components/quickView/types";
import { useSelector } from "../../hooks/use-redux-actions";
import { getQuickViewAccountData } from "../../services/accountSummaryService";
import { QUERY_KEYS } from "../constants";

const useQuickViewAccounts = () => {
    const participant = useSelector((state) => state.participant);
    const primaryPlan = useSelector((state) => state.primaryPlan);

    const individualId = participant.individualId;
    const planId = primaryPlan.id;

    return useQuery<IQuickViewAccount[]>({
        queryKey: [QUERY_KEYS.GET_QUICK_VIEW_ACCOUNTS_DATA, individualId, planId],
        queryFn: getQuickViewAccountData,
        enabled: individualId !== "" && planId !== ""
    });
};

export default useQuickViewAccounts;
