import { useEffect } from "react";

import { useQuery } from "@tanstack/react-query";
import { useAtomValue } from "jotai";
import { useHistory } from "react-router-dom";

import {
    ROUTE_OPEN_ACCOUNT_OPTIONS,
    ROUTE_SETTINGS,
    ROUTE_SETTINGS_PROFILE
} from "../../../../../routes";
import { isRetailAtom, csrfAtom } from "../../../../atoms/atoms";
import { setHasRetailAccount } from "../../../../middleware/otherAssetsMiddleware";
import { QUERY_KEYS } from "../../../../queries/constants";

// Allowed routes for a user without a retail account
const ALLOWED_ROUTES_NO_RETAIL_ACCOUNT = [ROUTE_SETTINGS_PROFILE, ROUTE_SETTINGS];

const useRetailAccount = () => {
    const isRetail = useAtomValue(isRetailAtom);

    const csrf = useAtomValue(csrfAtom);
    const history = useHistory();

    const { data: hasRetailAccount, isLoading: hasRetailAccountIsLoading } = useQuery({
        // eslint-disable-next-line @tanstack/query/exhaustive-deps
        queryKey: [QUERY_KEYS.GET_ACCOUNTS_LITE],
        queryFn: () => setHasRetailAccount(csrf),
        enabled: !!csrf
    });

    // WCDX-14129 if user is on Profile page do not change route when refreshed to open account
    const isAllowedRoute = ALLOWED_ROUTES_NO_RETAIL_ACCOUNT.some((route) =>
        window.location.hash.includes(route)
    );

    useEffect(() => {
        if (isRetail !== null && hasRetailAccount !== undefined && !hasRetailAccountIsLoading) {
            if (isRetail) {
                if (hasRetailAccount) {
                    globalThis.integratedEligibility.hasRetailAccount = true;
                } else {
                    if (!isAllowedRoute) {
                        history.push(ROUTE_OPEN_ACCOUNT_OPTIONS);
                    }
                    globalThis.integratedEligibility.hasRetailAccount = false;
                }
            }
        }
    }, [hasRetailAccount, isRetail, hasRetailAccountIsLoading, isAllowedRoute, history]);
};

export default useRetailAccount;
