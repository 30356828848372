import React from "react";

import { useSearchBox } from "core-ui/client/react/core/hooks/useSearchBox";
import { SearchboxContainer } from "gw-shared-components";

const SearchBoxWrapper = () => {
    const {
        isSearchboxOpen,
        setIsSearchboxOpen,
        flags,
        urlParams,
        externalCustomActions,
        externalLabels,
        externalURLs
    } = useSearchBox();

    return (
        <SearchboxContainer
            isOpen={isSearchboxOpen}
            onOutsideClick={() => setIsSearchboxOpen(false)}
            flags={flags}
            urlParams={urlParams}
            externalCustomActions={externalCustomActions}
            externalLabels={externalLabels}
            externalURLs={externalURLs}
        />
    );
};

export default SearchBoxWrapper;
