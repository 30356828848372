import type { IProjectedIncome } from "../../../../types/common";

const getProjectedHsaCoverage = (incomeParts: IProjectedIncome["incomeParts"]): number => {
    if (incomeParts.length) {
        const hsaPart = incomeParts.find((part) => part.id === "hsa");
        return hsaPart?.value || 0;
    }

    return 0;
};

export default getProjectedHsaCoverage;
