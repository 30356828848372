import type { AxiosResponse } from "axios";

import type { IPrimaryPlan } from "../types/common";
import { executeGet } from "../utils/apiUtils.js";
import { getUrl } from "../utils/urlUtils.js";

export const getPrimaryPlan = async () => {
    const url = getUrl("getPrimaryPlan");
    const response: AxiosResponse<IPrimaryPlan> = await executeGet(url);
    return response.data;
};

export default getPrimaryPlan;
