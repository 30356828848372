import React, { useState, useEffect, useCallback } from "react";

import { selectTranslations } from "core-ui/client/src/app/core/translateServiceModule/TranslationsSelector";
import StringUtil from "core-ui/client/src/app/StringUtil";
import Parser from "html-react-parser";
import { Modal } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";

import {
    setShowLockheedModal,
    showLockheedEnroll,
    showLockheedTermsOfService,
    showLockheedNoElections
} from "../../../actions/enrollment/enrollmentAction";
import ReferenceData from "../../../constants/ReferenceData";

const ELECT_TO_OPT_OUT = "ELECT TO OPT OUT";
const ELECT_TO_NQ = "ELECT TO NQ";

export const LockheedModal = () => {
    const dispatch = useDispatch();
    const appTranslations = selectTranslations("app");
    const nonqualTranslations = selectTranslations("nonqual");
    const nqModalTranslations = selectTranslations("nqModal");

    const enrollment = useSelector((state) => state.enrollment);
    const plans = useSelector((state) => state.paycheck.nqInfo);
    const plan = plans[0];

    const [initialize, setInitialize] = useState(false);

    // Enrollment Radio Button Constants
    const ENROLL_NOW = nonqualTranslations?.enrollment?.lockheedModal?.enrollNow;
    const OPT_OUT = nonqualTranslations?.enrollment?.lockheedModal?.optOut;
    const NO_CHANGES = nonqualTranslations?.enrollment?.lockheedModal?.noElections;

    const [selectedEnrollmentOption, setSelectedEnrollmentOption] = useState("");

    /* eslint-disable react-hooks-extra/no-direct-set-state-in-use-effect */
    const getChainingOptionValue = useCallback(
        (chainingOption) => {
            if (selectedEnrollmentOption == "") {
                if (chainingOption == ELECT_TO_OPT_OUT) {
                    setSelectedEnrollmentOption(OPT_OUT);
                } else if (chainingOption == ELECT_TO_NQ) {
                    setSelectedEnrollmentOption(ENROLL_NOW);
                }
            } else {
                if (chainingOption == ELECT_TO_OPT_OUT) {
                    setSelectedEnrollmentOption(OPT_OUT);
                } else if (chainingOption == ELECT_TO_NQ) {
                    setSelectedEnrollmentOption(ENROLL_NOW);
                }
            }
        },
        [ENROLL_NOW, OPT_OUT, selectedEnrollmentOption]
    );
    /* eslint-disable react-hooks-extra/no-direct-set-state-in-use-effect */

    useEffect(() => {
        if (enrollment && enrollment.dataLoaded && !initialize) {
            getChainingOptionValue(enrollment.currentChainingOption);

            setInitialize(true);
        }
    }, [enrollment, getChainingOptionValue, initialize]);

    // Enrollment modal view
    const enroll = () => {
        dispatch(showLockheedEnroll(false));
        dispatch(showLockheedTermsOfService(true));
    };

    const handleCancel = (cancelType) => {
        switch (cancelType) {
            case "E":
                // close lockheed modal
                dispatch(setShowLockheedModal(false));
                break;
            case "O":
                // close opt out view, close lockheed modal
                dispatch(showLockheedNoElections(false));
                dispatch(setShowLockheedModal(false));
                break;
        }
    };

    const subtitle = nonqualTranslations
        ? StringUtil.supplant(nonqualTranslations?.enrollment?.lockheedModal?.liatSubTitle, {
              enrollmentEndDate: enrollment.enrollmentEndDate
          })
        : "";

    // Terms of service modal view
    const title = nonqualTranslations
        ? StringUtil.supplant(nonqualTranslations?.enrollment?.lockheedModal?.liatTitle, {
              planName: enrollment.planName
          })
        : "";

    const planName = enrollment.planName;

    const enrollOptions = [
        { id: "1", value: ENROLL_NOW },
        { id: "2", value: OPT_OUT },
        { id: "3", value: NO_CHANGES }
    ];

    const [planId, setPlanId] = useState("");
    const chainingOption = enrollment.currentChainingOption;

    useEffect(() => {
        if (planId === "" && plan && plan.planId > "") {
            setPlanId(plan.planId);
        }
        if (planId === "" && plan && plan.id > "") {
            setPlanId(plan.id);
        }
    }, [plan, planId, plans]);

    const getEnrollPlanLink = function () {
        return StringUtil.supplant(ReferenceData.URL_NQ_ENROLLMENT_PLAN, { planId: planId });
    };

    const getOptOutLink = function () {
        return StringUtil.supplant(ReferenceData.URL_ENROLLMENT_OPT_OUT, { planId: planId });
    };

    const onStaySaveClick = () => {
        if (selectedEnrollmentOption == ENROLL_NOW) {
            // uses same url link as NQ Enrollment
            window.location.href = getEnrollPlanLink();
            dispatch(setShowLockheedModal(false));
            dispatch(showLockheedTermsOfService(false));
        } else if (selectedEnrollmentOption == OPT_OUT) {
            window.location.href = getOptOutLink();
            dispatch(setShowLockheedModal(false));
            dispatch(showLockheedTermsOfService(false));
        } else if (selectedEnrollmentOption == NO_CHANGES) {
            dispatch(showLockheedTermsOfService(false));
            dispatch(showLockheedNoElections(true));
        }
    };

    const onChangeEnrollOption = (e) => {
        setSelectedEnrollmentOption(e.target.value);
    };

    const renderEnrollRows = (enrollOptionsList) => {
        return enrollOptionsList.map((enrollOption) => {
            return (
                <div key={enrollOption.id}>
                    <label htmlFor={enrollOption.id} className="radio-inline">
                        <input
                            type="radio"
                            name="enrollOptions"
                            id={enrollOption.id}
                            value={enrollOption.value}
                            checked={enrollOption.value === selectedEnrollmentOption}
                            onChange={onChangeEnrollOption}
                        />
                        <span>{enrollOption.value} </span>
                    </label>
                </div>
            );
        });
    };

    return (
        enrollment.showLockheedModal && (
            <div data-testid="lockheed-modal">
                {enrollment.showLockheedEnroll && (
                    <Modal
                        show={enrollment.showLockheedModal}
                        onHide={handleCancel}
                        dialogClassName="lockheed-modal-dialog"
                    >
                        <div className="nq-modal">
                            <h3 className="modal-title" id="modal-title">
                                {nqModalTranslations?.title}
                            </h3>
                            <div className="modal-header">
                                <div data-testid="lockheed-subtitle">{subtitle}</div>
                            </div>
                            <div className="modal-body" id="modal-body">
                                <div className="row">
                                    <div className="col-xs-12 col-sm-8 col-md-8 text-left">
                                        <h6
                                            className="modal-sub-title"
                                            id="modal-sub-title"
                                            data-testid="lockheed-planName"
                                        >
                                            {planName}
                                            <br />
                                        </h6>
                                    </div>
                                    <div className="col-xs-12 col-sm-4 col-md-4 text-right">
                                        <button
                                            className="submit-btn btn btn-primary"
                                            type="button"
                                            onClick={() => {
                                                enroll(plan);
                                            }}
                                        >
                                            {nqModalTranslations["enroll-now"]}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <div className="footer-content">
                                    <div className="btns">
                                        <button
                                            className="cancel-btn btn btn-link"
                                            type="button"
                                            onClick={() => handleCancel("E")}
                                        >
                                            {nqModalTranslations["close-without-enrolling"]}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal>
                )}

                {enrollment.showLockheedTermsOfSvc && (
                    <Modal
                        id="lockheed-modal"
                        show={enrollment.showLockheedModal}
                        onHide={handleCancel}
                        dialogClassName="lockheed-modal-dialog"
                    >
                        <div className="nq-modal">
                            <div className="modal-header auto-increase">
                                <div className="modal-title">{title}</div>
                                <hr className="hr-auto-increase" />
                                <div>{subtitle}</div>
                                {chainingOption === ELECT_TO_NQ && (
                                    <div className="lockheed-modal">
                                        {Parser(
                                            nonqualTranslations?.enrollment?.lockheedModal
                                                ?.enrolledSubHeading
                                        )}
                                    </div>
                                )}
                                {chainingOption === ELECT_TO_OPT_OUT && (
                                    <div className="lockheed-modal">
                                        {Parser(
                                            nonqualTranslations?.enrollment?.lockheedModal
                                                ?.optOutSubHeading
                                        )}
                                    </div>
                                )}
                            </div>
                            <div className="modal-body auto-increase">
                                <div className="input-group">{renderEnrollRows(enrollOptions)}</div>
                                <br />
                                {chainingOption !== ELECT_TO_OPT_OUT && (
                                    <div className="lockheed-modal">
                                        {
                                            nonqualTranslations?.enrollment?.lockheedModal
                                                ?.enrolledSubHeading2
                                        }
                                    </div>
                                )}
                                {chainingOption === ELECT_TO_OPT_OUT && (
                                    <div className="lockheed-modal">
                                        {
                                            nonqualTranslations?.enrollment?.lockheedModal
                                                ?.optOutSubHeading2
                                        }
                                    </div>
                                )}
                            </div>
                            <div className="modal-footer auto-increase">
                                <div>
                                    <button
                                        type="button"
                                        className="btn btn-primary pull-right"
                                        onClick={onStaySaveClick}
                                    >
                                        {nonqualTranslations?.enrollment?.lockheedModal?.continue}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Modal>
                )}

                {enrollment.showLockheedNoElections && (
                    <Modal
                        id="lockheed-modal"
                        show={enrollment.showLockheedModal}
                        onHide={handleCancel}
                        dialogClassName="lockheed-modal-dialog"
                    >
                        <div className="nq-modal">
                            <div className="modal-content">
                                <div className="modal-header auto-increase">
                                    <div className="modal-title">
                                        {
                                            nonqualTranslations?.enrollment?.lockheedModal
                                                ?.noChangesHeading
                                        }
                                    </div>
                                </div>
                                <div className="modal-body auto-increase">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <p>
                                                {
                                                    nonqualTranslations?.enrollment?.lockheedModal
                                                        ?.noChangesElectionInfo
                                                }
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer auto-increase">
                                    <button
                                        type="button"
                                        className="btn btn-link btn-cancel"
                                        onClick={() => handleCancel("O")}
                                    >
                                        {appTranslations?.common?.labels?.cancel}
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={() => handleCancel("O")}
                                    >
                                        {appTranslations?.common?.labels?.confirmContinue}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Modal>
                )}
            </div>
        )
    );
};
