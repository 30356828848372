import axios from "axios";

import RequestFactory from "../factories/RequestFactory";
import { getUrl } from "../utils/urlUtils";

interface UserTransactions {
    spData?: {
        netCashflow?: number;
    };
}

const getUserTransactions = (
    csrf: string,
    startDate: string,
    endDate: string,
    userAccountIds: number[] = []
) => {
    const url = getUrl("getUserTransactions");
    const data = new FormData();
    data.append("csrf", csrf);
    data.append("startDate", startDate);
    data.append("endDate", endDate);
    data.append("userAccountIds", JSON.stringify(userAccountIds));

    return axios<UserTransactions>({
        method: RequestFactory.HTTP_METHOD.POST,
        headers: { "content-type": RequestFactory.HTTP_CONTENT_TYPE.URL_ENCODED },
        url,
        data,
        withCredentials: true
    });
};

export default getUserTransactions;
