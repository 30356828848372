import React, { useState, useEffect } from "react";

import { selectTranslations } from "core-ui/client/src/app/core/translateServiceModule/TranslationsSelector";
import { Loader, isUndefinedOrNull } from "gw-shared-components";
import { merge as _merge } from "lodash";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";

import HSAConstants from "../../constants/HSAConstants";
import { saveHsaPreferences } from "../../services/hsaService";
import { Header } from "../../shared/paycheckPrimaryControls";

import { SaveButton, HsaExpenseOption, CustomHsaExpenseOption } from "./components/index";
import { formatVal, getHsaExpenseSelectionType } from "./hsaHelpers";

const { NATIONAL_AVERAGES, SPEND_CODES } = HSAConstants;

const HsaTabBenefits = ({ benefits }) => {
    return (
        <div className="hsa-tab-benefits">
            <ol>
                {benefits.map((item) => (
                    <li key={item}>{item}</li>
                ))}
            </ol>
        </div>
    );
};

const HealthSavingsAccount = () => {
    const dispatch = useDispatch();
    const rivdTranslations = selectTranslations("hsa");
    const redwoodTranslations = selectTranslations("redwood")?.hsa;
    const translations = _merge(rivdTranslations, redwoodTranslations);
    const benefits = [
        translations.benefits.benefit1,
        translations.benefits.benefit2,
        translations.benefits.benefit3
    ];
    const hsaPlan = useSelector((state) => state.participant.hsaPlan);
    const participant = useSelector((state) => state.participant);
    const hsaLoader = useSelector((state) => state.participant.hsaLoader);
    const dashboardHsaClass = "dashboard-hsa";

    const previousYearExpense = hsaPlan ? hsaPlan.hsaDetails.aggrPriorYearSpend : null;
    const hasPreviousYearExpense =
        !isUndefinedOrNull(previousYearExpense) && previousYearExpense !== 0;

    const formattedPreviousYearExpense = formatVal(previousYearExpense);

    const nationalAverage = hsaPlan
        ? hsaPlan.hsaDetails.nationalAverageValues[
              NATIONAL_AVERAGES[hsaPlan.trsFlexAccountInfo.coverageType]
          ]
        : 1000; // default to single
    const formattedNationalAverage = formatVal(nationalAverage);

    const [hsaExpenseOption, setHsaExpenseOption] = useState("");
    const [customAmount, setCustomAmount] = useState(0);
    const [showSaveBtn, setShowSaveBtn] = useState(false);

    useEffect(() => {
        if (hsaPlan && hsaExpenseOption === "") {
            const getExpenseOption = getHsaExpenseSelectionType(hsaPlan);
            // eslint-disable-next-line react-hooks-extra/no-direct-set-state-in-use-effect
            setHsaExpenseOption(getExpenseOption);
            // eslint-disable-next-line react-hooks-extra/no-direct-set-state-in-use-effect
            setCustomAmount(hsaPlan.hsaDetails.customExpenseAmount);
        }
    }, [hsaExpenseOption, hsaPlan]);

    const handleSave = () => {
        const savePrefConfig = {
            hsaSelectionInd: hsaExpenseOption, // P - Prior Year, N - National Average, C - Custom value spend
            hsaExpenseAmount: customAmount
        };

        saveHsaPreferences(
            participant.clientId,
            participant.individualId,
            hsaPlan.id,
            savePrefConfig,
            dispatch
        );

        setShowSaveBtn(false);
    };

    const handleRadioSelected = (spendCode) => {
        let saveButton = true;

        if (hsaPlan.hsaDetails.expenseSelectionInd === spendCode) {
            // If the spendCode is same as existing expenseSelectionInd and its
            // Prior Year or National Average hide save button
            if (spendCode !== SPEND_CODES.CUSTOM) {
                saveButton = false;
            }

            // If the spendCode is same as existing expenseSelectionInd and its custom, compare the amounts
            if (
                spendCode === SPEND_CODES.CUSTOM &&
                Number(hsaPlan.hsaDetails.customExpenseAmount) === Number(customAmount)
            ) {
                saveButton = false;
            }
        }

        setShowSaveBtn(saveButton);
        setHsaExpenseOption(spendCode);
    };

    const handleCustomIncome = (amount) => {
        setCustomAmount(amount);

        if (Number(hsaPlan.hsaDetails.customExpenseAmount) !== Number(amount)) {
            setShowSaveBtn(true);
        } else {
            setShowSaveBtn(false);
        }
    };

    const handleTextInputClick = () => {
        setHsaExpenseOption(SPEND_CODES.CUSTOM);
        if (hsaPlan.hsaDetails.expenseSelectionInd !== SPEND_CODES.CUSTOM) {
            setShowSaveBtn(true);
        }
    };

    return (
        <div className={`primary-controls--hsa ${dashboardHsaClass}`}>
            {hsaLoader ? <Loader /> : null}
            <div className="hsa-tab-benefits-container">
                <div className="hsa-tab-svg" />
                <div className="hsa-tab-benefits">
                    <div className="slide-description">
                        <div className="hsa-header">
                            <Header title={translations.title} />
                            <p className="hsa-header-description">{translations.description}</p>
                        </div>
                    </div>
                    <HsaTabBenefits benefits={benefits} />
                </div>
            </div>

            <div className="hsa-tab-additional-info">
                <p>{translations.additionalInfo}</p>
            </div>

            <div className="hsa-tab-contribution-container">
                <div className="hsa-tab-contribution-desc">
                    <p>{translations.expenseDescription}</p>
                </div>

                <div className="hsa-tab-radio-buttons--container">
                    <div className="hsa-tab-description-container">
                        <div className="hsa-tab-sub-description">
                            <p>{translations.expenseSubDescription}</p>
                        </div>
                        <div role="radiogroup" className="hsa-tab-radio-buttons">
                            <HsaExpenseOption
                                showExpenseOption={hasPreviousYearExpense}
                                hsaExpenseOption={hsaExpenseOption}
                                handleRadioSelected={handleRadioSelected}
                                message={translations.radioButtons.previousYear}
                                amount={formattedPreviousYearExpense}
                                spendCode={SPEND_CODES.PRIOR}
                            />

                            <HsaExpenseOption
                                showExpenseOption={true}
                                hsaExpenseOption={hsaExpenseOption}
                                handleRadioSelected={handleRadioSelected}
                                message={translations.radioButtons.nationalAverage}
                                amount={formattedNationalAverage}
                                spendCode={SPEND_CODES.NATIONAL_AVG}
                            />

                            <CustomHsaExpenseOption
                                message={translations.radioButtons.custom}
                                hsaExpenseOption={hsaExpenseOption}
                                handleRadioSelected={handleRadioSelected}
                                customAmount={customAmount}
                                handleCustomIncome={handleCustomIncome}
                                spendCode={SPEND_CODES.CUSTOM}
                                handleTextInputClick={handleTextInputClick}
                            />
                        </div>
                    </div>
                </div>
                <SaveButton
                    customClass={"hsa-save-right"}
                    showSaveBtn={showSaveBtn}
                    handleSave={handleSave}
                />
            </div>
        </div>
    );
};
HsaTabBenefits.propTypes = {
    benefits: PropTypes.array
};
export default HealthSavingsAccount;
