import React, { useState, useEffect } from "react";

import {
    handleKeyboardDownEvent,
    keyboardInteractions
} from "core-ui/client/react/core/utils/accessibilityHelpers";
import { selectTranslations } from "core-ui/client/src/app/core/translateServiceModule/TranslationsSelector";
import StringUtil from "core-ui/client/src/app/StringUtil";
import { isUndefinedOrNull } from "gw-shared-components";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import Constants from "../../constants/Constants";
import { healthcareInsuranceLists } from "../../selectors/reselectSelectors";
import HiddenA11yWrapper from "../accessibility/HiddenA11yWrapper";
import CurrencyFormatter from "../currencyFormatter/CurrencyFormatter";

import useHealthcareData from "./use-healthcare-data";

const helperUpdateTooltips = (insurancePremiumsList, outOfPocketExpensesList) => {
    if (
        insurancePremiumsList &&
        outOfPocketExpensesList &&
        insurancePremiumsList.length > 0 &&
        outOfPocketExpensesList.length > 0
    ) {
        const arr = insurancePremiumsList.concat(outOfPocketExpensesList);
        const mapping = {};

        for (let i = 0; i < arr.length; i++) {
            mapping[arr[i].label] = false;
        }
        return mapping;
    } else {
        return {};
    }
};

const HealthcareCostsDetails = ({ showHealthcareViewDetails, term }) => {
    const translations = selectTranslations("healthCareCosts");
    const translationChartLegends = translations.viewDetails.chartLegends;

    const [healthcareItems, setHealthcareItems] = useState(null);

    const activeClass = showHealthcareViewDetails ? "active" : "";

    const insurancePremiumsList = useSelector((state) =>
        healthcareInsuranceLists(state, "premiumChartConfigs")
    );
    const outOfPocketExpensesList = useSelector((state) =>
        healthcareInsuranceLists(state, "oopChartConfigs")
    );

    const { attainedAge } = useHealthcareData();

    useEffect(() => {
        if (isUndefinedOrNull(healthcareItems)) {
            // eslint-disable-next-line react-hooks-extra/no-direct-set-state-in-use-effect
            setHealthcareItems({
                ...helperUpdateTooltips(insurancePremiumsList, outOfPocketExpensesList)
            });
        }
    }, [healthcareItems, insurancePremiumsList, outOfPocketExpensesList]);

    const tooltipSetShow = (name, show) => {
        healthcareItems[name] = show;
        setHealthcareItems({ ...healthcareItems });
    };

    const tooltipGetShow = (name) => {
        if (name === undefined || isUndefinedOrNull(healthcareItems)) {
            return false;
        }
        return healthcareItems[name];
    };

    const getItemLabel = (item) => {
        // the messaging comes in from the healthViewData
        // this will allow us to dynamically update the term to be consistent woith the particiapnts preference
        if (isUndefinedOrNull(item)) {
            return "";
        }

        const findItemLabel = translationChartLegends.find(
            (toolTipItem) => toolTipItem.label === item.label || toolTipItem.color === item.color
        );

        return StringUtil.supplant(findItemLabel.toolTip, { term });
    };

    const handleShowTooltip = (event, item) => {
        event.preventDefault();
        tooltipSetShow(item, true);
        event.stopPropagation();
    };

    const handleKeydownShowTooltip = (event, item) => {
        handleKeyboardDownEvent({
            event,
            keys: keyboardInteractions.link,
            output: () => tooltipSetShow(item, true)
        });
    };

    const tableRowGenerator = (arr, titleLabel) => {
        return arr
            .sort((a, b) => a.amount < b.amount)
            .map((item, key) => {
                const toolTip = getItemLabel(item);
                return (
                    <tr key={item.label}>
                        <td className="legend-icon">
                            <span className="fa fa-square fa-solid" style={{ color: item.color }} />
                        </td>
                        <td>
                            <div className="healthViewTableItemLabel">
                                {showHealthcareViewDetails ? (
                                    <button
                                        className="healthViewTableItemLabelButtonA11y"
                                        onKeyDown={(e) => handleKeydownShowTooltip(e, item.label)}
                                        onClick={(e) => handleShowTooltip(e, item.label)}
                                        onBlur={() => tooltipSetShow(item.label, false)}
                                        aria-describedby={`${titleLabel} item-amount-${item.amount}`}
                                    >
                                        {attainedAge >= Constants.DEFAULT_RETIREMENT_AGE
                                            ? item.label
                                            : item.label.split(/\(|\)/g)[0]}
                                    </button>
                                ) : (
                                    <span data-testid={`healthview-${key}`}>
                                        {attainedAge >= Constants.DEFAULT_RETIREMENT_AGE
                                            ? item.label
                                            : item.label.split(/\(|\)/g)[0]}
                                    </span>
                                )}

                                <HiddenA11yWrapper id={`item-amount-${item.amount}`}>
                                    <span data-testid={`amount-${key}`}>${item.amount}</span>
                                </HiddenA11yWrapper>
                                <div
                                    className={
                                        "popoverMessageContainer downMessage" +
                                        (tooltipGetShow(item.label) ? " messageHover" : "")
                                    }
                                    style={{ left: -41.5, right: 41.5 }}
                                    role="alert"
                                >
                                    <div className="popoverMessageArrow arrowUp" />
                                    <div className="popoverMessage" data-testid={`tooltip-${key}`}>
                                        {toolTip}
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td className="number">
                            <CurrencyFormatter amount={item.amount} precision={0} />
                        </td>
                    </tr>
                );
            });
    };

    return (
        <div
            className="view-details shadowify healthcare-details"
            data-testid="healthcare-costs-details"
        >
            <div
                className={`view-details-container view-details-container--healthcare clearfix ${activeClass}`}
            >
                <div className="projected-health-care-costs-table-items col-sm-6">
                    <table className="simple" role="presentation">
                        <thead>
                            <tr>
                                <th colSpan="3" scope="colgroup">
                                    <b
                                        id="insurance-premiums-title-id"
                                        data-testid="insurance-pre-title"
                                    >
                                        {translations.insurancePremiums.title}
                                    </b>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {tableRowGenerator(
                                insurancePremiumsList,
                                "insurance-premiums-title-id"
                            )}
                        </tbody>
                    </table>
                </div>

                <div className="projected-health-care-costs-table-items col-sm-6">
                    <table className="simple" role="presentation">
                        <thead>
                            <tr>
                                <th colSpan="3" scope="colgroup">
                                    <b
                                        id="additional-out-of-pockets-title-id"
                                        data-testid="additional-cost-title"
                                    >
                                        {translations.AdditionalCosts.title}
                                    </b>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {tableRowGenerator(
                                outOfPocketExpensesList,
                                "additional-out-of-pockets-title-id"
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};
HealthcareCostsDetails.propTypes = {
    showHealthcareViewDetails: PropTypes.bool,
    term: PropTypes.any
};

export default HealthcareCostsDetails;
