import React, { useState, useEffect, useCallback } from "react";

import { selectTranslations } from "core-ui/client/src/app/core/translateServiceModule/TranslationsSelector";
import { Loader } from "gw-shared-components";
import PropTypes from "prop-types";
import { Modal, Button } from "react-bootstrap";
import { useSelector } from "react-redux";

import Deferrals from "../../../business/deferralsLogic/deferrals";
import {
    get as getdeferral,
    getGlobalSearchData,
    save,
    getdeferralforSave
} from "../../../services/contributionDeferralServices";

const EditContributionModal = (props) => {
    const translations = selectTranslations("editContributionModal");
    const appTranslations = selectTranslations("app");

    const primaryPlan = useSelector((state) => state.primaryPlan);
    const activeDeferral = useSelector((state) => state.activeDeferral);
    const deferralList = useSelector((state) => state.participant.deferrals);
    const deferralSetupConfig = useSelector((state) => state.deferralSetupConfig);
    const individualId = useSelector((state) => state.participant.individualId);

    const [confirmation, setconfirmation] = useState(false);
    const [showError, setshowError] = useState(false);
    const [errorData, seterrorData] = useState([]);

    const [showLoader, setshowLoader] = useState(true);
    const [modalTitle, setmodalTitle] = useState("Contribution Details");
    const [globalData, setglobalData] = useState([]);
    const [getDeferralsData, setgetDeferralsData] = useState([]);
    const [saveDeferralsData, setsaveDeferralsData] = useState([]);
    const [gropuId, setgropuId] = useState([]);
    const [amountType, setamountType] = useState([]);
    const [recommendedValue, setrecommendedValue] = useState([]);
    const [showRecommendedValue, setshowRecommendedValue] = useState([]);
    const [deferralTypeCode, setdeferralTypeCode] = useState([]);
    const deferralClass = new Deferrals(
        deferralList,
        primaryPlan,
        deferralSetupConfig,
        activeDeferral
    );

    const getGdata = useCallback((plan) => {
        return getGlobalSearchData(plan)
            .then((response) => {
                // eslint-disable-next-line react-hooks-extra/no-direct-set-state-in-use-effect
                setglobalData(response.data);
                getGroupId(response.data);
            })
            .catch((err) => {
                // eslint-disable-next-line react-hooks-extra/no-direct-set-state-in-use-effect
                setshowError(true);
                // eslint-disable-next-line react-hooks-extra/no-direct-set-state-in-use-effect
                seterrorData(err);
            });
    }, []);

    useEffect(() => {
        if (globalData == "") {
            getGdata(props.plan);
        }
    }, [getGdata, globalData, props.plan]);

    const getGroupId = function (data) {
        const partAccount = data.data.gaId;
        setgropuId(partAccount);
    };

    const showRecommendedvalueDetails = useCallback(
        (value, currentAmountType, deferralTypeCode) => {
            const detailsValue = value.map((element, index) => (
                <div key={element}>
                    <span>
                        {currentAmountType != "%" &&
                        currentAmountType != "PCT" &&
                        currentAmountType != "AMT_PCT"
                            ? "$"
                            : ""}
                        {element}
                        {(currentAmountType && currentAmountType == "%") ||
                        currentAmountType == "PCT" ||
                        currentAmountType == "AMT_PCT"
                            ? "% "
                            : ""}
                    </span>
                    <span>
                        {deferralTypeCode[index] == "BT" ? translations?.preTax : ""}
                        {deferralTypeCode[index] == "RT" ? translations?.roth : ""}
                        {deferralTypeCode[index] == "AT" ? translations?.afterTax : ""}
                    </span>
                </div>
            ));
            return detailsValue;
        },
        [translations?.afterTax, translations?.preTax, translations?.roth]
    );

    const savingsRecommendationCalc = useCallback(
        (data) => {
            const salary = props.plan.salaryOnPcap;
            let savingsRecommendation = props.plan.savingsRecommendation;
            let currentAmountType;
            const recommendedValuearr = [];
            let deferralTypeCode = [];
            let currentCode = [];

            const payrollFreq = parseInt(data.getDeferrals.payrollDetail.payrollFreq);
            //gettingg the value for panner type
            data.getDeferrals.deferrals.forEach((element) => {
                if (element.code == "STANDARD") {
                    element.deferralsList.forEach((elem) => {
                        if (elem.code == "BEFORE") {
                            currentCode.push("BT");
                        } else if (elem.code == "ROTH") {
                            currentCode.push("RT");
                        } else if (elem.code == "AFTER") {
                            currentCode.push("AT");
                        }
                    });
                }
            });
            //getting amount type
            data.getDeferrals.deferrals.forEach((element) => {
                if (element.code == "STANDARD") {
                    if (
                        element.selectedAllocationType != null &&
                        element.selectedAllocationType != undefined
                    ) {
                        setamountType(element.selectedAllocationType);
                        currentAmountType = element.selectedAllocationType;
                    } else {
                        if (
                            element.deferralsList[0].granularityAmountType != null &&
                            element.deferralsList[0].granularityAmountType != undefined
                        )
                            setamountType(element.deferralsList[0].granularityAmountType);
                        currentAmountType = element.deferralsList[0].granularityAmountType;
                    }
                }
            });

            //sorting saving recommendation based on moneyType
            savingsRecommendation = savingsRecommendation.sort(function (a, b) {
                if (a.moneyType < b.moneyType) {
                    return -1;
                }
                if (a.moneyType > b.moneyType) {
                    return 1;
                }
                return 0;
            });
            currentCode = currentCode.sort();

            if (currentCode.length > savingsRecommendation.length) {
                currentCode.forEach((element) => {
                    savingsRecommendation.forEach((elem) => {
                        if (elem.moneyType == element) {
                            if (
                                currentAmountType == "AMT" ||
                                (currentAmountType != "%" && currentAmountType != "PCT")
                            ) {
                                recommendedValuearr.push(
                                    Math.round(elem.annualValue / payrollFreq)
                                );
                            } else {
                                recommendedValuearr.push(
                                    Math.round((elem.annualValue / salary) * 100)
                                );
                            }
                            deferralTypeCode.push(elem.moneyType);
                        } else {
                            recommendedValuearr.push(0);
                            deferralTypeCode.push(element);
                        }
                    });
                });
            } else if (currentCode.length == savingsRecommendation.length) {
                currentCode.forEach((element) => {
                    savingsRecommendation.forEach((elem) => {
                        if (elem.moneyType == element) {
                            if (
                                currentAmountType == "AMT" ||
                                (currentAmountType != "%" && currentAmountType != "PCT")
                            ) {
                                recommendedValuearr.push(
                                    Math.round(elem.annualValue / payrollFreq)
                                );
                            } else {
                                recommendedValuearr.push(
                                    Math.round((elem.annualValue / salary) * 100)
                                );
                            }
                            deferralTypeCode.push(elem.moneyType);
                        }
                    });
                });
            } else {
                savingsRecommendation.forEach((element) => {
                    currentCode.forEach((ele) => {
                        if (ele == element.moneyType) {
                            if (
                                currentAmountType == "AMT" ||
                                (currentAmountType != "%" && currentAmountType != "PCT")
                            ) {
                                recommendedValuearr.push(
                                    Math.round(element.annualValue / payrollFreq)
                                );
                            } else {
                                recommendedValuearr.push(
                                    Math.round((element.annualValue / salary) * 100)
                                );
                            }
                            deferralTypeCode.push(element.moneyType);
                        }
                    });
                });
            }

            deferralTypeCode = deferralTypeCode.sort();

            setdeferralTypeCode(deferralTypeCode);
            setrecommendedValue(recommendedValuearr);
            setshowRecommendedValue(
                showRecommendedvalueDetails(
                    recommendedValuearr,
                    currentAmountType,
                    deferralTypeCode
                )
            );
        },
        [props.plan.salaryOnPcap, props.plan.savingsRecommendation, showRecommendedvalueDetails]
    );

    const getDefrrals = useCallback(
        (palnId) => {
            return getdeferral(palnId).then((response) => {
                // eslint-disable-next-line react-hooks-extra/no-direct-set-state-in-use-effect
                setgetDeferralsData(response.data);
                savingsRecommendationCalc(response.data);
                // eslint-disable-next-line react-hooks-extra/no-direct-set-state-in-use-effect
                setshowLoader(false);
            });
        },
        [savingsRecommendationCalc]
    );

    useEffect(() => {
        if (gropuId != "") {
            getDefrrals(gropuId);
        }
    }, [getDefrrals, gropuId]);

    function saveDeferrals(planId) {
        setshowLoader(true);

        getdeferralforSave()
            .then((response) => {
                const deferrals = response.data.data.deferrals;
                deferrals[0].value = recommendedValue;

                const deferralTypeCodeDerive = deferralTypeCode.map((element) => {
                    if (element === "BT") return "BEFORE";
                    if (element === "RT") return "ROTH";
                    if (element === "AT") return "AFTER";
                    return element;
                });

                deferrals[0].deferralTypeCode = deferralTypeCodeDerive;
                deferrals[0].pctAmtCode =
                    amountType === "%" || amountType === "PCT" || amountType === "AMT_PCT"
                        ? "PCT"
                        : "AMT";

                return save(deferrals, planId);
            })
            .then((response) => {
                setsaveDeferralsData(response);
                setshowLoader(false);
                setconfirmation(true);
                setmodalTitle("Confirmation");
            })
            .catch((err) => {
                setshowLoader(false);
                setshowError(true);
                seterrorData(err);
            });
    }
    const goToMyContributions = () => {
        location.href = deferralClass.getMyContributionUrl(individualId, primaryPlan);
    };

    const goToSavingsPlanner = () => {
        location.href = "/participant/home/#/savings-planner";
    };

    return (
        <div data-testid="edit-contribution-modal">
            <React.Fragment>
                {!showError ? (
                    <Modal
                        dialogClassName="edit-contributions-container"
                        show={props.show}
                        onHide={props.onCloseClick}
                    >
                        <div className="edit-contributions-dialog">
                            <div className="edit-contributions-container">
                                {!showLoader ? (
                                    <React.Fragment>
                                        <Modal.Header>
                                            <button
                                                type="button"
                                                className="close"
                                                onClick={goToSavingsPlanner}
                                                aria-label="close"
                                            >
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                            <Modal.Title>{modalTitle}</Modal.Title>
                                            {!confirmation ? (
                                                <div className="modal-description">
                                                    <span className="review-msg-part review-contribution-msg-part">
                                                        {translations?.contributionDetails}
                                                        {/* You've requested to change your contribution
                                                    rate. By clicking "Confirm and continue" you
                                                    authorize the payroll deducations as indicated
                                                    below. */}
                                                    </span>
                                                </div>
                                            ) : (
                                                <div className="with-success-check">
                                                    <i className="em-checkbox-icon"></i>

                                                    <span className="confirm-msg-part confirm-retirement-msg-part">
                                                        {translations?.confirmationText}
                                                        {/* Your changes will be reported to your plan
                                                    sponsor and processed as soon as
                                                    administratively feasible. */}
                                                    </span>
                                                </div>
                                            )}
                                        </Modal.Header>
                                        <Modal.Body className="edit-summary-body">
                                            {!confirmation ? (
                                                <React.Fragment>
                                                    <div className="edit-summary-content">
                                                        <div className="edit-summary-row">
                                                            <div className="edit-summary-label">
                                                                <b>{translations?.plan} :</b>
                                                            </div>
                                                            <div className="edit-summary-item">
                                                                {getDeferralsData &&
                                                                    getDeferralsData.getDeferrals &&
                                                                    getDeferralsData.getDeferrals
                                                                        .planName}
                                                            </div>
                                                        </div>
                                                        <div className="edit-summary-row">
                                                            <div className="edit-summary-label">
                                                                <b>
                                                                    {translations?.contributionCaps}{" "}
                                                                    :
                                                                </b>
                                                            </div>
                                                            <div className="edit-summary-item">
                                                                <p>
                                                                    {recommendedValue &&
                                                                    amountType != "%" &&
                                                                    amountType != "PCT" &&
                                                                    amountType != "AMT_PCT"
                                                                        ? "$"
                                                                        : ""}
                                                                    {recommendedValue.reduce(
                                                                        (
                                                                            previousValue,
                                                                            currentValue
                                                                        ) =>
                                                                            previousValue +
                                                                            currentValue
                                                                    )}
                                                                    {(amountType &&
                                                                        amountType == "%") ||
                                                                    amountType == "PCT" ||
                                                                    amountType == "AMT_PCT"
                                                                        ? "% "
                                                                        : ""}
                                                                    {translations?.total}
                                                                </p>

                                                                {showRecommendedValue}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="disclaimer">
                                                        <small className="text-muted">
                                                            <p>
                                                                {translations?.disclosureText}
                                                                {/* Please note that the money source
                                                            (pre-tax, after-tax, Roth, etc.) used in
                                                            your savings recommendation is
                                                            determined based on your current money
                                                            source elections. If you do not have any
                                                            money source elections on file the
                                                            pre-tax money source will be used. */}
                                                            </p>
                                                        </small>
                                                    </div>
                                                </React.Fragment>
                                            ) : (
                                                <React.Fragment>
                                                    <div>
                                                        <label>
                                                            <b>
                                                                {translations?.confirmationNumber} :
                                                            </b>
                                                            {saveDeferralsData?.confirmationNumber}
                                                        </label>
                                                    </div>
                                                    <table className="confirmationTable">
                                                        <thead>
                                                            <tr>
                                                                <th className="col">
                                                                    {" "}
                                                                    {translations?.contribution}
                                                                </th>
                                                                <th className="col">
                                                                    {" "}
                                                                    {translations?.type}
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {recommendedValue &&
                                                                recommendedValue.map(
                                                                    (element, index) => (
                                                                        <tr key={element}>
                                                                            <td>
                                                                                <span>
                                                                                    {amountType !=
                                                                                        "%" &&
                                                                                    amountType !=
                                                                                        "PCT" &&
                                                                                    amountType !=
                                                                                        "AMT_PCT"
                                                                                        ? "$"
                                                                                        : ""}
                                                                                    {element}
                                                                                    {(amountType &&
                                                                                        amountType ==
                                                                                            "%") ||
                                                                                    amountType ==
                                                                                        "PCT" ||
                                                                                    amountType ==
                                                                                        "AMT_PCT"
                                                                                        ? "%"
                                                                                        : ""}
                                                                                </span>
                                                                            </td>
                                                                            <td>
                                                                                <span>
                                                                                    {translations &&
                                                                                    deferralTypeCode &&
                                                                                    deferralTypeCode[
                                                                                        index
                                                                                    ] == "BT"
                                                                                        ? translations?.preTax
                                                                                        : ""}
                                                                                    {translations &&
                                                                                    deferralTypeCode &&
                                                                                    deferralTypeCode[
                                                                                        index
                                                                                    ] == "AT"
                                                                                        ? translations?.afterTax
                                                                                        : ""}
                                                                                    {translations &&
                                                                                    deferralTypeCode &&
                                                                                    deferralTypeCode[
                                                                                        index
                                                                                    ] == "RT"
                                                                                        ? translations?.roth
                                                                                        : ""}
                                                                                </span>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                )}
                                                        </tbody>
                                                    </table>
                                                </React.Fragment>
                                            )}
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <div className="footer-content">
                                                <div className="btns">
                                                    {!confirmation ? (
                                                        <React.Fragment>
                                                            <Button
                                                                variant="secondary"
                                                                onClick={goToMyContributions}
                                                            >
                                                                {translations?.adjustContribution}
                                                            </Button>
                                                            <div className="submit-btn">
                                                                <Button
                                                                    variant="primary"
                                                                    onClick={() =>
                                                                        saveDeferrals(gropuId)
                                                                    }
                                                                >
                                                                    {
                                                                        translations?.confirmAndContinue
                                                                    }
                                                                </Button>
                                                            </div>
                                                        </React.Fragment>
                                                    ) : (
                                                        <React.Fragment>
                                                            <Button
                                                                variant="secondary"
                                                                onClick={goToSavingsPlanner}
                                                            >
                                                                {translations?.close}
                                                            </Button>
                                                        </React.Fragment>
                                                    )}
                                                </div>
                                            </div>
                                        </Modal.Footer>
                                    </React.Fragment>
                                ) : (
                                    <Loader />
                                )}
                            </div>
                        </div>
                    </Modal>
                ) : (
                    <Modal show={props.show}>
                        <div
                            id="errorModal"
                            tabIndex="-1"
                            role="dialog"
                            aria-labelledby="myModalLabel"
                            aria-hidden="true"
                        >
                            <Modal.Body>
                                <div>{errorData.message}</div>
                            </Modal.Body>
                            <Modal.Footer>
                                <hr />
                                {errorData.code && (
                                    <div className="errorCode text-muted">
                                        <strong>Code</strong>: {errorData.code}
                                    </div>
                                )}
                                <div className="buttonContainer">
                                    <button
                                        type="button"
                                        className="btn btn-link"
                                        onClick={goToSavingsPlanner}
                                    >
                                        {appTranslations?.common?.labels?.dismiss}
                                    </button>
                                </div>
                            </Modal.Footer>
                        </div>
                    </Modal>
                )}
            </React.Fragment>
        </div>
    );
};
EditContributionModal.propTypes = {
    plan: PropTypes.object,
    show: PropTypes.bool,
    onCloseClick: PropTypes.func
};
export default EditContributionModal;
