import { useQuery } from "@tanstack/react-query";

import { QUERY_KEYS } from "../../queries/constants";
import { getMADetails } from "../../services";
import type { IPrimaryPlan, MaDetailsData } from "../../types/common";

const useMaDetails = (primaryPlan?: IPrimaryPlan) => {
    const { gaId, indId } = primaryPlan || {};

    return useQuery<MaDetailsData>({
        queryKey: [QUERY_KEYS.GET_MTR_DETAILS, gaId, indId],
        queryFn: async () => {
            const response = await getMADetails({ gaId, indId });
            return response.data;
        },
        enabled: !!gaId && !!indId
    });
};

export default useMaDetails;
