import { useQuery } from "@tanstack/react-query";

import { QUERY_KEYS } from "../../queries/constants";
import { getPrimaryPlan } from "../../services";
import type { IPrimaryPlan } from "../../types/common";

const usePrimaryPlan = () => {
    return useQuery<IPrimaryPlan>({
        queryKey: [QUERY_KEYS.GET_PRIMARY_PLAN],
        queryFn: getPrimaryPlan
    });
};

export default usePrimaryPlan;
