import { selectTranslations } from "core-ui/client/src/app/core/translateServiceModule/TranslationsSelector";

import {
    RETIREMENT_INCOME_ROUTE,
    HEALTHCARE_COSTS_ROUTE,
    HOW_DO_I_COMPARE_ROUTE,
    MAXIMIZER_ROUTE,
    PRE_RETIREMENT_ROUTE,
    WHEN_CAN_I_RETIRE_ROUTE
} from "../../../../routes";
import TABS from "../../../constants/Tabs";
import {
    canShowHealthCareView,
    canShowHDICView,
    canShowMaximizer,
    canShowPreRetirementView,
    canShowWhenCanIRetire
} from "../../../selectors/featureFlagSelectors";

const getDesktopTabsRoutes = ({ primaryPlan, participant, howDoICompare, healthCare, isPAE }) => {
    const translations = selectTranslations("retirementIncome");
    const errorTranslations = selectTranslations("errors");

    const tabsList = [
        {
            route: RETIREMENT_INCOME_ROUTE,
            id: "income-parts-subtab-0",
            tabName: TABS.RETIREMENT_INCOME,
            translationKey: translations.mainTabs.retirementIncome,
            ariaControls: "projected-income-tabpanel",
            shouldShow: true,
            disableLink: false
        },
        {
            route: isPAE ? "#" : HEALTHCARE_COSTS_ROUTE,
            id: "income-parts-subtab-1",
            tabName: TABS.HEALTHCARE_COSTS,
            translationKey: translations.mainTabs.healthCareCosts,
            ariaControls: "integrated-healthcare-container",
            shouldShow: canShowHealthCareView(participant, primaryPlan),
            disableLink: healthCare.errorOccurred,
            tooltip: errorTranslations.featureCurrentlyUnavailable
        },
        {
            route: HOW_DO_I_COMPARE_ROUTE,
            id: "income-parts-subtab-2",
            tabName: TABS.HOW_DO_I_COMPARE,
            translationKey: translations.mainTabs.howDoICompare,
            ariaControls: "integrated-hdic",
            shouldShow: canShowHDICView(primaryPlan),
            disableLink: howDoICompare.errorOccurred,
            tooltip: errorTranslations.defaultErrorMessage
        },
        {
            route: PRE_RETIREMENT_ROUTE,
            id: "income-parts-subtab-3",
            tabName: TABS.PRE_RETIREMENT,
            translationKey: translations.mainTabs.preRetirement,
            ariaControls: "integrated-pre-retiree-view",
            shouldShow: canShowPreRetirementView(participant, primaryPlan),
            disableLink: isPAE,
            tooltip: "Feature not available in emulator"
        },
        {
            route: isPAE ? "#" : WHEN_CAN_I_RETIRE_ROUTE,
            id: "income-parts-subtab-4",
            tabName: TABS.WHEN_CAN_I_RETIRE,
            translationKey: translations.mainTabs.whenCanIRetire,
            ariaControls: "when-can-i-retire",
            shouldShow: canShowWhenCanIRetire(participant, primaryPlan)
        },
        {
            route: MAXIMIZER_ROUTE,
            id: "income-parts-subtab-5",
            tabName: TABS.MAXIMIZER,
            translationKey: translations.mainTabs.maximizer,
            ariaControls: "tab-content",
            shouldShow: canShowMaximizer(primaryPlan)
        }
    ];

    const sortTabsList = (tabsList, condition) => {
        if (condition) {
            // Move "WHEN_CAN_I_RETIRE" to the first position
            const whenCanIRetireTab = tabsList.find(
                (tab) => tab.tabName === TABS.WHEN_CAN_I_RETIRE
            );
            const otherTabs = tabsList.filter((tab) => tab.tabName !== TABS.WHEN_CAN_I_RETIRE);
            return [whenCanIRetireTab, ...otherTabs];
        }
        return tabsList;
    };

    const shouldShowWcirDefault = true; // Replace with your actual condition
    const sortedTabsList = sortTabsList(tabsList, shouldShowWcirDefault);

    return sortedTabsList.filter((tab) => tab.shouldShow);
};

export default getDesktopTabsRoutes;
