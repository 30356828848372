import React, { useState, useEffect, memo } from "react";

import { AMPLITUDE_EVENTS } from "core-ui/client/src/app/core/amplitude";
import { selectTranslations } from "core-ui/client/src/app/core/translateServiceModule/TranslationsSelector";

import {
    setShowModal as setShowModalVisible,
    tabChange
} from "../../actions/goal/goalModalActionCreators";
import {
    setCurrentFault,
    dispatchEventBus,
    dispatchAmplitude
} from "../../actions/shared/sharedActionCreators";
import EventBusEvents from "../../events/eventBusEvents";
import { useSelector, useDispatch } from "../../hooks/use-redux-actions";
import { useIsEmulator } from "../../hooks/useEmulatorType";
import { reselectTermPeriod } from "../../selectors/reselectSelectors";
import { getPAEActionFault } from "../../utils/paeUtils";

import getSpouseInitData from "./helpers/getSpouseInitData";

import { PersonView, SocialSecurityModal, SocialSecurityMessaging } from "./index";

const formatAmount = (amountDisplayed: string) => {
    if (!amountDisplayed) return 0;

    const cleanDecimals = Number(amountDisplayed);

    return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0
    }).format(cleanDecimals);
};

interface SocialSecurityTranslations {
    AgeText: string;
    MonthlyBenifit: string;
}

const personMessage = (
    maSocialSecurityTranslations: SocialSecurityTranslations,
    startReceiveAge: number
) => {
    if (!maSocialSecurityTranslations) return "";

    return `${maSocialSecurityTranslations.AgeText} ${startReceiveAge}, ${maSocialSecurityTranslations.MonthlyBenifit}`;
};

const IntegratedSocialSecurityView = () => {
    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState(false);
    const translations = selectTranslations("socialSecurity");
    const maSocialSecurityTranslations =
        selectTranslations("maSocialSecurity")?.SocialSecurity?.liat;

    const participant = useSelector((state) => state.participant);
    const isPAE = useIsEmulator();
    const spouse = useSelector((state) => state.spouse);
    const term = useSelector(reselectTermPeriod);

    const { socialSecuritySpouse, socialSecurityUser } = useSelector(
        (state) => state.socialSecurity
    );

    const personAgeDiff = personMessage(
        maSocialSecurityTranslations,
        socialSecurityUser.startReceiveAge
    );

    const [amountDisplayed, setAmtDisplay] = useState(socialSecurityUser.amountDisplayed);

    const {
        spouseName,
        amountDisplayed: spouseDisplayAmount,
        startReceiveAge: spouseStartAge,
        spouseButton
    } = getSpouseInitData(socialSecuritySpouse, spouse);

    const [spouseAmountDisplayed, setSpouseAmtDisplayed] = useState(spouseDisplayAmount);
    const [spouseStartRecieveAge, setSpouseStartRecieveAge] = useState(spouseStartAge);

    const spouseMessaging = spouse.exists
        ? personMessage(maSocialSecurityTranslations, spouseStartRecieveAge)
        : "";

    const spouseAction = () => {
        // Set goal modal tab to spouse and open it
        if (!spouse.exists) {
            if (isPAE) {
                dispatch(setCurrentFault(getPAEActionFault()));
                return;
            } else {
                dispatch(tabChange("spouse"));
                dispatch(setShowModalVisible(true));
                return;
            }
        }

        return setShowModal(true);
    };

    const handleEventBus = (isSpouse: boolean) => {
        dispatch(dispatchEventBus(EventBusEvents.SOCIAL_SECURITY.MODIFY_CLICK, { isSpouse }));
        dispatch(
            dispatchAmplitude({
                event_type: AMPLITUDE_EVENTS.SELECT_BUTTON,
                event_properties: {
                    selection: EventBusEvents.SOCIAL_SECURITY.MODIFY_CLICK,
                    isSpouse
                }
            })
        );
    };

    useEffect(() => {
        if (socialSecurityUser.amountDisplayed !== amountDisplayed) {
            // eslint-disable-next-line react-hooks-extra/no-direct-set-state-in-use-effect
            setAmtDisplay(socialSecurityUser.amountDisplayed);
        }

        if (
            socialSecuritySpouse &&
            spouse.exists &&
            socialSecuritySpouse.amountDisplayed !== spouseAmountDisplayed
        ) {
            if (
                socialSecuritySpouse.amountDisplayed !== null &&
                socialSecuritySpouse.amountDisplayed !== ""
            ) {
                // eslint-disable-next-line react-hooks-extra/no-direct-set-state-in-use-effect
                setSpouseAmtDisplayed(socialSecuritySpouse.amountDisplayed);
            }
        }

        if (socialSecuritySpouse && socialSecuritySpouse.startReceiveAge) {
            // eslint-disable-next-line react-hooks-extra/no-direct-set-state-in-use-effect
            setSpouseStartRecieveAge(socialSecuritySpouse.startReceiveAge);
        }
    }, [
        socialSecurityUser.amountDisplayed,
        socialSecuritySpouse,
        spouse.exists,
        term,
        amountDisplayed,
        spouseAmountDisplayed
    ]);

    return (
        <div className="integrated-social-security-container d-flex flex-column">
            <div
                className="social-security--family-details d-flex flex-column"
                data-testid="integrated-social-security-view"
            >
                <PersonView
                    name={participant.firstName.toLowerCase()}
                    messaging={personAgeDiff}
                    amountDisplayed={formatAmount(amountDisplayed)}
                    btnClass="btn-pcap-primary"
                    setShowModal={setShowModal}
                    handleEventBus={handleEventBus}
                />
                <PersonView
                    name={spouseName}
                    messaging={spouseMessaging}
                    amountDisplayed={
                        socialSecuritySpouse && spouse.exists
                            ? formatAmount(spouseAmountDisplayed)
                            : ""
                    }
                    btnClass={spouseButton.class}
                    btnTxt={spouseButton.text}
                    spouseIcon="spouse-icon"
                    setShowModal={spouseAction}
                    isSpouse
                    handleEventBus={handleEventBus}
                />
            </div>
            <div className="social-security--messaging">
                <SocialSecurityMessaging translations={translations} />
            </div>
            <SocialSecurityModal
                show={showModal}
                setShowModal={setShowModal}
                participantRecieveAge={socialSecurityUser.startReceiveAge}
                spouseRecieveAge={
                    socialSecuritySpouse ? socialSecuritySpouse.startReceiveAge : null
                }
            />
        </div>
    );
};

export default memo(IntegratedSocialSecurityView);
