import React, { useEffect, useRef } from "react";

import { selectTranslations } from "core-ui/client/src/app/core/translateServiceModule/TranslationsSelector";

import { useSelector } from "../../../../hooks/use-redux-actions";

const PremierIRA = () => {
    const [canShowHeader, setCanShowHeader] = React.useState(false);
    const insightsWidgetRef = useRef<HTMLDivElement>(null);

    const insightsWidgetLoaded = useSelector(
        (state) => state.shared.loadStatus.insightsWidgetLoaded
    );
    const loadClass = /*!insightsHasPriority ||*/ !insightsWidgetLoaded ? "" : "loaded";

    const translations = selectTranslations("retirementIncome");

    useEffect(() => {
        const element = insightsWidgetRef.current;
        if (!element) {
            return;
        }

        const observer = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                if (mutation.type === "attributes" && mutation.attributeName === "class") {
                    const hasHiddenClass = element.classList.contains("is-hidden");
                    const hasDashboardClass = element.classList.contains(
                        "dashboard__item--emp-insights-widget"
                    );

                    setCanShowHeader(!hasHiddenClass && hasDashboardClass);
                }
            });
        });

        observer.observe(element, {
            attributes: true,
            attributeFilter: ["class"]
        });

        return () => {
            observer.disconnect();
        };
    }, []);

    return (
        <>
            {insightsWidgetLoaded && canShowHeader && (
                <span className="header" style={{ display: "flex" }} data-testid="insights-header">
                    {translations.insights}
                </span>
            )}
            <div
                id="insights-widget"
                data-testid="insights-widget"
                className={`use-fade-in rounded-border bg-white insights-widget--container ${loadClass}`}
                ref={insightsWidgetRef}
            />
        </>
    );
};

export default PremierIRA;
