/**
 *
 * @name ReferenceData
 * @memberOf common.model.constants
 * @date 02/09/2015
 * @copyright 2015 Empower Retirement
 * @description Encapsulates constants for our code.
 * @property {string} MIN_CONTRIBUTION_RATE 1
 * @property {string} MIN_CONTRIBUTION_RATE_DOLLAR 25
 * @property {string} MAX_SLIDER_CONTRIBUTION_RATE 25
 * @property {string} MAX_CONTRIBUTION_RATE 50
 * @property {string} DEFERRAL_SLIDER_TERM 12
 * @property {string} DOLLAR_DEFERRAL_DECIMALS 2
 * @property {string} PERCENT_DEFERRAL_DECIMALS 1
 * @property {string} MAX_DOLLAR_ENTRY 99999.99
 * @property {string} DEFAULT_MAX_ENTRY 999.99
 * @property {string} MAX_INVESTMENT_MIX 100.0
 * @property {string} MAX_SALARY_ENTRY 9999999.99
 * @property {string} MIN_RETIREMENT_AGE 50
 * @property {string} MAX_RETIREMENT_AGE 85
 * @property {string} DEFAULT_CONTRIBUTION_RATE 0
 * @property {string} DEFAULT_RETIREMENT_AGE 65
 * @property {string} DEFAULT_INVESTMENT_MIX 50
 * @property {string} DEFAULT_HEALTHCARE_COSTS_AGE 65
 * @property {string} DEFAULT_BEST_NEXT_STEP 6
 * @property {string} DEFAULT_DISPLAY_SOCIAL_SECURITY true
 * @property {string} DEFAULT_IS_ASSET_ALLOCATION_ALLOWED true
 * @property {string} DEFAULT_IS_ENROLLED false
 * @property {string} DEFAULT_IS_TERMINATED false
 * @property {string} DEFAULT_DEFERRAL_CODE "full"
 * @property {string} DEFAULT_USER_SHOW_SOCIAL_SECURITY true
 * @property {string} DEFAULT_GOAL_PERCENT_INCOME 75
 * @property {string} DEFAULT_GOAL_SUCCESS_PROBABILITY 90
 * @property {string} DEFAULT_GOAL_TERM 12 // monthly
 * @property {string} URL_ACCOUNTS_OVERVIEW "/participant/accounts/#/overview"
 * @property {string} URL_ACCOUNTS_DO_IT_FOR_ME "/participant/accounts/#/guidance/allocations/professional-management"
 * @property {string} URL_ADVICE "/participant/accounts/#/retirement-income/allocations/selection"
 * @property {string} URL_MY_CONTRIBUTIONS "/participant/accounts/#/account/{individualId}/{groupId}/my-contributions"
 * @property {string} URL_INVESTMENT_CHANGE "/participant/accounts/#/retirement-income/allocations/selection/{targetMix}"
 * @property {string} URL_MAX_MATCH "/participant/accounts/#/account/{individualId}/{groupId}/contributions#max-match"
 * @property {string} URL_MAX_LIMIT "/participant/accounts/#/account/{individualId}/{groupId}/contributions#max-limit"
 * @property {string} USER_TYPE_PARTICIPANT "PARTICIPANT"
 *
 */
"use strict";

const ReferenceData = {
    MIN_CONTRIBUTION_RATE: 1,
    MIN_CONTRIBUTION_RATE_DOLLAR: 25,
    MAX_SLIDER_CONTRIBUTION_RATE: 25,
    MAX_CONTRIBUTION_RATE: 100,
    DEFERRAL_SLIDER_TERM: 12,
    DOLLAR_DEFERRAL_INCREMENT: 25,
    DOLLAR_DEFERRAL_ROUND: 5,
    DOLLAR_DEFERRAL_MAX_STEP: 31,
    DOLLAR_DEFERRAL_DECIMALS: 2,
    PERCENT_DEFERRAL_DECIMALS: 1,
    MAX_DOLLAR_ENTRY: 99999.99,
    DEFAULT_MAX_ENTRY: 999.99,
    MAX_INVESTMENT_MIX: 100.0,

    MAX_SALARY_ENTRY: 9999999.99,

    MIN_HEALTHCARE_VIEW_AGE: 35,

    MIN_RETIREMENT_AGE: 50,
    MAX_RETIREMENT_AGE: 85,

    MIN_PLAN_TO_AGE: 55,
    MAX_PLAN_TO_AGE: 109,

    MIN_SOCIALSECURITY_STARTAGE: 62,
    MAX_SOCIALSECURITY_LATEST_STARTAGE: 70,
    SOCIAL_SECURITY_CALCULATE_AGE_METHOD: 1970,

    DEFAULT_CONTRIBUTION_RATE: 0,
    DEFAULT_RETIREMENT_AGE: 65,
    DEFAULT_INVESTMENT_MIX: 50,
    DEFAULT_HEALTHCARE_COSTS_AGE: 65,

    DEFAULT_BEST_NEXT_STEP: 6,

    DEFAULT_DISPLAY_SOCIAL_SECURITY: true,
    DEFAULT_IS_ASSET_ALLOCATION_ALLOWED: true,
    DEFAULT_IS_ENROLLED: false,
    DEFAULT_IS_TERMINATED: false,
    DEFAULT_DEFERRAL_CODE: "full",
    DEFAULT_USER_SHOW_SOCIAL_SECURITY: true,

    DEFAULT_GOAL_PERCENT_INCOME: 75,
    DEFAULT_GOAL_SUCCESS_PROBABILITY: 90,

    DEFAULT_GOAL_TERM: 12, // monthly,
    PROGRAM_NAME_KEY: "{programName}",
    PROVIDER_MTR: "MTR",
    PROVIDER_MTR_TAS: "MTR_TAS",
    PROVIDER_FE: "FINANCIAL_ENGINES",
    PROVIDER_IBBOTSON: "IBBOTSON",
    PROVIDER_AMA: "AMA",

    TAXABLE_ACCOUNT: "140039-01",

    URL_ACCOUNTS_OVERVIEW: "/participant/accounts/#/overview",
    URL_ACCOUNTS_DO_IT_FOR_ME:
        "/participant/accounts/#/guidance/allocations/professional-management",
    URL_ADVICE: "/participant/accounts/#/retirement-income/allocations/selection",
    URL_MY_CONTRIBUTIONS:
        "/participant/accounts/#/account/{individualId}/{groupId}/my-contributions",
    URL_RATE_OF_RETURN: "/participant/accounts/#/account/{individualId}/{groupId}/rate-of-return",
    URL_BENEFICIARY: "/participant/accounts/#/account/{individualId}/{groupId}/beneficiary",
    URL_TRANSACTION_HISTORY:
        "/participant/accounts/#/account/{individualId}/{groupId}/transaction-history",
    URL_INVESTMENT_CHANGE:
        "/participant/accounts/#/retirement-income/allocations/selection/{targetMix}",
    URL_NON_ALL_MT_GROUPINGS:
        "/participant/accounts/#/account/{individualId}/{groupId}/investment-options/investment-overview",
    URL_MAX_MATCH:
        "/participant/accounts/#/account/{individualId}/{groupId}/contributions#max-match",
    URL_MAX_LIMIT:
        "/participant/accounts/#/account/{individualId}/{groupId}/contributions#max-limit",
    URL_FE_ENROLL:
        "/participant/accounts/#/account/{individualId}/{groupId}/investment-options/allocations/ma-express-enroll",
    URL_MSTAR_ENROLL:
        "/participant/accounts/#/account/{individualId}/{groupId}/investment-options/allocations/ma-express-enroll",
    URL_NQ_ENROLLMENT: "/participant/accounts/#/overview",
    URL_NQ_ENROLLMENT_PLAN: "/participant/accounts/#/enrollment-summary/{planId}",
    URL_ADVICE_PAGE_MTR: "/participant/home/#/advice",
    URL_MARKETING_PAGE: "/participant/accounts/#/guidance/allocations/marking-page",
    URL_MARKETING: "/participant/accounts/#/guidance/allocations/marketing",
    URL_ENROLLMENT_OPT_OUT: "/participant/accounts/#/qualifiedEnrollment/my-contributions/{planId}",
    URL_DEMIN:
        "/participant/accounts/#/account/{individualId}/{deminGroupId}/distribution/demin-landing",
    URL_HOME_ROOT: "projected-income",
    URL_INTEGRATED_HOME_ROOT: "dashboard",
    USER_TYPE_PARTICIPANT: "PARTICIPANT",
    USER_TYPE_PRIVILEGED: "PRIVILEGED",
    USER_TYPE_PAE: "PAE",
    URL_HYPERLINK_MY_CONTRIBUTIONS:
        "/participant/accounts/#/account/:individualId/:groupId/my-contributions",
    URL_HYPERLINK_RATE_OF_RETURN:
        "/participant/accounts/#/account/:individualId/:groupId/rate-of-return",
    URL_HYPERLINK_BENEFICIARY: "/participant/accounts/#/account/:individualId/:groupId/beneficiary",
    URL_HYPERLINK_TRANSACTION_HISTORY:
        "/participant/accounts/#/account/:individualId/:groupId/transaction-history",
    URL_IN_PLAN_ROTH_CONVERSION:
        "/participant/accounts/#/account/{individualId}/{groupId}/ngfeat/balance/in-plan-roth-conversion"
};

export default ReferenceData;
