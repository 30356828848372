import type { AxiosResponse } from "axios";
import StringUtil from "core-ui/client/src/app/StringUtil";

import type { IAccountCustomization } from "../types/common";
import { executeGet } from "../utils/apiUtils";

type Args = {
    accu: string;
};

const getAccountCustomization = async ({ accu }: Args) => {
    const url = StringUtil.supplant(__styleEnv__ + "customizations/{accu}/{accu}{suffixPath}", {
        accu,
        suffixPath: "AccountsCustomization.json"
    });
    const response: AxiosResponse<IAccountCustomization> = await executeGet(
        url,
        "getAccountCustomization"
    );
    return response.data;
};

export default getAccountCustomization;
