import React from "react";

import { useAccountDetails } from "core-ui/client/react/core/queries";
import { selectTranslations } from "core-ui/client/src/app/core/translateServiceModule/TranslationsSelector";
import { EnterpriseChatComponent } from "gw-shared-components";

import { useIsEmulator } from "../../hooks/useEmulatorType";
import { useAccountCustomization, useGroupTxnAccess, usePrimaryPlan } from "../../queries";

const LANGUAGE = "en_US";

const EnterpriseChatContainer = () => {
    const { data: primaryPlanData, isLoading: isPrimaryPlanLoading } = usePrimaryPlan();
    const { data: accountCustomization, isLoading: isAccountCustomizationLoading } =
        useAccountCustomization();
    const { accuCode: name, gaId: groupId, indId: individualId } = primaryPlanData ?? {};
    const defaultCustomization = {};

    const { data: txnAccess, isLoading: isTxnLoading } = useGroupTxnAccess({
        individualId,
        planId: groupId,
        txnCodes: "WNCCHB, WNACHB, WNACHT"
    });
    const { data: accountDetailsData } = useAccountDetails({
        individualId: individualId ?? "",
        groupId: groupId ?? ""
    });

    const isPAE = useIsEmulator();

    if (
        !accountDetailsData ||
        isPrimaryPlanLoading ||
        isTxnLoading ||
        isAccountCustomizationLoading ||
        !txnAccess
    ) {
        return null;
    }

    const { firstName, middleName, lastName } = accountDetailsData;
    const { WNCCHB, WNACHB, WNACHT } = txnAccess;

    const accountDetails = {
        accountDetails: {
            firstName: firstName,
            middleName: middleName ?? "",
            lastName: lastName,
            name: name ?? ""
        }
    };
    const activeGA = {
        individualId: individualId ?? "",
        groupId: groupId ?? ""
    };

    const footerTranslations = selectTranslations("footer");

    return (
        <EnterpriseChatComponent
            account={accountDetails}
            activeGroupAccount={activeGA}
            accuCustomization={accountCustomization ?? defaultCustomization}
            authenticated
            chatBotEnable={WNACHB === "true"}
            enterpriseChatEnable={WNCCHB === "true"}
            isEmulator={isPAE}
            language={LANGUAGE}
            liveChatEnable={WNACHT === "true"}
            selectedLanguage={LANGUAGE}
            translations={{ footer: footerTranslations }}
        />
    );
};

export default EnterpriseChatContainer;
