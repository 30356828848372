import React, { useEffect, useRef, useState } from "react";

import { withProfiler } from "@sentry/react";
import { useAtomValue } from "jotai";
import PropTypes from "prop-types";

import { redwoodAtom } from "../../atoms/atoms";
import { SidebarShell } from "../loadShells/LoadShells";

const SideBar = ({ showSidebar, leaveSideBar, slideClass, ariaHidden, onFocus }) => {
    const [loading, setLoading] = useState(false);
    const { pcapClass } = useAtomValue(redwoodAtom);
    const loadClass = pcapClass.sidebar ? "loaded" : "";
    // WCDX-14129 if user is on Profile page show sidebar
    const sidebarRef = useRef(null);

    useEffect(() => {
        const sidebarEl = document.querySelector("#sidebar");
        try {
            if (sidebarEl && !pcapClass.sidebar && pcapClass.mountSidebar) {
                pcapClass.mountSidebar();
            }

            return () => {
                if (sidebarEl && pcapClass.sidebar && pcapClass.unmountSidebar) {
                    pcapClass.unmountSidebar();
                }
            };
        } catch (err) {
            console.log("Error loading sidebar: ", err);
        }
    }, [pcapClass]);

    useEffect(() => {
        // eslint-disable-next-line react-hooks-extra/no-direct-set-state-in-use-effect
        setLoading(!!sidebarRef.current?.clientHeight);
    }, [sidebarRef.current?.clientHeight]);

    const handleOnSideBarBlur = (e) => {
        if (sidebarRef.current && !sidebarRef.current.contains(e.relatedTarget)) {
            leaveSideBar();
        }
    };

    const handleOnSideBarFocus = (e) => {
        if (ariaHidden && sidebarRef.current.contains(e.target)) {
            onFocus(e);
        }
    };

    return (
        <div
            className={`rounded-border bg-white sidebar-container ${showSidebar} ${slideClass} aria-hidden=${ariaHidden}`}
        >
            {!loading && <SidebarShell loaded={loadClass} />}
            <div
                id="sidebar"
                ref={sidebarRef}
                className={`use-fade-in ${loadClass}`}
                onMouseLeave={leaveSideBar}
                onBlur={handleOnSideBarBlur}
                onFocus={handleOnSideBarFocus}
            ></div>
        </div>
    );
};

SideBar.propTypes = {
    ariaHidden: PropTypes.bool,
    showSidebar: PropTypes.string,
    leaveSideBar: PropTypes.func,
    onFocus: PropTypes.func,
    slideClass: PropTypes.string
};

export default withProfiler(SideBar, { name: "SideBar" });
