import ReferenceData from "../../../../constants/ReferenceData";
import type { ISpouse } from "../../../../types/common";
import type SocialSecurity from "../../../../types/common/socialSecurity";

const ADD_SPOUSE_BUTTON = {
    text: "Add",
    class: "btn-bare--border"
};

const MODIFY_SPOUSE_BUTTON = {
    class: "btn-pcap-primary",
    text: "Modify"
};

type SpouseButton = {
    class: string;
    text: string;
};

interface SocialSecurityDetails {
    amountDisplayed: string;
    spouseButton: SpouseButton;
    spouseName: string;
    startReceiveAge: number;
}

const getSpouseInitData = (
    socialSecuritySpouse: SocialSecurity["socialSecuritySpouse"],
    spouse: Pick<ISpouse, "exists" | "firstName" | "retirementAge">
) => {
    const spouseName = spouse?.firstName.toLowerCase() || "Missing first name";

    const spouseData: SocialSecurityDetails = {
        amountDisplayed:
            socialSecuritySpouse && spouse.exists ? socialSecuritySpouse.amountDisplayed : "",
        startReceiveAge:
            socialSecuritySpouse && socialSecuritySpouse.startReceiveAge
                ? socialSecuritySpouse.startReceiveAge
                : spouse?.retirementAge || ReferenceData.MIN_SOCIALSECURITY_STARTAGE,
        spouseName: spouse.exists ? spouseName : "Spouse / Partner",
        spouseButton: spouse.exists ? MODIFY_SPOUSE_BUTTON : ADD_SPOUSE_BUTTON
    };

    return spouseData;
};

export default getSpouseInitData;
