import React, { useCallback, useEffect, useState } from "react";

import { AMPLITUDE_EVENTS, dispatchAmplitude } from "core-ui/client/src/app/core/amplitude";
import PropTypes from "prop-types";

import { NumberInput } from "../numberinput/NumberInput.jsx";

import Percent from "./Percent";

const BUTTON_ACTIVE_CLASS = "button-active";

const PortionForm = (props) => {
    const [prevShowPercent, setPrevShowPercent] = useState(true);
    const [showPercent, setShowPercent] = useState(true);
    const [pct, setPct] = useState(0);
    const [amt, setAmt] = useState(0);
    const hidePercentButton = props.hasLinkedAccounts && !props.isPercent;

    /* eslint-disable react-hooks-extra/no-direct-set-state-in-use-effect */
    const setTabs = useCallback(
        (props, init, tabsChanged) => {
            const portion = props.portion;

            if (init) {
                setPrevShowPercent(props.isPercent);
                setShowPercent(props.isPercent);
                setPct(props.isPercent ? portion : 75);
                setAmt(
                    props.isPercent ? (portion / 100) * (props.salary + props.additional) : portion
                );
            } else if (!tabsChanged) {
                if (props.isPercent) {
                    setPrevShowPercent(showPercent);
                    setPct(portion);
                    setAmt((portion / 100) * (props.salary + props.additional));
                } else {
                    setAmt(portion);
                }
            } else {
                setPrevShowPercent(showPercent);
                setShowPercent(props.isPercent);
            }
        },
        [showPercent]
    );

    useEffect(() => {
        setTabs(props, true, false);
    }, [props, setTabs]);

    useEffect(() => {
        const tabsChanged = prevShowPercent !== props.isPercent;
        setTabs(props, false, tabsChanged);
    }, [prevShowPercent, props, props.isPercent, setTabs]);

    const handleSwitchInput = (e) => {
        const findClosestPercent = (val) => {
            let upperLimit = val;
            let lowerLimit = val;

            upperLimit = Math.ceil(upperLimit / 5) * 5;
            lowerLimit = Math.floor(lowerLimit / 5) * 5;

            return Math.abs(val - upperLimit) > Math.abs(val - lowerLimit)
                ? lowerLimit
                : upperLimit;
        };

        if (!hidePercentButton) {
            /**
             * Calculate the closest percent value given the amount and salary, when switching to percent tab
             * Switching to amount will assign the already calculated amount value
             */
            const percent = props.salary
                ? Math.round((amt / (props.salary + props.additional)) * 100)
                : pct;
            let val = !props.isPercent ? percent : amt;

            if (!props.isPercent) {
                if (val < 50) {
                    val = 50;
                } else if (val > 200) {
                    val = 200;
                } else {
                    val = findClosestPercent(val);
                }
            }

            if (!e.target.classList.contains(BUTTON_ACTIVE_CLASS)) {
                if (!showPercent) {
                    props.portionValidator(
                        Number(val),
                        props.salary,
                        props.additional,
                        !showPercent,
                        props.identifier,
                        props.isSpouse
                    );
                }
                props.handlePortion(Number(val), !showPercent);
                setShowPercent(!showPercent);
            }
        }
    };

    const handlePortionBlur = (val, isPercent) => {
        props.portionValidator(
            Number(val),
            props.salary,
            props.additional,
            isPercent,
            props.identifier,
            props.isSpouse
        );

        const eventType = props.isSpouse
            ? props.mtrGoalModalEvents.UPDATE_SPOUSE_PORTION
            : props.mtrGoalModalEvents.UPDATE_PORTION;

        props.handlePortion(Number(val), isPercent);
        props.eventBus.dispatch(eventType, { portion: val, isPercent: isPercent });
        dispatchAmplitude({
            eventType: AMPLITUDE_EVENTS.CHANGE_FIELD,
            selection: eventType,
            payload: {
                portion: val,
                isPercent: isPercent
            }
        });
    };

    const handleButtonFocus = (e) => {
        const isTabPressed = e.key === "Tab" || e.keyCode === 9;

        if (isTabPressed) {
            e.target.classList.add("percent-button-focus");
        }
    };

    const handleButtonBlur = (e) => {
        e.target.classList.remove("percent-button-focus");
    };

    return (
        <div className="portion-form" data-testid="portion-form">
            <div data-testid="percent-button-container" className="percent-button-container">
                {!hidePercentButton && (
                    <button
                        id="percent"
                        type="button"
                        className={
                            showPercent ? BUTTON_ACTIVE_CLASS : "portion-form-button-non-active"
                        }
                        aria-pressed={showPercent}
                        onClick={handleSwitchInput}
                        onKeyUp={handleButtonFocus}
                        onBlur={handleButtonBlur}
                    >
                        %
                    </button>
                )}
                <button
                    id="dollar"
                    type="button"
                    className={showPercent ? "portion-form-button-non-active" : BUTTON_ACTIVE_CLASS}
                    aria-pressed={!showPercent}
                    onClick={handleSwitchInput}
                    onKeyUp={handleButtonFocus}
                    onBlur={handleButtonBlur}
                    disabled={hidePercentButton}
                >
                    $
                </button>
            </div>
            <div className="percent-input-container">
                {showPercent && !hidePercentButton ? (
                    <Percent handlePortion={handlePortionBlur} portion={props.portion} />
                ) : (
                    <NumberInput
                        additional={false}
                        handlePortionBlur={handlePortionBlur}
                        isPercent={props.isPercent}
                        type="text"
                        value={amt}
                        portion={true}
                        modal={true}
                        canSave={props.canSave}
                        identifier={props.identifier}
                        id="income-replacement"
                    />
                )}
            </div>
        </div>
    );
};

PortionForm.propTypes = {
    identifier: PropTypes.string.isRequired,
    handlePortion: PropTypes.func.isRequired,
    handleError: PropTypes.func.isRequired,
    portionValidator: PropTypes.func.isRequired,
    salary: PropTypes.number.isRequired,
    isSpouse: PropTypes.bool.isRequired,
    additional: PropTypes.number,
    portion: PropTypes.number.isRequired,
    canSave: PropTypes.bool,
    isPercent: PropTypes.bool.isRequired,
    hasLinkedAccounts: PropTypes.bool,
    mtrGoalModalEvents: PropTypes.object,
    eventBus: PropTypes.oneOfType([PropTypes.func, PropTypes.object])
};

export default PortionForm;
