import React, { useState, useEffect, memo, useCallback } from "react";

import { selectTranslations } from "core-ui/client/src/app/core/translateServiceModule/TranslationsSelector";
import { ktmgFlags } from "gw-shared-components";
import { useAtomValue } from "jotai";
import { HashRouter as Switch, Route } from "react-router-dom";

import {
    ROUTE_MANAGED_ACCOUNTS,
    ROUTE_MANAGED_ACCOUNTS_HOME,
    ROUTE_MANAGED_ACCOUNTS_INTRO,
    ROUTE_MANAGED_ACCOUNTS_STARTED,
    ROUTE_MANAGED_ACCOUNTS_ADVICE,
    RETIREMENT_INCOME_ROUTE,
    SOCIAL_SECURITY_ROUTE,
    HSA_ROUTE,
    INCOME_GAP_ROUTE,
    OTHER_ASSETS_ROUTE,
    HEALTHCARE_COSTS_ROUTE,
    HOW_DO_I_COMPARE_ROUTE,
    PRE_RETIREMENT_ROUTE,
    WHEN_CAN_I_RETIRE_ROUTE,
    MAXIMIZER_ROUTE
} from "../../../routes";
import {
    setShowLockheedModal,
    setLockheedTerms,
    showLockheedEnroll,
    showLockheedTermsOfService
} from "../../actions/enrollment/enrollmentAction";
import { setNqInfo } from "../../actions/paycheck/paycheckActions";
import { showWidgetsAtom, homePageEligibilityAtom } from "../../atoms/atoms";
import { LiatComponent, Widgets } from "../../components/dashboard";
import DeminModal from "../../components/modals/deminModal/DeminModal";
import EditContributionModal from "../../components/modals/editContributionModal/EditContributionModal";
import { LockheedModal } from "../../components/modals/lockheedModal/LockheedModal";
import { NqModal } from "../../components/modals/nqModal/NqModal";
import DashboardPaeError from "../../components/pae/dashboard/DashboardPaeError";
import QuickView from "../../components/quickView/QuickView";
import WelcomeWidget from "../../components/welcomeWidget/WelcomeWidget";
import { useSelector, useDispatch } from "../../hooks/use-redux-actions";
import useDisplayWelcomeWidget from "../../hooks/useDisplayWelcomeWidget";
import { useIsEmulator, useIsPrivileged } from "../../hooks/useEmulatorType";
import setManagedAccountsData from "../../middleware/setManagedAccountsData";
import {
    useAccountsList,
    useFunnelAttributes,
    useGroupTxnAccess,
    useMaDetails,
    usePrimaryPlan
} from "../../queries";
import { canShowQuickView } from "../../selectors/featureFlagSelectors";
import { hasCurrentFault } from "../../selectors/sharedSelectors";

import { ContributionButton, RightSideBar } from "./components";
import isWebSearchEnabled from "./helpers/isWebSearchEnabled";
import shouldShowWelcomeWidget from "./helpers/shouldShowWelcomeWidget";

const LIAT_INTEGRATED_ROUTES = [
    RETIREMENT_INCOME_ROUTE,
    SOCIAL_SECURITY_ROUTE,
    OTHER_ASSETS_ROUTE,
    INCOME_GAP_ROUTE,
    HSA_ROUTE,
    ROUTE_MANAGED_ACCOUNTS,
    ROUTE_MANAGED_ACCOUNTS_HOME,
    ROUTE_MANAGED_ACCOUNTS_INTRO,
    ROUTE_MANAGED_ACCOUNTS_STARTED,
    ROUTE_MANAGED_ACCOUNTS_ADVICE,
    MAXIMIZER_ROUTE,
    HEALTHCARE_COSTS_ROUTE,
    HOW_DO_I_COMPARE_ROUTE,
    PRE_RETIREMENT_ROUTE,
    WHEN_CAN_I_RETIRE_ROUTE
];

const WebBanner = () => {
    // PCAP will remove is-hidden class from the element when we have to show the completeness meter. (data exists and progress < 100)
    return (
        <div
            id="ace-block"
            className={`gray-border-000 rounded-border bg-white banner-container is-hidden`}
        />
    );
};

const OnboardingChecklist = () => {
    return <div id="onboarding-checklist" className="is-hidden" />;
};

interface PcapPlan {
    accountId: string;
    personId: string;
    salaryOnPcap: number;
    savingsRecommendation: number; // the primary plan's ID is the group ID
}

interface EligiblePlan {
    currentChainingOption: string;
    enrollmentEndDate: string;
    groupLockheedPlan: boolean;
    planId: string;
    planName: string;
    planNumber: string;
}

const DEFAULT_ELIGIBLE_PLANS: EligiblePlan[] = [];

interface DashboardProps {
    location: {
        hash: string;
        key: string;
        pathname: string;
        search: string;
        state: null | object;
    };
    modalOpen: boolean;
    secDeminModal:
        | null
        | {
              groupId: string;
              individualId: string;
              planName: string;
          }[];
    showLIAT: boolean;
}

const Dashboard = (props: DashboardProps) => {
    const { modalOpen, showLIAT, secDeminModal } = props;
    const [shouldRefetch, setShouldRefetch] = useState(false);
    const homePageEligibility = useAtomValue(homePageEligibilityAtom);
    const showWidgets = useAtomValue(showWidgetsAtom);

    const { data: accounts, isLoading: isLoadingAccounts, refetch } = useAccountsList();

    const { data: funnelData, isLoading: isLoadingFunnelData } = useFunnelAttributes();

    const isPrivileged = useIsPrivileged();
    const isEmulator = useIsEmulator();
    const isPAE = isEmulator && !isPrivileged;

    const dispatch = useDispatch();
    const txnAccess = useSelector((state) => state.shared.txnAccess);
    const showLoader = useSelector(
        (state) => state.shared.showLoader || !state.shared.readyToShowApplication
    );
    const { data: primaryPlan, isLoading: isPrimaryPlanLoading } = usePrimaryPlan();
    const { data: maDetails, isLoading: isMaDetailsLoading } = useMaDetails(primaryPlan);

    const { data: txnCodesResponseData, isLoading: isTxnAccessLoading } = useGroupTxnAccess({
        individualId: primaryPlan?.indId,
        planId: primaryPlan?.gaId,
        txnCodes: [
            ktmgFlags.CUSTOMER_WEB_SEARCH,
            ktmgFlags.FINANCIAL_WELLNESS_DOUBLE_NETPAY,
            ktmgFlags.WELLNESS_PAGE
        ].join(",")
    });
    const showSecondaryLoader = useSelector((state) => state.shared.showSecondaryLoader);
    const currentFaultError = useSelector(hasCurrentFault);
    const showLoaderOnLoad =
        (showLoader && !currentFaultError) ||
        isLoadingAccounts ||
        isLoadingFunnelData ||
        isTxnAccessLoading ||
        isMaDetailsLoading ||
        isPrimaryPlanLoading;

    const [show, setShow] = useState(false);
    const [planId, setplanId] = useState<PcapPlan | null>(null);
    const handleClose = () => setShow(false);

    const translations = selectTranslations("retirementIncome");

    const handleShow = useCallback(
        (e) => {
            e.stopPropagation();
            if (!show) {
                setplanId(e.detail);
                setShow(true);
            }
        },
        [show]
    );

    const handleShowLockheedModal = useCallback(
        (e: Event) => {
            e.stopPropagation();
            const result: {
                currentChainingOption: string;
                enrollmentEndDate: string;
                planName: string;
                planNumber: string;
            } = (e as CustomEvent).detail.response;

            const eligiblePlans = DEFAULT_ELIGIBLE_PLANS;

            const item = {
                planNumber: result.planNumber,
                planName: result.planName,
                planId: result.planNumber,
                currentChainingOption: result.currentChainingOption,
                enrollmentEndDate: result.enrollmentEndDate,
                groupLockheedPlan: true
            };
            eligiblePlans.push(item);

            dispatch(setLockheedTerms(result));
            dispatch(setNqInfo(eligiblePlans));
            dispatch(setShowLockheedModal(true));
            globalThis.PublicServices.isParticipantEnrollEligible(item.planId)
                .then((result) => {
                    const isEnrolledInCurrentEnrollmentDate =
                        !!result.nonQualEnrollmentEligibility
                            .participantEnrolledInCurrentEnrollmentDate;
                    dispatch(showLockheedEnroll(!isEnrolledInCurrentEnrollmentDate));
                    dispatch(showLockheedTermsOfService(isEnrolledInCurrentEnrollmentDate));
                })
                .catch(() => {
                    dispatch(showLockheedEnroll(true));
                });
        },
        [dispatch]
    );

    const updateManagedAccountData = useCallback(
        (e: Event) => {
            e.stopPropagation();
            setManagedAccountsData(dispatch);
        },
        [dispatch]
    );

    useEffect(() => {
        if (modalOpen) {
            // eslint-disable-next-line react-hooks-extra/no-direct-set-state-in-use-effect
            setShouldRefetch(true);
        } else if (shouldRefetch) {
            // Check if the link account modal has been opened and trigger a refetch of the accounts data if needed.
            refetch();
            // eslint-disable-next-line react-hooks-extra/no-direct-set-state-in-use-effect
            setShouldRefetch(false);
        }
    }, [modalOpen, refetch, shouldRefetch]);

    useEffect(() => {
        window.addEventListener("pc_editContributions", handleShow);
        window.addEventListener("pc_showLockheedModal", handleShowLockheedModal);
        window.addEventListener("pc_refresh_top_nav", updateManagedAccountData);

        return () => {
            window.removeEventListener("pc_editContributions", handleShow);
            window.removeEventListener("pc_showLockheedModal", handleShowLockheedModal);
            window.removeEventListener("pc_refresh_top_nav", updateManagedAccountData);
        };
    }, [handleShow, handleShowLockheedModal, updateManagedAccountData]);

    const showQuickView = canShowQuickView(homePageEligibility);
    const linkedAccounts = useSelector((state) => state.participant.linkedAccountBalances || []);

    const showDeminModal =
        txnAccess.allowDeminModal &&
        !isPAE &&
        secDeminModal &&
        secDeminModal.length > 0 &&
        !currentFaultError;

    const hasSeenWelcomeWidget = useDisplayWelcomeWidget(accounts?.spHeader?.userGuid);

    const showWelcomeWidget = shouldShowWelcomeWidget({
        accounts: accounts?.accounts,
        linkedAccounts,
        funnelData: funnelData?.spData,
        hasSeenWelcomeWidget
    });

    const webSearchEnabled = isWebSearchEnabled(txnCodesResponseData);

    return (
        <div className="dashboard-layout-main-content" data-testid="dashboard">
            <Switch>
                <Route
                    location={props.location}
                    path={LIAT_INTEGRATED_ROUTES}
                    render={() => {
                        return (
                            <React.Fragment>
                                <WebBanner />
                                <div className="sub-body-dashboard-layout-main-content">
                                    <div className="d-flex redwood-center-column">
                                        {showWelcomeWidget && <WelcomeWidget />}
                                        {txnAccess.onboardingChecklistEnabled && (
                                            <OnboardingChecklist />
                                        )}
                                        {showLIAT && (
                                            <div className="header">{translations.retirement} </div>
                                        )}
                                        {showQuickView && (
                                            <QuickView showRetirementHeader={!showLIAT} />
                                        )}
                                        <LiatComponent showLoader={showLoaderOnLoad} />
                                        {showWidgets && (
                                            <div className="header">{translations.finances} </div>
                                        )}
                                        {txnAccess.pcapWidgetsEnabled && (
                                            <Widgets modalOpen={modalOpen} isPAE={isPAE} />
                                        )}
                                        {isPAE && !showLIAT && <DashboardPaeError />}
                                    </div>
                                    <RightSideBar
                                        showWidgets={showWidgets}
                                        homePageEligibility={homePageEligibility}
                                        isPAE={isPAE}
                                        showLoader={showSecondaryLoader}
                                        webSearchEnabled={webSearchEnabled}
                                        maDetails={maDetails}
                                    />
                                </div>
                            </React.Fragment>
                        );
                    }}
                />
            </Switch>

            <ContributionButton />
            {/* Check on the planId we are passing to the modal that is expecting a plan object... */}
            {show && <EditContributionModal show={show} plan={planId} onCloseClick={handleClose} />}
            <NqModal />
            <LockheedModal />
            {showDeminModal && (
                <DeminModal isPrivileged={isPrivileged} secDeminModal={secDeminModal} />
            )}
        </div>
    );
};

export default memo(Dashboard);
