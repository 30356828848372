import React, { useEffect, useRef, useState, useCallback } from "react";

import {
    handleKeyboardDownEvent,
    keyboardInteractions
} from "core-ui/client/react/core/utils/accessibilityHelpers";
import {
    find as _find,
    isEqual as _isEqual,
    cloneDeep as _cloneDeep,
    sortBy as _sortBy
} from "lodash";
import PropTypes from "prop-types";

import { usePrevious } from "../../utils/customHooks";

/**
 * Radial chart arcs are drawn with the dash array and dash offset properties.
 *
 * DASH ARRAY
 *
 * The dash array is 2 numbers - the
 * first is the dash and the second is the void.   If a line has length 10 and a dash array of "5 5" the first
 * half will be painted and the second half will be blank.   Turn the line into a circle and the first half of the
 * circle will be painted and the second half blank.   To make this work, the sum of the two numbers in our dash array
 * always equals the circumference.   The last piece is the dash offset which sets the starting point of the painted
 * region.
 *
 *
 * DASH OFFSET
 *
 * SVG offsets are tricky.  0 starts at the right most point of the circle.
 * Offsets are represented as a value that runs counter clockwise.
 * So a 1/4 circumference offset will offset the segment to the top of the circle.
 * Dash-arrays, in contrast, travel clockwise.   ¯\_(ツ)_/¯
 *
 */
const RadialChartArc = ({
    percent,
    offsetPercent,
    className,
    cx,
    cy,
    strokeWidth,
    circumference,
    radius,
    isAnimated,
    animationDuration, // See comments on arcAnimationDuration from RadialChart below
    animationDelay, // See comments on arcAnimationDelay from RadialChart below
    animateStartingPercent,
    animateStartingOffsetPercent
}) => {
    const strokeLength = (percent / 100) * circumference;
    const dashArray = `${strokeLength} ${circumference - strokeLength}`;

    let dashOffset = circumference / 4 + ((100 - offsetPercent) / 100) * circumference;
    if (dashOffset >= circumference) {
        dashOffset = dashOffset - circumference;
    }

    // animations
    const previousStrokeLength = isAnimated ? (animateStartingPercent / 100) * circumference : null;
    const previousDashArray = isAnimated
        ? `${previousStrokeLength} ${circumference - previousStrokeLength}`
        : null;
    let previousDashOffset = null;

    if (isAnimated) {
        previousDashOffset =
            circumference / 4 + ((100 - animateStartingOffsetPercent) / 100) * circumference;
        if (previousDashOffset >= circumference) {
            previousDashOffset = previousDashOffset - circumference;
        }
    }

    // We want the animation to move in the right direction (clockwise or counterclockwise)
    // depending on whether the previous starting point was greater or less than the current.
    if (offsetPercent > animateStartingOffsetPercent && dashOffset > previousDashOffset) {
        dashOffset = dashOffset - circumference;
    } else if (offsetPercent < animateStartingOffsetPercent && dashOffset < previousDashOffset) {
        previousDashOffset = previousDashOffset - circumference;
    }

    return (
        <circle
            cx={cx}
            cy={cy}
            r={radius}
            fill="transparent"
            className={`radial-chart-arc ${className}`}
            strokeWidth={strokeWidth}
            strokeDasharray={dashArray}
            strokeDashoffset={dashOffset}
        >
            {isAnimated && (
                <React.Fragment>
                    <animate
                        className="radial-chart-inner-animation"
                        attributeName="stroke-dasharray"
                        values={`${previousDashArray} ; ${previousDashArray} ; ${dashArray}`}
                        keyTimes={`0 ; ${animationDelay} ; 1`}
                        dur={animationDuration}
                        fill="freeze"
                    />
                    <animate
                        className="radial-chart-inner-animation"
                        attributeName="stroke-dashoffset"
                        values={`${previousDashOffset} ; ${previousDashOffset} ; ${dashOffset}`}
                        keyTimes={`0 ; ${animationDelay} ; 1`}
                        dur={animationDuration}
                        fill="freeze"
                    />
                </React.Fragment>
            )}
        </circle>
    );
};

const calculateStrokeWidth = (newWidth) => {
    if (newWidth > 140 && newWidth < 160) {
        return 10;
    }
    if (newWidth >= 160) {
        return 12;
    }

    return 10;
};

/*
An example of this can be seen in the goal percentage circle on the landing page.
Segments are an array.   For example
[
    {
        code: 'retirementIncome',
        class: 'retirementIncome-color',
        percent: 100,
        sortOrder: 1
    }
]

The sum of all segment percents must be 100.   Segments start at the top of the circle and travel clockwise.
If a segment is selected, an inner drop shadow is shown.

The wrapperClass is used to create a div, whose width is then used to calculate the size of the radial chart

The approach used here, namely using svg's stroke dash array and dash offset properties of a circle,
is explained in this excellent tutorial
https://medium.com/@heyoka/scratch-made-svg-donut-pie-charts-in-html5-2c587e935d72

Top padding can also be added as can be seen in How Do I Compare.   When topPadding is set to true,
topPaddingClass can be set to a string value.   In this case a semi-circular band of padding will be added
to the top of the circle.   See the piers and top piers radial charts in the How Do I Compare section
to see how this looks.
*/
const RadialChart = ({
    wrapperId,
    wrapperClass,
    segments = [], // see comments above
    selectedSegmentCode,
    donutHoleClass,
    topPadding = false,
    topPaddingClass,
    handleClick,
    arcAnimationDuration = "2s", // NOTE: This includes the animation and the delay
    // set to "0s" to turn animations off

    arcAnimationDelay = "0.5", // NOTE: Set as a fractional percent of the duration.
    // A duration of "2s" and delay of "0.5" would yield a time delay of (2s * 0.5) or 1 sec.
    children,
    altText = "",
    description = "",
    tabIndex = "0",
    hasFocus,
    onTabNav = () => {}
}) => {
    const wrapperDiv = useRef(null);
    const [width, setWidth] = useState(0);
    const [strokeWidth, setStrokeWidth] = useState(8);

    const oldWidth = usePrevious(width);

    let previousSegments = usePrevious(segments);
    const previousSelectedSegmentCode = usePrevious(selectedSegmentCode);

    useEffect(() => {
        window.addEventListener("resize", calculateWidth);

        return () => {
            window.removeEventListener("resize", calculateWidth);
        };
    });

    const calculateWidth = useCallback(() => {
        const newWidth = wrapperDiv.current ? wrapperDiv.current.offsetWidth : 0;
        if (newWidth !== 0 && newWidth !== width) {
            // eslint-disable-next-line react-hooks-extra/no-direct-set-state-in-use-effect
            setWidth(newWidth);
            // eslint-disable-next-line react-hooks-extra/no-direct-set-state-in-use-effect
            setStrokeWidth(calculateStrokeWidth(newWidth));
        }
    }, [width]);

    useEffect(() => {
        if (wrapperDiv.current) {
            calculateWidth();
        }
    }, [calculateWidth]);

    useEffect(() => {
        if (hasFocus) {
            wrapperDiv.current.focus();
        }
    }, [hasFocus]);

    // The sum of all segment percents must round to 100
    const sumOfAllSegmentPercents = segments.reduce((sum, segment) => {
        return sum + segment.percent;
    }, 0);
    if (sumOfAllSegmentPercents === 0) {
        return null;
    }
    if (isNaN(parseFloat(sumOfAllSegmentPercents))) {
        return null;
    }
    if (Math.round(sumOfAllSegmentPercents) !== 100) {
        throw new Error(
            `Radial chart segments must have a cumulative percent that rounds to 100. ${sumOfAllSegmentPercents} is invalid.`
        );
    }

    const radialChartAnimationElements = Array.from(
        document.getElementsByClassName("radial-chart-inner-animation")
    );

    const browserSupportsSvgAnimations =
        radialChartAnimationElements.length < 1
            ? false
            : "beginElement" in radialChartAnimationElements[0];

    let isAnimated =
        selectedSegmentCode === previousSelectedSegmentCode && browserSupportsSvgAnimations;

    if (!previousSegments) {
        previousSegments = _cloneDeep(segments);
    } else if (!_isEqual(previousSegments, segments)) {
        radialChartAnimationElements.forEach((arcElement) => {
            if ("beginElement" in arcElement) {
                arcElement.beginElement();
            }
        });
    }

    if (!!oldWidth && oldWidth !== width) {
        isAnimated = false;
    }

    if (width === 0) {
        return <div className={wrapperClass} ref={wrapperDiv} />;
    }

    const diameter = width - 20;
    const radius = diameter / 2;
    const circumference = Math.PI * diameter;

    const addOffsetPercentToSegments = (segments) => {
        return _sortBy(segments, (segment) => {
            return segment.sortOrder;
        }).map((segment, currentIndex) => {
            const offsetPercent = segments.reduce((sum, segment, index) => {
                return index < currentIndex ? sum + segment.percent : sum;
            }, 0);
            return { ...segment, ...{ offsetPercent: offsetPercent } };
        });
    };

    const segmentsWithOffsetPercent = addOffsetPercentToSegments(segments);
    const previousSegmentsWithOffsetPercent = addOffsetPercentToSegments(previousSegments);

    // The selected segment gets an inner highlight - similar to a dropshadow
    const selectedSegment = !selectedSegmentCode
        ? undefined
        : _cloneDeep(
              _find(segmentsWithOffsetPercent, (segment) => {
                  return segment.code === selectedSegmentCode;
              })
          );

    if (!!selectedSegmentCode && !selectedSegment) {
        console.warn(`Missing segment code ${selectedSegmentCode}`);
    }

    let selectedRadius, selectedCircumference;
    let previousSelectedSegment;

    if (selectedSegment) {
        selectedRadius = radius - strokeWidth * 0.75;
        selectedCircumference = Math.PI * selectedRadius * 2;
        previousSelectedSegment = _cloneDeep(
            _find(previousSegmentsWithOffsetPercent, (previousSegment) => {
                return previousSegment.code === previousSelectedSegmentCode;
            })
        );
    }

    let paddingRadius, paddingCircumference, paddingStrokeWidth;
    if (topPadding) {
        paddingRadius = radius + strokeWidth * 0.75;
        paddingCircumference = Math.PI * paddingRadius * 2;
        paddingStrokeWidth = strokeWidth * 0.6;
    }

    const handleOnKeyDown = (event) => {
        onTabNav(event);
        handleKeyboardDownEvent({
            event,
            keys: keyboardInteractions.button,
            output: () => handleClick(event)
        });
    };

    return (
        <div
            id={wrapperId}
            className={wrapperClass}
            ref={wrapperDiv}
            onClick={handleClick}
            onKeyDown={handleOnKeyDown}
            tabIndex={tabIndex}
            role="button"
            aria-controls="goal-modal"
            aria-describedby="goal-modal-title radialChartDescription"
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                id="radialChart"
                height={width}
                width={width}
                aria-labelledby="radialChartTitle"
                aria-describedby="radialChartDescription"
                role="img"
            >
                <title id="radialChartTitle">{altText}</title>
                <desc id="radialChartDescription">{description}</desc>
                {/* Add a placeholder arc with animation to see if the browser supports it.  See browserSupportsSvgAnimations */}
                <RadialChartArc
                    percent={0}
                    offsetPercent={0}
                    cx={width / 2}
                    cy={width / 2}
                    strokeWidth={strokeWidth}
                    circumference={circumference}
                    radius={radius}
                    isAnimated={true}
                    animationDuration={"0s"}
                    animationDelay={0.0}
                    animateStartingPercent={0}
                    animateStartingOffsetPercent={0}
                />

                <circle className={donutHoleClass} cx={width / 2} cy={width / 2} r={radius} />
                {segmentsWithOffsetPercent.map((segment) => {
                    const previousSegment =
                        _find(previousSegmentsWithOffsetPercent, (previousSegment) => {
                            return previousSegment.code === segment.code;
                        }) || {};
                    return (
                        <RadialChartArc
                            key={segment.code}
                            percent={segment.percent}
                            offsetPercent={segment.offsetPercent}
                            className={segment.class}
                            cx={width / 2}
                            cy={width / 2}
                            strokeWidth={strokeWidth}
                            circumference={circumference}
                            radius={radius}
                            isAnimated={isAnimated}
                            animationDuration={arcAnimationDuration}
                            animationDelay={arcAnimationDelay}
                            animateStartingPercent={previousSegment.percent || 0}
                            animateStartingOffsetPercent={previousSegment.offsetPercent || 0}
                        />
                    );
                })}

                {!!selectedSegment && (
                    <RadialChartArc
                        percent={selectedSegment.percent}
                        offsetPercent={selectedSegment.offsetPercent}
                        className={`${selectedSegment.class} ${selectedSegment.class}-selected`}
                        cx={width / 2}
                        cy={width / 2}
                        strokeWidth={strokeWidth}
                        circumference={selectedCircumference}
                        radius={selectedRadius}
                        isAnimated={isAnimated}
                        animationDuration={arcAnimationDuration}
                        animationDelay={arcAnimationDelay}
                        animateStartingPercent={
                            previousSelectedSegment ? previousSelectedSegment.percent : 0
                        }
                        animateStartingOffsetPercent={
                            previousSelectedSegment ? previousSelectedSegment.offsetPercent : 0
                        }
                    />
                )}

                {topPadding && (
                    <circle
                        cx={width / 2}
                        cy={width / 2}
                        r={paddingRadius}
                        fill="transparent"
                        className={topPaddingClass}
                        strokeWidth={paddingStrokeWidth}
                        strokeDasharray={paddingCircumference / 2}
                        strokeDashoffset={paddingCircumference / 2}
                    />
                )}
                {children}
            </svg>
        </div>
    );
};

RadialChartArc.propTypes = {
    percent: PropTypes.number,
    offsetPercent: PropTypes.number,
    className: PropTypes.string,
    cx: PropTypes.number,
    cy: PropTypes.number,
    strokeWidth: PropTypes.number,
    circumference: PropTypes.number,
    radius: PropTypes.number,
    isAnimated: PropTypes.bool,
    animationDuration: PropTypes.string,
    animationDelay: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    animateStartingPercent: PropTypes.number,
    animateStartingOffsetPercent: PropTypes.number
};
RadialChart.propTypes = {
    wrapperId: PropTypes.number,
    wrapperClass: PropTypes.string,
    segments: PropTypes.array,
    selectedSegmentCode: PropTypes.number,
    donutHoleClass: PropTypes.string,
    topPadding: PropTypes.bool,
    topPaddingClass: PropTypes.string,
    handleClick: PropTypes.func,
    arcAnimationDuration: PropTypes.string,
    arcAnimationDelay: PropTypes.string,
    children: PropTypes.any,
    altText: PropTypes.string,
    description: PropTypes.string,
    tabIndex: PropTypes.string,
    hasFocus: PropTypes.bool,
    onTabNav: PropTypes.func
};
export default RadialChart;
