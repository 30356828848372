/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect, useCallback } from "react";

import currencyFormatter from "currency-formatter";
import { find as _find, some as _some } from "lodash";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";

import {
    HOW_DO_I_COMPARE_ROUTE,
    RETIREMENT_INCOME_ROUTE,
    HEALTHCARE_COSTS_ROUTE,
    MAXIMIZER_ROUTE,
    PRE_RETIREMENT_ROUTE,
    WHEN_CAN_I_RETIRE_ROUTE
} from "../../../routes";

const PaycheckOptionDetails = ({ options, mySavingsHasUpdated }) => {
    const location = useLocation();

    const [extend, setExtend] = useState(false);
    const [value, setDisplay] = useState("Select");
    const [mySavingsReload, setMySavingsReload] = useState(false);

    const renderSelectDisplay = useCallback((option) => {
        return (
            <span>
                {" "}
                <i className={"fa fa-square " + option.cssClassName} /> {option.label}:{" "}
                {renderCurrency(option.value)}
            </span>
        );
    }, []);

    useEffect(() => {
        const selectedOption = _find(options, (option) => {
            // In mobile, the following routes all equate to the "My Savings" selection
            // in the navigation dropdown
            if (
                _some(
                    [
                        HOW_DO_I_COMPARE_ROUTE,
                        RETIREMENT_INCOME_ROUTE,
                        HEALTHCARE_COSTS_ROUTE,
                        MAXIMIZER_ROUTE,
                        PRE_RETIREMENT_ROUTE,
                        WHEN_CAN_I_RETIRE_ROUTE
                    ],
                    (route) => {
                        return route === location.pathname;
                    }
                )
            ) {
                return option.routeTo === RETIREMENT_INCOME_ROUTE;
            }
            return option.routeTo === location.pathname;
        });
        if (selectedOption) {
            // eslint-disable-next-line react-hooks-extra/no-direct-set-state-in-use-effect
            setDisplay(renderSelectDisplay(selectedOption));
        }
    }, [location.pathname, options, renderSelectDisplay]);

    const dropdown = () => {
        setExtend(!extend);
    };

    const handleClick = (e, option, indx) => {
        setExtend(false);
        let bypassNavigation = false;
        // add checks here if navigation should be bypassed
        // bypass Social Security Navigation if value is "N/A"; flag has been turned off
        if (option.id === "socialSecurity" && option.value === "N/A") {
            bypassNavigation = true;
        }
        if (!bypassNavigation) {
            e.currentTarget.children[0].click();

            if (indx === 0 || indx === 1) {
                setDisplay(renderSelectDisplay(option));
            }
        }
    };

    const renderCurrency = (value) => {
        let formattedNum = "";
        const locale = "en_US";
        const precision = 0;
        const useSuperscriptSymbol = true;

        if (typeof value === "number") {
            formattedNum =
                (useSuperscriptSymbol ? "$" : "") +
                currencyFormatter.format(value, { lcale: locale, precision: precision });
        } else {
            formattedNum = value;
        }

        return formattedNum;
    };

    /**
     * Customize My Savings and Employer contribution using index to determine
     * which feature it is and display accordingly
     * @param {} option
     * @param {*} indx
     */
    const renderCustomOption = (option, indx, hasERValue) => {
        let clsBorderBottom = "";
        if (indx === 0) {
            clsBorderBottom =
                indx === 0 && hasERValue
                    ? "paycheck-select-borderbottom-none"
                    : "paycheck-select-withborderbottom";
        }
        let clsMarginTop = "";
        if (indx === 1) {
            clsMarginTop =
                indx === 1 && hasERValue
                    ? "paycheck-select-withmargintop"
                    : "paycheck-select-margin-none";
        }

        return (
            (indx === 0 || (indx === 1 && hasERValue)) && (
                <li
                    key={indx}
                    className={`liPayDetails ${clsBorderBottom} ${clsMarginTop}`}
                    onClick={(e) => handleClick(e, option, indx)}
                >
                    <a aria-label={option.label} id={option.id} href={"#" + option.routeTo}></a>
                    {indx == 0 && <i className={"fa fa-square " + option.cssClassName} />}
                    {indx == 1 && <span className="noIcon"></span>}

                    <span className="details-label">
                        {" "}
                        {option.label} {indx == 0 && hasERValue ? " + " : null}
                    </span>

                    <span className="details-amount">{renderCurrency(option.value)}</span>
                </li>
            )
        );
    };

    const renderLI = (indx, option) => {
        const isDisabled = option.disabled ? "disabled" : "";
        return (
            <li
                key={indx}
                className={`liPayDetails ${isDisabled}`}
                onClick={(e) => handleClick(e, option, indx)}
            >
                <a id={option.id} href={"#" + option.routeTo}>
                    {" "}
                </a>
                <i className={"fa fa-square " + option.cssClassName} />
                <span className="details-label"> {option.label} </span>
                <span className="details-amount">{renderCurrency(option.value)}</span>
            </li>
        );
    };

    const renderOptions = (options) => {
        return options.map((option, indx) => {
            // Second condtion checks to see if value has been updated in paycheckDetails and not loaded here before updating
            if (
                option.id === "mySavings" &&
                option.value > 0 &&
                mySavingsHasUpdated &&
                !mySavingsReload
            ) {
                setMySavingsReload(true);
            }

            switch (option.id) {
                case "mySavings":
                    return renderCustomOption(option, 0, options[1].display);
                case "employerContributions":
                    return renderCustomOption(option, 1, option.display);
                case "hsa":
                case "otherAssets":
                    if (option.display) {
                        return renderLI(indx, option);
                    }
                    break;
                case "socialSecurity":
                case "incomeGap":
                    return renderLI(indx, option);
                default:
                    break;
            }
        });
    };

    return (
        <div
            className="dropdown custom-select-container visible-xs-block"
            data-testid="paycheck-option-details"
        >
            <button
                onClick={dropdown}
                className={`custom-select-main dropdown-toggle`}
                data-bs-toggle="dropdown"
            >
                <span className={`custom-select-placeholder `}> {value}</span>
                <span className="custom-select-button"></span>
            </button>
            <ul className="dropdown-menu">{renderOptions(options)}</ul>
        </div>
    );
};
PaycheckOptionDetails.propTypes = {
    options: PropTypes.array,
    mySavingsHasUpdated: PropTypes.bool
};
export default PaycheckOptionDetails;
