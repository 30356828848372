import { isEmpty } from "lodash";

import type { IAccountSummary } from "../../../../types/common";

const DEFAULT_MONTHLY_ACCOUNTS: IAccountSummary["monthlyAccounts"] = [];
const DEFAULT_NON_MONTHLY_ACCOUNTS: IAccountSummary["nonMonthlyAccounts"] = [];

const getPrimaryAccountsInContext = ({
    accountSummary,
    primaryPlanId
}: {
    accountSummary?: IAccountSummary;
    primaryPlanId: string;
}) => {
    if (!accountSummary || isEmpty(accountSummary)) {
        return DEFAULT_MONTHLY_ACCOUNTS;
    }
    const { monthlyAccounts, nonMonthlyAccounts } = accountSummary;

    return (monthlyAccounts || DEFAULT_MONTHLY_ACCOUNTS)
        .concat(nonMonthlyAccounts || DEFAULT_NON_MONTHLY_ACCOUNTS)
        .filter(({ groupId }) => {
            return groupId === primaryPlanId;
        });
};

export default getPrimaryAccountsInContext;
