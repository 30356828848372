import { ObjectUtil } from "gw-shared-components";

import {
    SOCIAL_SECURITY_ROUTE,
    OTHER_ASSETS_ROUTE,
    INCOME_GAP_ROUTE,
    HSA_ROUTE,
    HOW_DO_I_COMPARE_ROUTE,
    HEALTHCARE_COSTS_ROUTE,
    PRE_RETIREMENT_ROUTE
} from "../../../../routes";
import ReferenceData from "../../../constants/ReferenceData";
import type { Attributes } from "../../../types/common";
import type { GetAdvisoryAttributesProps } from "../../../types/common/advisoryMessaging";

const ROUTEMAP = {
    [SOCIAL_SECURITY_ROUTE]: "social_security",
    [OTHER_ASSETS_ROUTE]: "other_assets",
    [HSA_ROUTE]: "hsa",
    [INCOME_GAP_ROUTE]: "income_gap",
    [HEALTHCARE_COSTS_ROUTE]: "healthcare_costs",
    [HOW_DO_I_COMPARE_ROUTE]: "hdic",
    [PRE_RETIREMENT_ROUTE]: "pre_retirement"
};
/**
 * Returns a hash containing the following attributes used by Advisory Services messaging component
 * {
 *   title
 *   bodyHighlight
 *   body
 *   buttonLabel
 *   buttonUrl
 *   footer
 * }
 * or {
 *  missingData: true
 * }
 * If no Advisory Services message should be displayed
 */

const getAdvisoryAttributes = ({
    enrolledInManagedAccounts,
    provider,
    displayRetirementIncomeForecast,
    currentRoute,
    advisoryServicesMessagingJson,
    customMtrMessageEnabled
}: GetAdvisoryAttributesProps): Attributes => {
    if (provider === ReferenceData.PROVIDER_AMA) {
        provider = ReferenceData.PROVIDER_MTR;
    }

    let key = "";

    if (
        !ObjectUtil.isUndefinedOrNull(enrolledInManagedAccounts) &&
        !ObjectUtil.isUndefinedOrNull(provider)
    ) {
        const isFe = provider === ReferenceData.PROVIDER_FE;
        const prefix = enrolledInManagedAccounts ? "enrolled_" : "not_enrolled_";
        const providerStr = isFe ? "fe_" : "mtr_new";
        const forecast = displayRetirementIncomeForecast ? "forecast" : "no_forecast";
        const customInsert = customMtrMessageEnabled ? "_custom" : "";

        key = `${prefix}${providerStr}`;

        // Add display forecast to key if FE
        if (isFe) {
            key += forecast;
        } else {
            // Add route to key if exists in map or 'custom'/'default'
            if (!!ROUTEMAP[currentRoute] && !customInsert) {
                key += `_${ROUTEMAP[currentRoute]}`;
            }
            if (customInsert) {
                key += `${customInsert || "_default"}`;
            }
        }
    }

    if (advisoryServicesMessagingJson[key]) {
        return { ...advisoryServicesMessagingJson[key], ...{ key: key } };
    } else if (advisoryServicesMessagingJson[`${key}_default`]) {
        return { ...advisoryServicesMessagingJson[`${key}_default`], ...{ key: `${key}_default` } };
    }

    return {
        missingData: true
    };
};

export default getAdvisoryAttributes;
