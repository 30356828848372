import { isEmptyString } from "gw-shared-components";

import { isValueUnitsPercent } from "../../../../../selectors/participantSelectors";
import type { ISpouse } from "../../../../../types/common";
import { convertDobFormat, changeDateFormat } from "../../../../../utils/dateUtils";

interface SpouseFieldChanges {
    age: boolean;
    gender: boolean;
    name: boolean;
    retirementAge: boolean;
    spouseDob: boolean;
    spouseIncomeGoal: boolean;
    spouseSalary: boolean;
}

const DEFAULT_SPOUSE_CHANGES: SpouseFieldChanges = {
    name: false,
    gender: false,
    age: false,
    spouseSalary: false,
    spouseIncomeGoal: false,
    spouseDob: false,
    retirementAge: false
};

export interface StateSpouse {
    set: (spouse: ISpouse) => void;
    value: {
        isPercentSpouse: boolean;
        spouseAge: ISpouse["age"];
        spouseDob: string;
        spouseGender: ISpouse["gender"];
        spouseName: ISpouse["firstName"];
        spousePortion: ISpouse["incomeGoal"]["value"];
        spouseSalary: number;
    };
}

enum IncomeGoalValueUnits {
    AMOUNT = "AMT",
    PERCENT = "PCT"
}

interface SpouseInfo {
    dataSource: string;
    dateOfBirth: ISpouse["dateOfBirth"];
    firstName: ISpouse["firstName"];
    gender: ISpouse["gender"];
    incomeGoalValue: ISpouse["incomeGoal"]["value"];
    incomeGoalValueUnits: IncomeGoalValueUnits;
    retirementAge: ISpouse["retirementAge"];
    salary: ISpouse["salary"]["total"];
    type: string;
}

interface GetSpouseChangesResult {
    spouseAdded: boolean;
    spouseFieldChanges: SpouseFieldChanges;
    spouseInfo: SpouseInfo | null;
    spouseInfoHasChanged: boolean;
}

const getSpouseChanges = (
    stateSpouse: StateSpouse,
    spouse: ISpouse,
    stateDesiredRetAge: number,
    spouseDeleted: boolean
): GetSpouseChangesResult => {
    if (spouseDeleted)
        return {
            spouseFieldChanges: DEFAULT_SPOUSE_CHANGES,
            spouseInfoHasChanged: false,
            spouseAdded: false,
            spouseInfo: null
        };

    const { firstName, age, retirementAge, gender, incomeGoal, salary, exists } = spouse;
    const spousePortion = incomeGoal.value;
    const isPercentSpouse = isValueUnitsPercent(spouse);
    const spouseDob = convertDobFormat(spouse.dateOfBirth);

    const spouseFieldChanges = {
        name: stateSpouse.value.spouseName !== firstName,
        gender: stateSpouse.value.spouseGender !== gender,
        age: stateSpouse.value.spouseAge !== age,
        retirementAge: stateDesiredRetAge !== retirementAge,
        spouseSalary: stateSpouse.value.spouseSalary !== salary.base,
        spouseIncomeGoal:
            stateSpouse.value.spousePortion !== spousePortion ||
            stateSpouse.value.isPercentSpouse !== isPercentSpouse,
        spouseDob:
            !isEmptyString(stateSpouse.value.spouseDob) && stateSpouse.value.spouseDob !== spouseDob
    };

    /** A new spouse has been added if state copy has falsy spouseName value and state has truthy spouseName */
    const spouseAdded = !exists && stateSpouse.value.spouseName !== "";

    const spouseInfoHasChanged =
        spouseAdded || Object.values(spouseFieldChanges).some((item) => item);

    const spouseInfo = spouseInfoHasChanged
        ? {
              firstName: stateSpouse.value.spouseName,
              gender: stateSpouse.value.spouseGender,
              dateOfBirth: changeDateFormat(stateSpouse.value.spouseDob),
              retirementAge: stateDesiredRetAge,
              dataSource: "UXAUV",
              salary: stateSpouse.value.spouseSalary,
              incomeGoalValueUnits: stateSpouse.value.isPercentSpouse
                  ? IncomeGoalValueUnits.PERCENT
                  : IncomeGoalValueUnits.AMOUNT,
              incomeGoalValue: stateSpouse.value.spousePortion,
              type: "SPOUSE"
          }
        : null;

    return {
        spouseFieldChanges,
        spouseInfoHasChanged,
        spouseAdded,
        spouseInfo
    };
};

export default getSpouseChanges;
