import type { ACCOUNT_INTERFACE } from "../../../types/common/accounts";

const hasBankOrCCAccount = (accountsData: ACCOUNT_INTERFACE[] | undefined | null = []) => {
    if (!accountsData) return false;
    return accountsData.some(
        (account: ACCOUNT_INTERFACE) =>
            account.productType === "BANK" || account.productType === "CREDIT_CARD"
    );
};

export default hasBankOrCCAccount;
