import React, { useState, useEffect, useCallback } from "react";

import { selectTranslations } from "core-ui/client/src/app/core/translateServiceModule/TranslationsSelector";
import { ObjectUtil } from "gw-shared-components";
import { find as _find } from "lodash";
import PropTypes from "prop-types";
import { Modal, ModalHeader } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";

import {
    resetPendingChanges,
    changeHealthCondition,
    setRetirementState
} from "../../actions/healthcare";
import { saveHealthViewConditions } from "../../middleware/healthCareMiddleware";
import { openHealthViewReport } from "../../services/healthCareService";

const DEFAULT_STATES = [];
const DEFAULT_CONDITIONS = [];

const PersonalizedModal = ({
    isPrivileged,
    showModal,
    toggleModal,
    conditions = DEFAULT_CONDITIONS,
    allRetirementStates = DEFAULT_STATES,
    retirementStateIn,
    healthCareProjectionsAge
}) => {
    const dispatch = useDispatch();
    const translations = selectTranslations("healthCareCosts");
    const translationsApp = selectTranslations("app");
    const { salary } = useSelector((state) => state.participant);
    const healthViewData = useSelector((state) => state.healthCare.healthViewData);

    const [tooltips, setTooltipState] = useState(null);
    const [healthConditions, setHealthConditions] = useState([]);
    const [retirementStateChanged, setRetirementStateChanged] = useState(false);
    const [showMedicareModal, setShowMedicareModal] = useState(false);
    const [retirementStateUpd, setRetirementStateUpd] = useState(retirementStateIn);
    const [changedHealthConditions, setChangedHealthConditions] = useState([]);

    useEffect(() => {
        const setConditionsTooltipValues = conditions.map((condition) => {
            return {
                ...condition,
                show: false
            };
        });

        // eslint-disable-next-line react-hooks-extra/no-direct-set-state-in-use-effect
        setHealthConditions(setConditionsTooltipValues);
    }, [conditions]);

    if (ObjectUtil.isUndefinedOrNull(tooltips) && conditions && conditions.length > 0) {
        const mapping = {};

        for (let i = 0; i < conditions.length; i++) {
            mapping[conditions[i].conditionName] = {
                show: false,
                x: 0
            };
        }

        setTooltipState(mapping);
    }

    const getStateName = (stateCode) => {
        const retirementStateName = _find(healthViewData.healthSurvey.states, (item) => {
            return item.stateCode === stateCode;
        });

        return retirementStateName.stateName;
    };

    const originalRetirementState = getStateName(
        healthViewData.healthSurvey.selectedRetirementState
    );

    const hasHealthConditionsOrRetirementStateChanged = (healthCondition, retirementStateInput) => {
        let hasPendingChanges = false;
        const newArr = changedHealthConditions.slice();

        if (healthCondition) {
            let condition;
            let shouldRemoveFromChanged = false;
            let index = -1;

            for (let i = 0; i < newArr.length; i++) {
                condition = newArr[i];

                if (condition.conditionCode === healthCondition.conditionCode) {
                    shouldRemoveFromChanged = true;
                    index = i;
                    break;
                }
            }

            if (shouldRemoveFromChanged) {
                newArr.splice(index, 1);
            } else {
                newArr.push(healthCondition);
            }

            hasPendingChanges = newArr.length > 0;

            setChangedHealthConditions(newArr);
        } else {
            hasPendingChanges = newArr.length > 0;
        }

        const retirementState = retirementStateInput
            ? getStateName(retirementStateInput)
            : retirementStateUpd;

        hasPendingChanges = hasPendingChanges || originalRetirementState !== retirementState;

        setRetirementStateChanged(hasPendingChanges);
    };

    const conditionChangeHandler = (event, condition) => {
        condition.isPreselected = event.currentTarget.checked;
        hasHealthConditionsOrRetirementStateChanged(condition, null);

        // update dataModel
        dispatch(changeHealthCondition(condition));
    };

    const stateChangeHandler = (event) => {
        const selected = event.target[event.target.selectedIndex];
        const stateObj = {
            stateCode: selected.dataset.code,
            stateName: selected.value
        };

        hasHealthConditionsOrRetirementStateChanged(null, stateObj.stateCode);

        setRetirementStateUpd(stateObj.stateName);

        // update dataModel
        dispatch(setRetirementState(stateObj));
    };

    const modalUpdateHandler = () => {
        toggleModal();

        // calling service for saving changes
        dispatch(saveHealthViewConditions());
    };

    const modalCancelHandler = () => {
        toggleModal();

        dispatch(resetPendingChanges());
    };

    const toggleMedicareModal = () => {
        setShowMedicareModal(!showMedicareModal);
    };

    const handlOpenMedicareModal = (e) => {
        e.preventDefault();
        toggleMedicareModal();
    };

    const goToMedicare = () => {
        toggleMedicareModal();
        window.open("https://www.medicare.gov", "_blank", "noopener,noreferrer");
    };

    const tooltipGetCoordX = (name) => {
        return tooltips[name].x;
    };

    const tooltipSetShow = (name, show, element) => {
        const newState = tooltips;
        newState[name].show = show;

        if (element) {
            newState[name].x = element.offsetLeft;
        }

        const getShowToolTips = healthConditions.map((condition) => {
            if (condition.conditionName === name) {
                condition.show = show;
            }
            return condition;
        });

        setHealthConditions(getShowToolTips);
        setTooltipState(newState);
    };

    const tooltipStyle = (name) => {
        const offset = tooltipGetCoordX(name) - 28;
        return {
            left: offset,
            right: -offset,
            bottom: 15
        };
    };

    const handleOpenHealthViewReport = useCallback(
        (e) => {
            e.preventDefault();
            openHealthViewReport(healthCareProjectionsAge, salary.total);
        },
        [salary, healthCareProjectionsAge]
    );

    const dialogClassName = "healthcare-costs-personalize-modal integrated-healthcare-costs";
    const personalizeConditionsBody = "integrated-personalize-conditions-body";

    const handleKeypress = (e) => {
        const { key } = e;
        if (key === "Enter" && isPrivileged) {
            e.preventDefault();
            return false;
        }
    };

    return (
        tooltips && (
            <div data-testid="personalized-modal">
                <Modal
                    show={showModal}
                    onHide={modalCancelHandler}
                    dialogClassName={dialogClassName}
                >
                    <ModalHeader closeButton={false}>
                        <button
                            type="button"
                            className="btn-close close"
                            onClick={modalCancelHandler}
                        >
                            <span aria-hidden="true">x</span>
                        </button>
                    </ModalHeader>
                    {!showMedicareModal ? (
                        <div className="personalize-modal">
                            <div className="health-care-details-container">
                                <div className="health-care-costs-header row">
                                    <div className="health-care-header col-sm-12">
                                        <h2 data-testid="personalized-header">
                                            {translations.personalizeModal.header}
                                        </h2>
                                        <p data-testid="personalized-body">
                                            {translations.personalizeModal.rivdBody}
                                        </p>
                                    </div>
                                </div>

                                <div className="health-care-costs-content row">
                                    <div className="health-care-checkboxes col-sm-8 clearfix">
                                        <label data-testid="conditon-header">
                                            {translations.personalizeModal.conditions.header}
                                        </label>
                                        <p
                                            className={personalizeConditionsBody}
                                            data-testid="condition-body"
                                        >
                                            {translations.personalizeModal.conditions.body}
                                        </p>
                                        <fieldset>
                                            <legend className="sr-only">
                                                {translations.personalizeModal.conditions.header}
                                            </legend>
                                            {healthConditions.map((condition, index) => {
                                                return (
                                                    <div
                                                        className="health-care-checkbox col-sm-6"
                                                        key={condition.conditionCode}
                                                    >
                                                        <input
                                                            id={condition.conditionName}
                                                            type="checkbox"
                                                            defaultChecked={condition.isPreselected}
                                                            onChange={(event) => {
                                                                conditionChangeHandler(
                                                                    event,
                                                                    condition
                                                                );
                                                            }}
                                                        />
                                                        <label
                                                            htmlFor={condition.conditionName}
                                                            data-testid={`condition-name-${index}`}
                                                        >
                                                            {condition.conditionName}
                                                        </label>
                                                        <button
                                                            className="btn-link"
                                                            onClick={(event) => {
                                                                tooltipSetShow(
                                                                    condition.conditionName,
                                                                    !condition.show,
                                                                    event.currentTarget
                                                                );
                                                            }}
                                                            onBlur={() => {
                                                                tooltipSetShow(
                                                                    condition.conditionName,
                                                                    false
                                                                );
                                                            }}
                                                            title={
                                                                condition.conditionName +
                                                                " Information"
                                                            }
                                                            aria-describedby={
                                                                condition.conditionName + " Tooltip"
                                                            }
                                                        >
                                                            <span
                                                                aria-hidden="true"
                                                                className="em-info-icon"
                                                            />
                                                            <div
                                                                className={
                                                                    "popoverMessageContainer" +
                                                                    (condition.show
                                                                        ? " messageHover"
                                                                        : "")
                                                                }
                                                                style={tooltipStyle(
                                                                    condition.conditionName
                                                                )}
                                                                id={condition.conditionName}
                                                                aria-live="polite"
                                                                aria-hidden={!condition.show}
                                                            >
                                                                <div
                                                                    className="popoverMessage"
                                                                    data-testid={`condition-tooltip-${index}`}
                                                                >
                                                                    {condition.tooltip}
                                                                </div>
                                                                <div className="popoverMessageArrow arrowDown">
                                                                    <div className="insideArrowDown" />
                                                                </div>
                                                            </div>
                                                        </button>
                                                    </div>
                                                );
                                            })}
                                        </fieldset>
                                    </div>

                                    <div className="health-care-costs-where col-sm-4">
                                        <label htmlFor="statesSelect">
                                            {translations.personalizeModal.states.header}
                                        </label>
                                        <p className="hidden-xs">
                                            {translations.personalizeModal.states.body}
                                        </p>
                                        <div className="custom-select custom-select-medium col-sm-12">
                                            <select
                                                id="statesSelect"
                                                name="statesSelect"
                                                className="form-control"
                                                value={retirementStateIn}
                                                onChange={stateChangeHandler}
                                            >
                                                {allRetirementStates.map((state, index) => {
                                                    return (
                                                        <option
                                                            key={state.stateCode}
                                                            value={state.stateName}
                                                            data-code={state.stateCode}
                                                            data-testid={`stateName-${index}`}
                                                        >
                                                            {state.stateName}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div className="health-care-footer row">
                                    <div className="helpful-links-container col-sm-7 col-md-8">
                                        <p className="helpful-links">
                                            <span data-testid="helpfull-links">
                                                {translations.personalizeModal.helpfulLinks}
                                            </span>

                                            <button
                                                className={`${isPrivileged ? "is-disabled" : ""} btn-link`}
                                                onClick={handleOpenHealthViewReport}
                                                onKeyDown={handleKeypress}
                                                data-testid="your-report"
                                                aria-disabled={isPrivileged}
                                            >
                                                {translations.personalizeModal.yourReport}
                                            </button>

                                            <button
                                                className="btn-link"
                                                onClick={handlOpenMedicareModal}
                                            >
                                                <span>www.medicare.gov</span>
                                                <i className="fa fa-external-link" />
                                            </button>
                                        </p>
                                    </div>
                                    <div className="health-care-cta-container">
                                        <div className="health-care__update-container col-sm-3">
                                            <button
                                                data-testid="save-button"
                                                className={`btn-block btn btn-primary ${
                                                    isPrivileged ? "is-disabled" : ""
                                                }`}
                                                onClick={modalUpdateHandler}
                                                onKeyDown={handleKeypress}
                                                aria-disabled={!retirementStateChanged}
                                            >
                                                {translations.personalizeModal.save}
                                            </button>
                                        </div>
                                        <div className="health-care__close-container col-sm-1">
                                            <button
                                                className="btn btn-link"
                                                onClick={modalCancelHandler}
                                            >
                                                <span>Cancel</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="modal-content shadowify">
                            <div className="modal-header">
                                <h4 className="modal-title" id="myModalLabel">
                                    {translations.medicareModal.title}
                                </h4>
                            </div>

                            <div className="modal-body clearfix">
                                <p>{translations.medicareModal.body}</p>
                            </div>

                            <div className="modal-footer">
                                <div className="buttonContainer">
                                    <button
                                        type="button"
                                        className="btn-cancel btn btn-link"
                                        data-dismiss="modal"
                                        onClick={toggleMedicareModal}
                                    >
                                        {translationsApp.common.labels.cancel}
                                    </button>
                                    <button
                                        type="button"
                                        className="btn-continue btn btn-primary"
                                        data-dismiss="modal"
                                        onClick={goToMedicare}
                                    >
                                        {translationsApp.common.labels.continue}
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                </Modal>
            </div>
        )
    );
};
PersonalizedModal.propTypes = {
    isPrivileged: PropTypes.bool,
    showModal: PropTypes.bool,
    toggleModal: PropTypes.func,
    conditions: PropTypes.array,
    allRetirementStates: PropTypes.array,
    retirementStateIn: PropTypes.string,
    healthCareProjectionsAge: PropTypes.number
};
export default PersonalizedModal;
