import type { IAccountSummary } from "../../../../types/common";

const DEFAULT_ACCOUNTS: IAccountSummary["monthlyAccounts"] = [];
const DEFAULT_MONTHLY_ACCOUNTS: IAccountSummary["monthlyAccounts"] = [];
const DEFAULT_NON_ACCOUNTS: IAccountSummary["nonMonthlyAccounts"] = [];

const getAccountSummaryAccounts = (accountSummary: IAccountSummary) => {
    if (!accountSummary) return DEFAULT_ACCOUNTS;

    return (accountSummary?.nonMonthlyAccounts || DEFAULT_NON_ACCOUNTS).concat(
        accountSummary?.monthlyAccounts || DEFAULT_MONTHLY_ACCOUNTS
    );
};

export default getAccountSummaryAccounts;
