import { useEffect, useState } from "react";

import { ObjectUtil } from "gw-shared-components";

import type { Attributes } from "../../../types/common";
import getAdvisoryAttributes from "../helpers/getAdvisoryAttributes";

const useAdvisoryAttributes = (
    advisoryMessaging,
    displayFeForecast,
    enrolled,
    provider,
    currentRoute,
    translations,
    customMtrMessageEnabled,
    readyToShowAdvisoryMessaging
) => {
    const [attributes, setAttributes] = useState<Attributes>({ missingData: true });

    useEffect(() => {
        if (readyToShowAdvisoryMessaging) {
            let advisoryMessagingTranslations = {
                ...translations
            };

            if (!ObjectUtil.isUndefinedOrNull(advisoryMessaging)) {
                advisoryMessagingTranslations = {
                    ...advisoryMessagingTranslations,
                    ...advisoryMessaging
                };
            }

            const msgAttributes = getAdvisoryAttributes({
                enrolledInManagedAccounts: enrolled,
                provider,
                displayRetirementIncomeForecast: displayFeForecast,
                currentRoute,
                advisoryServicesMessagingJson: advisoryMessagingTranslations,
                customMtrMessageEnabled
            });

            if (!("missingData" in msgAttributes)) {
                // eslint-disable-next-line react-hooks-extra/no-direct-set-state-in-use-effect
                setAttributes(msgAttributes);
            }
        }
    }, [
        advisoryMessaging,
        displayFeForecast,
        enrolled,
        provider,
        currentRoute,
        translations,
        customMtrMessageEnabled,
        readyToShowAdvisoryMessaging
    ]);

    return attributes;
};

export default useAdvisoryAttributes;
