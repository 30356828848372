import RequestFactory from "../factories/RequestFactory";
import type { FunnelData } from "../types/common/funnelAttributes";
import { pcapServiceCall } from "../utils/apiUtils";
import { getUrl } from "../utils/urlUtils.js";

const getFunnelAttributesData = async () => {
    const url = getUrl("getFunnelAttributes");
    const contentType = RequestFactory.HTTP_CONTENT_TYPE.URL_ENCODED;
    const method = RequestFactory.HTTP_METHOD.POST;
    const faultLabel = "getFunnelAttributesFault";

    return (await pcapServiceCall({
        method,
        url,
        contentType,
        faultLabel
    })) as FunnelData; //TODO convert pcapServiceCall to typescript and setup type argument
};

export default getFunnelAttributesData;
