import React, { useEffect, useCallback } from "react";

import { selectTranslations } from "core-ui/client/src/app/core/translateServiceModule/TranslationsSelector";
import StringUtil from "core-ui/client/src/app/StringUtil";
import { useSelector } from "react-redux";

const ThingsToConsider = () => {
    const wcirTranslations = selectTranslations("whenCanIRetire")?.thingsToConsider;
    const nsmTranslations = selectTranslations("bestNextStep")?.messages;

    const primaryPlan = useSelector((state) => state.primaryPlan);
    const deferrals = useSelector((state) => state.participant.deferrals);
    const otherAssetsOn = useSelector((state) => state.primaryPlan.planRules.otherAssetsEnabled);
    const nextSteps = useSelector((state) => state.nextSteps);
    const salary = useSelector((state) => state.participant.salary.base);
    const payFrequency = useSelector((state) => state.primaryPlan.payFrequency);
    const hasMixedDeferrals = useSelector((state) => state.deferralSetupConfig.hasMixedDeferrals);
    const showNextSteps = nextSteps.showNextSteps;
    const nextStepsPercent = nextSteps.settings.recommendedPct;
    const nextStepMessage = nextSteps.message;
    const isActive = !primaryPlan.terminated;
    const defaultMsgs = [
        nsmTranslations.MSG_100,
        nsmTranslations.MSG_101,
        nsmTranslations.LEGACY_BNS
    ];
    const isCustom = defaultMsgs.indexOf(nextStepMessage) > -1;
    const [messageList, setMessageList] = React.useState([]);

    const getTotalContributions = useCallback(
        (deferrals) => {
            const total = {
                value: 0,
                type: "PCT"
            };

            deferrals.forEach((deferral, id) => {
                let value = deferral.value;

                if (deferral.pctAmtCode === "AMT") {
                    if (hasMixedDeferrals) {
                        value = Math.round((value / salary) * 100);
                    } else if (id === 0) {
                        total.type = deferral.pctAmtCode;
                    }
                }

                if (deferral.config.enrollmentGroupCode === "STANDARD") {
                    total.value += value;
                }
            });

            return total;
        },
        [salary, hasMixedDeferrals]
    );

    const getContributionConstant = useCallback(
        (totalObj) => {
            return totalObj.type === "PCT" ? 10 : Math.ceil(salary / payFrequency / 5) * 5;
        },
        [salary, payFrequency]
    );

    const getMessaging = useCallback(
        (totalObj) => {
            return totalObj && totalObj.type === "PCT"
                ? wcirTranslations.message2
                : wcirTranslations.message2_dollar;
        },
        [wcirTranslations.message2, wcirTranslations.message2_dollar]
    );

    const renderMessageRows = (messageList) => {
        if (messageList.length === 0) {
            return <li>{wcirTranslations.message4}</li>;
        }
        let counter = 0;
        return messageList.map((item) => {
            counter++;
            return <li key={counter}>{item}</li>;
        });
    };

    useEffect(() => {
        const messages = [];
        const totalContributions = getTotalContributions(deferrals);
        const contributionConstant = getContributionConstant(totalContributions);
        const msgItem = getMessaging(totalContributions);

        if (isActive && showNextSteps && isCustom) {
            const item = StringUtil.supplant(wcirTranslations.message1, {
                rate: nextStepsPercent
            });
            messages.push(item);
        }
        if (isActive && totalContributions.value < contributionConstant) {
            messages.push(msgItem);
        }
        if (otherAssetsOn && totalContributions.value > contributionConstant) {
            messages.push(wcirTranslations.message3);
        }
        if (!otherAssetsOn && totalContributions.value > contributionConstant) {
            messages.push(wcirTranslations.message4);
        }

        if (messages.length > 0) {
            // eslint-disable-next-line react-hooks-extra/no-direct-set-state-in-use-effect
            setMessageList(messages);
        }
    }, [
        deferrals,
        nextStepsPercent,
        isActive,
        showNextSteps,
        isCustom,
        otherAssetsOn,
        wcirTranslations.message1,
        wcirTranslations.message3,
        wcirTranslations.message4,
        getMessaging,
        getContributionConstant,
        getTotalContributions
    ]);

    return (
        <div className="wcir-things-to-consider" data-testid="things-to-consider">
            <h4 className="">
                <strong data-testid="wcir-title">{wcirTranslations.title}</strong>
            </h4>
            <ul data-testid="wcir-msg">{renderMessageRows(messageList)}</ul>
        </div>
    );
};

export default ThingsToConsider;
