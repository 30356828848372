import React, { memo, useRef, useEffect, useState } from "react";

import { queryFocusableElements } from "core-ui/client/react/core/utils/accessibilityHelpers";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { Switch } from "react-router-dom";
import { CompatRoute as Route } from "react-router-dom-v5-compat";

import {
    RETIREMENT_INCOME_ROUTE,
    SOCIAL_SECURITY_ROUTE,
    OTHER_ASSETS_ROUTE,
    INCOME_GAP_ROUTE,
    HSA_ROUTE,
    MAXIMIZER_ROUTE,
    HOW_DO_I_COMPARE_ROUTE,
    HEALTHCARE_COSTS_ROUTE,
    WHEN_CAN_I_RETIRE_ROUTE,
    PRE_RETIREMENT_ROUTE
} from "../../routes";
import { setFocusOnFirstActionable } from "../actions/projectedIncome/projectedIncomeActions";
import HealthSavingsAccount from "../components/hsa/HealthSavingsAccount";
import { DesktopTabs } from "../components/middleTabs/DesktopTabs";
import { IntegratedSocialSecurityView } from "../components/socialSecurity";

import RetirementIncomeView from "./../components/retirementIncome/RetirementIncomeView";
import IncomeGapControls from "./IncomeGapControls";
import OtherAssetsControls from "./OtherAssetsControls";

const RetirementIncomeViewContainer = () => {
    return (
        <React.Fragment>
            <DesktopTabs />
            <RetirementIncomeView />
        </React.Fragment>
    );
};

const PaycheckPrimaryControls = () => {
    const tabpanel = useRef("");
    const { activeIncomeTabId, doFocusOnFirstActionable } = useSelector(
        (state) => state.projectedIncome
    );
    const dispatch = useDispatch();
    const [tabIndex, setTabIndex] = useState(-1);

    useEffect(() => {
        const firstActionable =
            tabpanel.current && tabpanel.current.querySelector(queryFocusableElements);

        if (doFocusOnFirstActionable) {
            if (firstActionable) {
                // eslint-disable-next-line react-hooks-extra/no-direct-set-state-in-use-effect
                setTabIndex(-1);
                firstActionable.focus();
            } else {
                // eslint-disable-next-line react-hooks-extra/no-direct-set-state-in-use-effect
                setTabIndex(0);
                tabpanel.current.focus();
            }
        }
        dispatch(setFocusOnFirstActionable(false));
    }, [dispatch, doFocusOnFirstActionable]);
    return (
        <div
            id="paycheck-primary-controls"
            className="paycheck-primary-controls"
            role="tabpanel"
            aria-labelledby={activeIncomeTabId}
            ref={tabpanel}
            tabIndex={tabIndex}
        >
            <Switch>
                <Route
                    exact
                    path={SOCIAL_SECURITY_ROUTE}
                    render={() => <IntegratedSocialSecurityView />}
                />
                <Route path={OTHER_ASSETS_ROUTE} render={() => <OtherAssetsControls />} />
                <Route
                    path={INCOME_GAP_ROUTE}
                    render={(props) => <IncomeGapControls match={props.match} />}
                />
                <Route path={HSA_ROUTE} render={() => <HealthSavingsAccount />} />
                <Route
                    path={RETIREMENT_INCOME_ROUTE}
                    render={() => <RetirementIncomeViewContainer />}
                />
                <Route
                    path={HEALTHCARE_COSTS_ROUTE}
                    render={() => <RetirementIncomeViewContainer />}
                />
                <Route
                    path={HOW_DO_I_COMPARE_ROUTE}
                    render={() => <RetirementIncomeViewContainer />}
                />
                <Route
                    path={WHEN_CAN_I_RETIRE_ROUTE}
                    render={() => <RetirementIncomeViewContainer />}
                />
                <Route
                    path={PRE_RETIREMENT_ROUTE}
                    render={() => <RetirementIncomeViewContainer />}
                />
                <Route
                    path={MAXIMIZER_ROUTE}
                    render={() => (
                        <React.Fragment>
                            <DesktopTabs />
                            <IncomeGapControls path={MAXIMIZER_ROUTE} />
                        </React.Fragment>
                    )}
                />
            </Switch>
        </div>
    );
};
PaycheckPrimaryControls.propTypes = {
    match: PropTypes.any
};
export default memo(PaycheckPrimaryControls);
