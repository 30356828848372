import React, { useState, useEffect } from "react";

import { selectTranslations } from "core-ui/client/src/app/core/translateServiceModule/TranslationsSelector";
import { useSelector } from "react-redux";

import { reselectTradeOffMessages } from "../../selectors/reselectSelectors";

import {
    MaximizerTradeOff,
    HasTradeOffsView,
    DeferralSpecialtyChanged,
    ShowLimitMessage
} from "./components/index";
import { hasTradeOffs } from "./components/tradeoffHelpers";

const TradeOffMessaging = () => {
    const translations = selectTranslations("retirementIncome");

    const { future, today } = useSelector(reselectTradeOffMessages);

    const changedAutoIncreaseDeferrals = useSelector(
        (state) => state.deferralSetupConfig.changedAutoIncreaseDeferrals
    );
    const changedFutureDatedDeferrals = useSelector(
        (state) => state.deferralSetupConfig.changedFutureDatedDeferrals
    );

    const tradeoffChange = translations.tradeoffChange;
    const tradeOffAutoIncreaseChange = translations.tradeOffAutoIncreaseChange;
    const tradeOffFutureDatedChange = translations.tradeOffFutureDatedChange;

    const hasAutoIncreaseChanges = useSelector(
        (state) => state.deferralSetupConfig.hasAutoIncreaseChanges
    );
    const hasFutureDatedChanges = useSelector(
        (state) => state.deferralSetupConfig.hasFutureDatedChanges
    );

    const hasFutureTradeOffs = hasTradeOffs(future);
    const hasTodayTradeOffs = hasTradeOffs(today);

    const deferralSetupConfig = useSelector((state) => state.deferralSetupConfig);

    const [hasDeferralChanged, setHasDeferralChanged] = useState(false);
    useEffect(() => {
        if (deferralSetupConfig.hasDeferralChanged !== hasDeferralChanged) {
            // eslint-disable-next-line react-hooks-extra/no-direct-set-state-in-use-effect
            setHasDeferralChanged(deferralSetupConfig.hasDeferralChanged);
        }
    }, [deferralSetupConfig.hasDeferralChanged, hasDeferralChanged]);

    const show = hasFutureTradeOffs || hasTodayTradeOffs || hasDeferralChanged;

    return (
        show && (
            <div id="trade-off-messaging" data-testid="trade-off-messaging">
                <span>{tradeoffChange}</span>
                <MaximizerTradeOff />
                <DeferralSpecialtyChanged
                    hasDeferralChanges={hasAutoIncreaseChanges}
                    translations={tradeOffAutoIncreaseChange}
                    changedDeferral={changedAutoIncreaseDeferrals}
                />
                <DeferralSpecialtyChanged
                    hasDeferralChanges={hasFutureDatedChanges}
                    translations={tradeOffFutureDatedChange}
                    changedDeferral={changedFutureDatedDeferrals}
                />
                <ShowLimitMessage />
                <div className="rivd-tradeoffs-view">
                    <HasTradeOffsView
                        label="FUTURE"
                        hasTradeOffs={hasFutureTradeOffs}
                        setting={future}
                    />
                    <HasTradeOffsView
                        label="TODAY"
                        hasTradeOffs={hasTodayTradeOffs}
                        setting={today}
                    />
                </div>
            </div>
        )
    );
};

export { TradeOffMessaging };
