import React, { useId } from "react";

import { AMPLITUDE_EVENTS, dispatchAmplitude } from "core-ui/client/src/app/core/amplitude";
import { generatePath } from "react-router-dom";

import ReferenceData from "../../../../constants/ReferenceData";
import EventBusEvents from "../../../../events/eventBusEvents";
import type IQuickViewAccount from "../../types";

export type LinkType = keyof typeof ReferenceData;

const AMPLITUDE_SELECTION_EVENTS = {
    [ReferenceData.URL_HYPERLINK_BENEFICIARY]: EventBusEvents.QUICK_VIEW.BENEFICIARIES_CLICKED,
    [ReferenceData.URL_HYPERLINK_MY_CONTRIBUTIONS]: EventBusEvents.QUICK_VIEW.CONTRIBUTIONS_CLICKED,
    [ReferenceData.URL_HYPERLINK_RATE_OF_RETURN]: EventBusEvents.QUICK_VIEW.RATE_OF_RETURN_CLICKED,
    [ReferenceData.URL_HYPERLINK_TRANSACTION_HISTORY]:
        EventBusEvents.QUICK_VIEW.LAST_TRANSACTION_CLICKED
};

interface QuickViewLinkProps {
    link: string;
    planDetails: IQuickViewAccount;
    title: string;
}

const QuickViewLink = ({ link, title, planDetails }: QuickViewLinkProps) => {
    // Generate a unique ID for accessibility purposes
    const id = useId();

    const href = generatePath(link, {
        individualId: planDetails.indId,
        groupId: planDetails.gaId
    });

    const onClickHyperlink = () => {
        if (AMPLITUDE_SELECTION_EVENTS[link]) {
            dispatchAmplitude({
                eventType: AMPLITUDE_EVENTS.SELECT_LINK,
                selection: AMPLITUDE_SELECTION_EVENTS[link]
            });
        }
        window.location.href = href;
    };

    return (
        <div data-testid="quick-view-link" className="quick-view-link">
            <a href={href} className="d-flex" aria-labelledby={id} onClick={onClickHyperlink}>
                <span className="title" id={id}>
                    {title}
                </span>
            </a>
            <i className="fa fa-chevron-right" aria-hidden={true} />
        </div>
    );
};

export default QuickViewLink;
